export default
[
  {
    "BayerFlamsteed": "  9Alp CMa",
    "ProperName": "Sirius",
    "RA": "6.7525694",
    "Dec": "-16.71314306",
    "Distance": "2.63706125893305",
    "Mag": -1.44,
    "ColorIndex": 0.009
  },
  {
    "BayerFlamsteed": "   Alp Car",
    "ProperName": "Canopus",
    "RA": "6.39919184",
    "Dec": "-52.69571799",
    "Distance": "95.8772770853308",
    "Mag": -0.62,
    "ColorIndex": 0.164
  },
  {
    "BayerFlamsteed": " 16Alp Boo",
    "ProperName": "Arcturus",
    "RA": "14.2612076",
    "Dec": "+19.18726997",
    "Distance": "11.2549240292628",
    "Mag": -0.05,
    "ColorIndex": 1.239
  },
  {
    "BayerFlamsteed": "   Alp1Cen",
    "ProperName": "Rigel Kentaurus A",
    "RA": "14.66136068",
    "Dec": "-60.83514707",
    "Distance": "1.34749097181049",
    "Mag": -0.01,
    "ColorIndex": 0.71
  },
  {
    "BayerFlamsteed": " 95Psi3Aqr",
    "ProperName": " ",
    "RA": "23.31601414",
    "Dec": "-09.61073100",
    "Distance": "76.3358778625954",
    "Mag": 4.99,
    "ColorIndex": -0.022
  },
  {
    "BayerFlamsteed": " 58Psi7Aur",
    "ProperName": " ",
    "RA": "6.84610087",
    "Dec": "+41.78156548",
    "Distance": "111.731843575419",
    "Mag": 4.99,
    "ColorIndex": 1.256
  },
  {
    "BayerFlamsteed": " 56    UMa",
    "ProperName": " ",
    "RA": "11.38044827",
    "Dec": "+43.48273665",
    "Distance": "150.829562594268",
    "Mag": 4.99,
    "ColorIndex": 0.998
  },
  {
    "BayerFlamsteed": " 36    Dra",
    "ProperName": " ",
    "RA": "18.23148847",
    "Dec": "+64.39719935",
    "Distance": "23.4962406015038",
    "Mag": 4.99,
    "ColorIndex": 0.44
  },
  {
    "BayerFlamsteed": " 23    Ori",
    "ProperName": " ",
    "RA": "5.38055592",
    "Dec": "+03.54444809",
    "Distance": "294.985250737463",
    "Mag": 4.99,
    "ColorIndex": -0.096
  },
  {
    "BayerFlamsteed": " 20    Pup",
    "ProperName": " ",
    "RA": "8.22221528",
    "Dec": "-15.78821451",
    "Distance": "377.358490566038",
    "Mag": 4.99,
    "ColorIndex": 1.066
  },
  {
    "BayerFlamsteed": " 19    Mon",
    "ProperName": " ",
    "RA": "7.04854986",
    "Dec": "-04.23923814",
    "Distance": "342.465753424658",
    "Mag": 4.99,
    "ColorIndex": -0.195
  },
  {
    "BayerFlamsteed": " 18Ome Hya",
    "ProperName": " ",
    "RA": "9.09954939",
    "Dec": "+05.09234249",
    "Distance": "371.747211895911",
    "Mag": 4.99,
    "ColorIndex": 1.189
  },
  {
    "BayerFlamsteed": "  5Xi  Leo",
    "ProperName": " ",
    "RA": "9.5324423",
    "Dec": "+11.30003070",
    "Distance": "73.1528895391368",
    "Mag": 4.99,
    "ColorIndex": 1.046
  },
  {
    "BayerFlamsteed": "  4    Per",
    "ProperName": " ",
    "RA": "2.03835394",
    "Dec": "+54.48754946",
    "Distance": "226.757369614512",
    "Mag": 4.99,
    "ColorIndex": -0.071
  },
  {
    "BayerFlamsteed": "  3    Cet",
    "ProperName": " ",
    "RA": "0.07503398",
    "Dec": "-10.50949443",
    "Distance": "492.610837438424",
    "Mag": 4.99,
    "ColorIndex": 1.619
  },
  {
    "BayerFlamsteed": "  2Eta CrB",
    "ProperName": " ",
    "RA": "15.38672952",
    "Dec": "+30.28824114",
    "Distance": "18.6219739292365",
    "Mag": 4.99,
    "ColorIndex": 0.577
  },
  {
    "BayerFlamsteed": "  2Eps CMi",
    "ProperName": " ",
    "RA": "7.42747186",
    "Dec": "+09.27611835",
    "Distance": "303.030303030303",
    "Mag": 4.99,
    "ColorIndex": 0.991
  },
  {
    "BayerFlamsteed": "  2    Cyg",
    "ProperName": " ",
    "RA": "19.40210326",
    "Dec": "+29.62131120",
    "Distance": "237.529691211401",
    "Mag": 4.99,
    "ColorIndex": -0.12
  },
  {
    "BayerFlamsteed": "   Ups PsA",
    "ProperName": " ",
    "RA": "22.14053557",
    "Dec": "-34.04372111",
    "Distance": "161.812297734628",
    "Mag": 4.99,
    "ColorIndex": 1.499
  },
  {
    "BayerFlamsteed": "   Nu 1Lup",
    "ProperName": " ",
    "RA": "15.36899835",
    "Dec": "-47.92746963",
    "Distance": "34.1646737273659",
    "Mag": 4.99,
    "ColorIndex": 0.515
  },
  {
    "BayerFlamsteed": "   Del For",
    "ProperName": " ",
    "RA": "3.70413865",
    "Dec": "-31.93839587",
    "Distance": "224.719101123595",
    "Mag": 4.99,
    "ColorIndex": -0.159
  },
  {
    "BayerFlamsteed": " 63The2Ser",
    "ProperName": " ",
    "RA": "18.93739246",
    "Dec": "+04.20205672",
    "Distance": "43.7828371278459",
    "Mag": 4.98,
    "ColorIndex": 0.204
  },
  {
    "BayerFlamsteed": " 59    Leo",
    "ProperName": " ",
    "RA": "11.01245342",
    "Dec": "+06.10150342",
    "Distance": "46.2748727441",
    "Mag": 4.98,
    "ColorIndex": 0.166
  },
  {
    "BayerFlamsteed": " 53Nu 2Boo",
    "ProperName": " ",
    "RA": "15.52972009",
    "Dec": "+40.89936876",
    "Distance": "131.752305665349",
    "Mag": 4.98,
    "ColorIndex": 0.086
  },
  {
    "BayerFlamsteed": " 43The2Ori",
    "ProperName": " ",
    "RA": "5.58969439",
    "Dec": "-05.41606331",
    "Distance": "581.395348837209",
    "Mag": 4.98,
    "ColorIndex": -0.097
  },
  {
    "BayerFlamsteed": " 41The1Ori",
    "ProperName": " ",
    "RA": "5.58772859",
    "Dec": "-05.38731536",
    "Distance": "10000000",
    "Mag": 4.98,
    "ColorIndex": null
  },
  {
    "BayerFlamsteed": " 39    Dra",
    "ProperName": " ",
    "RA": "18.39851359",
    "Dec": "+58.80058520",
    "Distance": "57.7700751010976",
    "Mag": 4.98,
    "ColorIndex": 0.082
  },
  {
    "BayerFlamsteed": " 26    Aql",
    "ProperName": " ",
    "RA": "19.34245505",
    "Dec": "-05.41587489",
    "Distance": "47.2366556447803",
    "Mag": 4.98,
    "ColorIndex": 0.937
  },
  {
    "BayerFlamsteed": " 16    Com",
    "ProperName": " ",
    "RA": "12.44980668",
    "Dec": "+26.82572160",
    "Distance": "86.5051903114187",
    "Mag": 4.98,
    "ColorIndex": 0.088
  },
  {
    "BayerFlamsteed": " 14Iot CrB",
    "ProperName": " ",
    "RA": "16.02405295",
    "Dec": "+29.85107821",
    "Distance": "107.642626480086",
    "Mag": 4.98,
    "ColorIndex": -0.05
  },
  {
    "BayerFlamsteed": " 14    Ari",
    "ProperName": " ",
    "RA": "2.1570239",
    "Dec": "+25.93997268",
    "Distance": "98.135426889107",
    "Mag": 4.98,
    "ColorIndex": 0.339
  },
  {
    "BayerFlamsteed": "  9    Aur",
    "ProperName": " ",
    "RA": "5.11129396",
    "Dec": "+51.59814314",
    "Distance": "26.2191924488726",
    "Mag": 4.98,
    "ColorIndex": 0.343
  },
  {
    "BayerFlamsteed": "  6    Hya",
    "ProperName": " ",
    "RA": "8.66708871",
    "Dec": "-12.47537441",
    "Distance": "128.53470437018",
    "Mag": 4.98,
    "ColorIndex": 1.415
  },
  {
    "BayerFlamsteed": "   Bet Hor",
    "ProperName": " ",
    "RA": "2.979935",
    "Dec": "-64.07129717",
    "Distance": "96.1538461538461",
    "Mag": 4.98,
    "ColorIndex": 0.126
  },
  {
    "BayerFlamsteed": "102Ome1Aqr",
    "ProperName": " ",
    "RA": "23.66306578",
    "Dec": "-14.22204281",
    "Distance": "41.0172272354389",
    "Mag": 4.97,
    "ColorIndex": 0.257
  },
  {
    "BayerFlamsteed": " 83Phi Gem",
    "ProperName": " ",
    "RA": "7.89162124",
    "Dec": "+26.76585858",
    "Distance": "78.003120124805",
    "Mag": 4.97,
    "ColorIndex": 0.098
  },
  {
    "BayerFlamsteed": " 72    Peg",
    "ProperName": " ",
    "RA": "23.56587592",
    "Dec": "+31.32532196",
    "Distance": "168.067226890756",
    "Mag": 4.97,
    "ColorIndex": 1.383
  },
  {
    "BayerFlamsteed": " 70    Vir",
    "ProperName": " ",
    "RA": "13.47387511",
    "Dec": "+13.78018777",
    "Distance": "18.1093806591815",
    "Mag": 4.97,
    "ColorIndex": 0.714
  },
  {
    "BayerFlamsteed": " 52Phi Tau",
    "ProperName": " ",
    "RA": "4.33923187",
    "Dec": "+27.35094069",
    "Distance": "104.931794333683",
    "Mag": 4.97,
    "ColorIndex": 1.15
  },
  {
    "BayerFlamsteed": " 52    Hya",
    "ProperName": " ",
    "RA": "14.46956759",
    "Dec": "-29.49157976",
    "Distance": "127.388535031847",
    "Mag": 4.97,
    "ColorIndex": -0.074
  },
  {
    "BayerFlamsteed": " 46Ome Cas",
    "ProperName": " ",
    "RA": "1.93333438",
    "Dec": "+68.68526545",
    "Distance": "215.05376344086",
    "Mag": 4.97,
    "ColorIndex": -0.084
  },
  {
    "BayerFlamsteed": " 42    Lib",
    "ProperName": " ",
    "RA": "15.67136259",
    "Dec": "-23.81805841",
    "Distance": "116.959064327485",
    "Mag": 4.97,
    "ColorIndex": 1.302
  },
  {
    "BayerFlamsteed": " 40    Per",
    "ProperName": " ",
    "RA": "3.70628987",
    "Dec": "+33.96503899",
    "Distance": "283.28611898017",
    "Mag": 4.97,
    "ColorIndex": -0.048
  },
  {
    "BayerFlamsteed": " 20Psi And",
    "ProperName": " ",
    "RA": "23.76723303",
    "Dec": "+46.42029095",
    "Distance": "401.606425702811",
    "Mag": 4.97,
    "ColorIndex": 1.086
  },
  {
    "BayerFlamsteed": " 16    Vir",
    "ProperName": " ",
    "RA": "12.33920904",
    "Dec": "+03.31272688",
    "Distance": "87.4890638670166",
    "Mag": 4.97,
    "ColorIndex": 1.172
  },
  {
    "BayerFlamsteed": "   Xi  Col",
    "ProperName": " ",
    "RA": "5.92496954",
    "Dec": "-37.12060931",
    "Distance": "100.704934541793",
    "Mag": 4.97,
    "ColorIndex": 1.102
  },
  {
    "BayerFlamsteed": "   Nu  Phe",
    "ProperName": " ",
    "RA": "1.25293547",
    "Dec": "-45.53209717",
    "Distance": "15.053439710974",
    "Mag": 4.97,
    "ColorIndex": 0.571
  },
  {
    "BayerFlamsteed": "   Iot Ret",
    "ProperName": " ",
    "RA": "4.0216864",
    "Dec": "-61.07905148",
    "Distance": "101.522842639594",
    "Mag": 4.97,
    "ColorIndex": 1.386
  },
  {
    "BayerFlamsteed": "   Gam1Nor",
    "ProperName": " ",
    "RA": "16.28359319",
    "Dec": "-50.06811224",
    "Distance": "440.528634361233",
    "Mag": 4.97,
    "ColorIndex": 0.788
  },
  {
    "BayerFlamsteed": "109    Tau",
    "ProperName": " ",
    "RA": "5.32127494",
    "Dec": "+22.09669104",
    "Distance": "63.1711939355654",
    "Mag": 4.96,
    "ColorIndex": 0.937
  },
  {
    "BayerFlamsteed": "104    Her",
    "ProperName": " ",
    "RA": "18.19837968",
    "Dec": "+31.40528840",
    "Distance": "184.50184501845",
    "Mag": 4.96,
    "ColorIndex": 1.643
  },
  {
    "BayerFlamsteed": " 98    Her",
    "ProperName": " ",
    "RA": "18.10053019",
    "Dec": "+22.21889206",
    "Distance": "188.679245283019",
    "Mag": 4.96,
    "ColorIndex": 1.656
  },
  {
    "BayerFlamsteed": " 89    Vir",
    "ProperName": " ",
    "RA": "13.83120679",
    "Dec": "-18.13407618",
    "Distance": "74.1839762611276",
    "Mag": 4.96,
    "ColorIndex": 1.059
  },
  {
    "BayerFlamsteed": " 75    Tau",
    "ProperName": " ",
    "RA": "4.47399031",
    "Dec": "+16.35962902",
    "Distance": "59.5947556615018",
    "Mag": 4.96,
    "ColorIndex": 1.137
  },
  {
    "BayerFlamsteed": " 53Tau And",
    "ProperName": " ",
    "RA": "1.67633449",
    "Dec": "+40.57710624",
    "Distance": "208.768267223382",
    "Mag": 4.96,
    "ColorIndex": -0.068
  },
  {
    "BayerFlamsteed": " 32    Per",
    "ProperName": " ",
    "RA": "3.35739087",
    "Dec": "+43.32965174",
    "Distance": "47.6190476190476",
    "Mag": 4.96,
    "ColorIndex": 0.051
  },
  {
    "BayerFlamsteed": " 30Xi  Aur",
    "ProperName": " ",
    "RA": "5.91410718",
    "Dec": "+55.70690490",
    "Distance": "73.8552437223043",
    "Mag": 4.96,
    "ColorIndex": 0.052
  },
  {
    "BayerFlamsteed": " 11    Sgr",
    "ProperName": " ",
    "RA": "18.19536888",
    "Dec": "-23.70116777",
    "Distance": "74.9063670411985",
    "Mag": 4.96,
    "ColorIndex": 1.055
  },
  {
    "BayerFlamsteed": "  4    Cas",
    "ProperName": " ",
    "RA": "23.41395751",
    "Dec": "+62.28283877",
    "Distance": "236.406619385343",
    "Mag": 4.96,
    "ColorIndex": 1.676
  },
  {
    "BayerFlamsteed": "   Ome For",
    "ProperName": " ",
    "RA": "2.56408677",
    "Dec": "-28.23234010",
    "Distance": "139.082058414465",
    "Mag": 4.96,
    "ColorIndex": -0.05
  },
  {
    "BayerFlamsteed": " 84Tau Leo",
    "ProperName": " ",
    "RA": "11.46561941",
    "Dec": "+02.85629071",
    "Distance": "190.47619047619",
    "Mag": 4.95,
    "ColorIndex": 1
  },
  {
    "BayerFlamsteed": " 69    Ori",
    "ProperName": " ",
    "RA": "6.20091003",
    "Dec": "+16.13044684",
    "Distance": "237.529691211401",
    "Mag": 4.95,
    "ColorIndex": -0.149
  },
  {
    "BayerFlamsteed": " 48    Lib",
    "ProperName": " ",
    "RA": "15.96982674",
    "Dec": "-14.27931773",
    "Distance": "157.232704402516",
    "Mag": 4.95,
    "ColorIndex": -0.08
  },
  {
    "BayerFlamsteed": " 34Phi Cas",
    "ProperName": " ",
    "RA": "1.33469935",
    "Dec": "+58.23161610",
    "Distance": "714.285714285714",
    "Mag": 4.95,
    "ColorIndex": 0.683
  },
  {
    "BayerFlamsteed": " 22    Cyg",
    "ProperName": " ",
    "RA": "19.93104478",
    "Dec": "+38.48670392",
    "Distance": "369.0036900369",
    "Mag": 4.95,
    "ColorIndex": -0.086
  },
  {
    "BayerFlamsteed": " 21Eta UMi",
    "ProperName": " ",
    "RA": "16.29180584",
    "Dec": "+75.75470385",
    "Distance": "29.8329355608592",
    "Mag": 4.95,
    "ColorIndex": 0.393
  },
  {
    "BayerFlamsteed": " 20Pi  Cas",
    "ProperName": " ",
    "RA": "0.72446964",
    "Dec": "+47.02463657",
    "Distance": "53.4473543559594",
    "Mag": 4.95,
    "ColorIndex": 0.17
  },
  {
    "BayerFlamsteed": " 19    Psc",
    "ProperName": " ",
    "RA": "23.77320469",
    "Dec": "+03.48687164",
    "Distance": "233.100233100233",
    "Mag": 4.95,
    "ColorIndex": 2.508
  },
  {
    "BayerFlamsteed": " 15    Lac",
    "ProperName": " ",
    "RA": "22.86720757",
    "Dec": "+43.31236494",
    "Distance": "100.1001001001",
    "Mag": 4.95,
    "ColorIndex": 1.559
  },
  {
    "BayerFlamsteed": "  8Kap Psc",
    "ProperName": " ",
    "RA": "23.44886258",
    "Dec": "+01.25583758",
    "Distance": "49.7017892644135",
    "Mag": 4.95,
    "ColorIndex": 0.036
  },
  {
    "BayerFlamsteed": "  6    Dra",
    "ProperName": " ",
    "RA": "12.57890701",
    "Dec": "+70.02177318",
    "Distance": "167.50418760469",
    "Mag": 4.95,
    "ColorIndex": 1.312
  },
  {
    "BayerFlamsteed": "   Kap Nor",
    "ProperName": " ",
    "RA": "16.22464842",
    "Dec": "-54.63041289",
    "Distance": "134.228187919463",
    "Mag": 4.95,
    "ColorIndex": 1.017
  },
  {
    "BayerFlamsteed": " 67Rho Aql",
    "ProperName": " ",
    "RA": "20.23794044",
    "Dec": "+15.19746767",
    "Distance": "47.0809792843691",
    "Mag": 4.94,
    "ColorIndex": 0.072
  },
  {
    "BayerFlamsteed": " 45Psi Hya",
    "ProperName": " ",
    "RA": "13.15091194",
    "Dec": "-23.11797275",
    "Distance": "70.7213578500707",
    "Mag": 4.94,
    "ColorIndex": 1.048
  },
  {
    "BayerFlamsteed": " 45Ome1Cyg",
    "ProperName": " ",
    "RA": "20.50098104",
    "Dec": "+48.95155039",
    "Distance": "266.666666666667",
    "Mag": 4.94,
    "ColorIndex": -0.087
  },
  {
    "BayerFlamsteed": " 24    Per",
    "ProperName": " ",
    "RA": "2.98436382",
    "Dec": "+35.18311415",
    "Distance": "107.411385606874",
    "Mag": 4.94,
    "ColorIndex": 1.235
  },
  {
    "BayerFlamsteed": " 15Tau PsA",
    "ProperName": " ",
    "RA": "22.1690232",
    "Dec": "-32.54844042",
    "Distance": "18.7406296851574",
    "Mag": 4.94,
    "ColorIndex": 0.489
  },
  {
    "BayerFlamsteed": " 15Lam Lyr",
    "ProperName": " ",
    "RA": "19.00022817",
    "Dec": "+32.14549051",
    "Distance": "471.698113207547",
    "Mag": 4.94,
    "ColorIndex": 1.465
  },
  {
    "BayerFlamsteed": " 15    Dra",
    "ProperName": " ",
    "RA": "16.46640365",
    "Dec": "+68.76805491",
    "Distance": "150.602409638554",
    "Mag": 4.94,
    "ColorIndex": -0.051
  },
  {
    "BayerFlamsteed": "  6    Tri",
    "ProperName": " ",
    "RA": "2.20620108",
    "Dec": "+30.30321507",
    "Distance": "93.6329588014981",
    "Mag": 4.94,
    "ColorIndex": 0.77
  },
  {
    "BayerFlamsteed": "   Chi Gem",
    "ProperName": " ",
    "RA": "8.05863865",
    "Dec": "+27.79441794",
    "Distance": "78.9889415481832",
    "Mag": 4.94,
    "ColorIndex": 1.13
  },
  {
    "BayerFlamsteed": " 92Chi Aqr",
    "ProperName": " ",
    "RA": "23.28081957",
    "Dec": "-07.72646619",
    "Distance": "196.463654223969",
    "Mag": 4.93,
    "ColorIndex": 1.613
  },
  {
    "BayerFlamsteed": " 78    UMa",
    "ProperName": " ",
    "RA": "13.01210715",
    "Dec": "+56.36633134",
    "Distance": "24.9625561657514",
    "Mag": 4.93,
    "ColorIndex": 0.368
  },
  {
    "BayerFlamsteed": " 78    Peg",
    "ProperName": " ",
    "RA": "23.73317723",
    "Dec": "+29.36155278",
    "Distance": "71.8907260963336",
    "Mag": 4.93,
    "ColorIndex": 0.935
  },
  {
    "BayerFlamsteed": " 54    Per",
    "ProperName": " ",
    "RA": "4.34018223",
    "Dec": "+34.56674228",
    "Distance": "69.3481276005548",
    "Mag": 4.93,
    "ColorIndex": 0.95
  },
  {
    "BayerFlamsteed": " 50Ome2Tau",
    "ProperName": " ",
    "RA": "4.28769059",
    "Dec": "+20.57874033",
    "Distance": "28.6779466590192",
    "Mag": 4.93,
    "ColorIndex": 0.259
  },
  {
    "BayerFlamsteed": " 45    Boo",
    "ProperName": " ",
    "RA": "15.12165196",
    "Dec": "+24.86959294",
    "Distance": "19.723865877712",
    "Mag": 4.93,
    "ColorIndex": 0.429
  },
  {
    "BayerFlamsteed": " 39Kap Aql",
    "ProperName": " ",
    "RA": "19.61484682",
    "Dec": "-07.02747069",
    "Distance": "446.428571428571",
    "Mag": 4.93,
    "ColorIndex": -0.046
  },
  {
    "BayerFlamsteed": " 31    Com",
    "ProperName": " ",
    "RA": "12.86164661",
    "Dec": "+27.54073393",
    "Distance": "94.1619585687382",
    "Mag": 4.93,
    "ColorIndex": 0.681
  },
  {
    "BayerFlamsteed": " 29    Cyg",
    "ProperName": " ",
    "RA": "20.24221735",
    "Dec": "+36.80613540",
    "Distance": "41.0340582683627",
    "Mag": 4.93,
    "ColorIndex": 0.151
  },
  {
    "BayerFlamsteed": " 28    Cyg",
    "ProperName": " ",
    "RA": "20.15711576",
    "Dec": "+36.83959003",
    "Distance": "263.852242744063",
    "Mag": 4.93,
    "ColorIndex": -0.139
  },
  {
    "BayerFlamsteed": " 27    Mon",
    "ProperName": " ",
    "RA": "7.99560686",
    "Dec": "-03.67957835",
    "Distance": "76.5696784073507",
    "Mag": 4.93,
    "ColorIndex": 1.205
  },
  {
    "BayerFlamsteed": " 24    Lyn",
    "ProperName": " ",
    "RA": "7.7167938",
    "Dec": "+58.71048707",
    "Distance": "72.7272727272727",
    "Mag": 4.93,
    "ColorIndex": 0.104
  },
  {
    "BayerFlamsteed": " 15Psi Sco",
    "ProperName": " ",
    "RA": "16.20000043",
    "Dec": "-10.06418514",
    "Distance": "50.5305709954523",
    "Mag": 4.93,
    "ColorIndex": 0.087
  },
  {
    "BayerFlamsteed": " 11    Lib",
    "ProperName": " ",
    "RA": "14.85028336",
    "Dec": "-02.29884995",
    "Distance": "66.2690523525514",
    "Mag": 4.93,
    "ColorIndex": 0.988
  },
  {
    "BayerFlamsteed": "  7    Com",
    "ProperName": " ",
    "RA": "12.27237644",
    "Dec": "+23.94542332",
    "Distance": "76.219512195122",
    "Mag": 4.93,
    "ColorIndex": 0.957
  },
  {
    "BayerFlamsteed": "  4Ome Aur",
    "ProperName": " ",
    "RA": "4.98760428",
    "Dec": "+37.89048240",
    "Distance": "48.780487804878",
    "Mag": 4.93,
    "ColorIndex": 0.037
  },
  {
    "BayerFlamsteed": "   Xi  Tel",
    "ProperName": " ",
    "RA": "20.12310238",
    "Dec": "-52.88080972",
    "Distance": "384.615384615385",
    "Mag": 4.93,
    "ColorIndex": 1.591
  },
  {
    "BayerFlamsteed": "   Del Hor",
    "ProperName": " ",
    "RA": "4.18067583",
    "Dec": "-41.99374121",
    "Distance": "53.7345513164965",
    "Mag": 4.93,
    "ColorIndex": 0.334
  },
  {
    "BayerFlamsteed": "106    Her",
    "ProperName": " ",
    "RA": "18.33830655",
    "Dec": "+21.96144090",
    "Distance": "130.548302872063",
    "Mag": 4.92,
    "ColorIndex": 1.594
  },
  {
    "BayerFlamsteed": " 78    Vir",
    "ProperName": " ",
    "RA": "13.56886258",
    "Dec": "+03.65902506",
    "Distance": "56.2113546936481",
    "Mag": 4.92,
    "ColorIndex": 0.029
  },
  {
    "BayerFlamsteed": " 56    Cet",
    "ProperName": " ",
    "RA": "1.94448852",
    "Dec": "-22.52671153",
    "Distance": "122.249388753056",
    "Mag": 4.92,
    "ColorIndex": 1.434
  },
  {
    "BayerFlamsteed": " 46Psi1Aur",
    "ProperName": " ",
    "RA": "6.41497277",
    "Dec": "+49.28789903",
    "Distance": "1176.47058823529",
    "Mag": 4.92,
    "ColorIndex": 1.905
  },
  {
    "BayerFlamsteed": " 31Eps Lib",
    "ProperName": " ",
    "RA": "15.40331392",
    "Dec": "-10.32189476",
    "Distance": "32.3624595469256",
    "Mag": 4.92,
    "ColorIndex": 0.453
  },
  {
    "BayerFlamsteed": " 30    Vul",
    "ProperName": " ",
    "RA": "20.74792311",
    "Dec": "+25.27104823",
    "Distance": "111.856823266219",
    "Mag": 4.92,
    "ColorIndex": 1.183
  },
  {
    "BayerFlamsteed": " 17    Lep",
    "ProperName": " ",
    "RA": "6.08309238",
    "Dec": "-16.48442713",
    "Distance": "327.868852459016",
    "Mag": 4.92,
    "ColorIndex": 0.196
  },
  {
    "BayerFlamsteed": " 14    Com",
    "ProperName": " ",
    "RA": "12.44002048",
    "Dec": "+27.26826273",
    "Distance": "83.8926174496644",
    "Mag": 4.92,
    "ColorIndex": 0.277
  },
  {
    "BayerFlamsteed": "  6    Boo",
    "ProperName": " ",
    "RA": "13.82856109",
    "Dec": "+21.26406422",
    "Distance": "112.866817155756",
    "Mag": 4.92,
    "ColorIndex": 1.432
  },
  {
    "BayerFlamsteed": "  3    Mon",
    "ProperName": " ",
    "RA": "6.03067383",
    "Dec": "-10.59793918",
    "Distance": "300.3003003003",
    "Mag": 4.92,
    "ColorIndex": -0.128
  },
  {
    "BayerFlamsteed": "   Del1Tel",
    "ProperName": " ",
    "RA": "18.52928779",
    "Dec": "-45.91474360",
    "Distance": "243.90243902439",
    "Mag": 4.92,
    "ColorIndex": -0.101
  },
  {
    "BayerFlamsteed": "   Chi1Hya",
    "ProperName": " ",
    "RA": "11.08889795",
    "Dec": "-27.29359566",
    "Distance": "43.5161009573542",
    "Mag": 4.92,
    "ColorIndex": 0.369
  },
  {
    "BayerFlamsteed": "104    Tau",
    "ProperName": " ",
    "RA": "5.12407668",
    "Dec": "+18.64500711",
    "Distance": "15.8679784195493",
    "Mag": 4.91,
    "ColorIndex": 0.657
  },
  {
    "BayerFlamsteed": " 70    Aql",
    "ProperName": " ",
    "RA": "20.61211862",
    "Dec": "-02.54991814",
    "Distance": "675.675675675676",
    "Mag": 4.91,
    "ColorIndex": 1.606
  },
  {
    "BayerFlamsteed": " 69    Aql",
    "ProperName": " ",
    "RA": "20.49415518",
    "Dec": "-02.88547630",
    "Distance": "58.5480093676815",
    "Mag": 4.91,
    "ColorIndex": 1.16
  },
  {
    "BayerFlamsteed": " 63    Aur",
    "ProperName": " ",
    "RA": "7.19424789",
    "Dec": "+39.32054373",
    "Distance": "142.450142450142",
    "Mag": 4.91,
    "ColorIndex": 1.451
  },
  {
    "BayerFlamsteed": " 50Rho Peg",
    "ProperName": " ",
    "RA": "22.9204512",
    "Dec": "+08.81609536",
    "Distance": "86.5800865800866",
    "Mag": 4.91,
    "ColorIndex": -0.003
  },
  {
    "BayerFlamsteed": " 45    Eri",
    "ProperName": " ",
    "RA": "4.53129707",
    "Dec": "-00.04399115",
    "Distance": "254.452926208651",
    "Mag": 4.91,
    "ColorIndex": 1.32
  },
  {
    "BayerFlamsteed": " 24Psi Cyg",
    "ProperName": " ",
    "RA": "19.92717285",
    "Dec": "+52.43902327",
    "Distance": "88.4955752212389",
    "Mag": 4.91,
    "ColorIndex": 0.124
  },
  {
    "BayerFlamsteed": " 21Mu  Dra",
    "ProperName": " ",
    "RA": "17.08893952",
    "Dec": "+54.46986308",
    "Distance": "26.9687162891046",
    "Mag": 4.91,
    "ColorIndex": 0.471
  },
  {
    "BayerFlamsteed": " 20    Mon",
    "ProperName": " ",
    "RA": "7.17046719",
    "Dec": "-04.23763568",
    "Distance": "64.7249190938511",
    "Mag": 4.91,
    "ColorIndex": 1.02
  },
  {
    "BayerFlamsteed": " 19Del Lib",
    "ProperName": " ",
    "RA": "15.01621879",
    "Dec": "-08.51893488",
    "Distance": "93.2835820895522",
    "Mag": 4.91,
    "ColorIndex": 0
  },
  {
    "BayerFlamsteed": " 12    Per",
    "ProperName": " ",
    "RA": "2.7041469",
    "Dec": "+40.19438688",
    "Distance": "24.6791707798618",
    "Mag": 4.91,
    "ColorIndex": 0.582
  },
  {
    "BayerFlamsteed": "  1    Lup",
    "ProperName": " ",
    "RA": "15.24370163",
    "Dec": "-31.51912564",
    "Distance": "349.65034965035",
    "Mag": 4.91,
    "ColorIndex": 0.374
  },
  {
    "BayerFlamsteed": "   Phi3Hya",
    "ProperName": " ",
    "RA": "10.64305918",
    "Dec": "-16.87663687",
    "Distance": "68.870523415978",
    "Mag": 4.91,
    "ColorIndex": 0.922
  },
  {
    "BayerFlamsteed": "   Nu  Tuc",
    "ProperName": " ",
    "RA": "22.5500035",
    "Dec": "-61.98207300",
    "Distance": "83.6120401337793",
    "Mag": 4.91,
    "ColorIndex": 1.612
  },
  {
    "BayerFlamsteed": " 51    Ori",
    "ProperName": " ",
    "RA": "5.70796232",
    "Dec": "+01.47466498",
    "Distance": "92.5925925925926",
    "Mag": 4.9,
    "ColorIndex": 1.144
  },
  {
    "BayerFlamsteed": " 46    Cet",
    "ProperName": " ",
    "RA": "1.42700257",
    "Dec": "-14.59875437",
    "Distance": "88.4173297966401",
    "Mag": 4.9,
    "ColorIndex": 1.231
  },
  {
    "BayerFlamsteed": " 25Nu  Cas",
    "ProperName": " ",
    "RA": "0.81388703",
    "Dec": "+50.96819154",
    "Distance": "119.4743130227",
    "Mag": 4.9,
    "ColorIndex": -0.091
  },
  {
    "BayerFlamsteed": " 16    Lyn",
    "ProperName": " ",
    "RA": "6.96031249",
    "Dec": "+45.09410202",
    "Distance": "69.0131124913734",
    "Mag": 4.9,
    "ColorIndex": 0.027
  },
  {
    "BayerFlamsteed": " 12    Vul",
    "ProperName": " ",
    "RA": "19.85113712",
    "Dec": "+22.61008676",
    "Distance": "189.753320683112",
    "Mag": 4.9,
    "ColorIndex": -0.153
  },
  {
    "BayerFlamsteed": "  8Bet2Sco",
    "ProperName": " ",
    "RA": "16.09071534",
    "Dec": "-19.80184191",
    "Distance": "347.222222222222",
    "Mag": 4.9,
    "ColorIndex": -0.024
  },
  {
    "BayerFlamsteed": "   Zet TrA",
    "ProperName": " ",
    "RA": "16.47438923",
    "Dec": "-70.08467012",
    "Distance": "12.1050720251786",
    "Mag": 4.9,
    "ColorIndex": 0.555
  },
  {
    "BayerFlamsteed": "   Zet Ind",
    "ProperName": " ",
    "RA": "20.82470255",
    "Dec": "-46.22689249",
    "Distance": "124.069478908189",
    "Mag": 4.9,
    "ColorIndex": 1.494
  },
  {
    "BayerFlamsteed": "134    Tau",
    "ProperName": " ",
    "RA": "5.82581763",
    "Dec": "+12.65136584",
    "Distance": "83.3333333333333",
    "Mag": 4.89,
    "ColorIndex": -0.068
  },
  {
    "BayerFlamsteed": " 81    Gem",
    "ProperName": " ",
    "RA": "7.76874825",
    "Dec": "+18.51016877",
    "Distance": "104.712041884817",
    "Mag": 4.89,
    "ColorIndex": 1.425
  },
  {
    "BayerFlamsteed": " 71Omi Gem",
    "ProperName": " ",
    "RA": "7.65276566",
    "Dec": "+34.58463287",
    "Distance": "48.4966052376334",
    "Mag": 4.89,
    "ColorIndex": 0.413
  },
  {
    "BayerFlamsteed": " 60    Her",
    "ProperName": " ",
    "RA": "17.08962783",
    "Dec": "+12.74085469",
    "Distance": "44.0917107583774",
    "Mag": 4.89,
    "ColorIndex": 0.125
  },
  {
    "BayerFlamsteed": " 35    Com",
    "ProperName": " ",
    "RA": "12.88826967",
    "Dec": "+21.24502058",
    "Distance": "99.403578528827",
    "Mag": 4.89,
    "ColorIndex": 0.904
  },
  {
    "BayerFlamsteed": " 25Psi1Ori",
    "ProperName": " ",
    "RA": "5.4124518",
    "Dec": "+01.84644611",
    "Distance": "340.136054421769",
    "Mag": 4.89,
    "ColorIndex": -0.2
  },
  {
    "BayerFlamsteed": " 24Nu 1Dra",
    "ProperName": " ",
    "RA": "17.53622721",
    "Dec": "+55.18411077",
    "Distance": "30.3398058252427",
    "Mag": 4.89,
    "ColorIndex": 0.251
  },
  {
    "BayerFlamsteed": " 15    Cyg",
    "ProperName": " ",
    "RA": "19.737931",
    "Dec": "+37.35426967",
    "Distance": "85.4700854700855",
    "Mag": 4.89,
    "ColorIndex": 0.948
  },
  {
    "BayerFlamsteed": " 10    Lac",
    "ProperName": " ",
    "RA": "22.65435525",
    "Dec": "+39.05028301",
    "Distance": "324.675324675325",
    "Mag": 4.89,
    "ColorIndex": -0.207
  },
  {
    "BayerFlamsteed": "  6    Cet",
    "ProperName": " ",
    "RA": "0.18775227",
    "Dec": "-15.46732287",
    "Distance": "18.8893086513034",
    "Mag": 4.89,
    "ColorIndex": 0.487
  },
  {
    "BayerFlamsteed": "   Eta Aps",
    "ProperName": " ",
    "RA": "14.30388069",
    "Dec": "-81.00760147",
    "Distance": "42.8082191780822",
    "Mag": 4.89,
    "ColorIndex": 0.243
  },
  {
    "BayerFlamsteed": "   Alp Mic",
    "ProperName": " ",
    "RA": "20.83279986",
    "Dec": "-33.77967320",
    "Distance": "116.686114352392",
    "Mag": 4.89,
    "ColorIndex": 1.004
  },
  {
    "BayerFlamsteed": "132    Tau",
    "ProperName": " ",
    "RA": "5.81693318",
    "Dec": "+24.56755473",
    "Distance": "160",
    "Mag": 4.88,
    "ColorIndex": 1.021
  },
  {
    "BayerFlamsteed": "114    Tau",
    "ProperName": " ",
    "RA": "5.4605789",
    "Dec": "+21.93698219",
    "Distance": "213.219616204691",
    "Mag": 4.88,
    "ColorIndex": -0.14
  },
  {
    "BayerFlamsteed": " 72Rho Cet",
    "ProperName": " ",
    "RA": "2.4325034",
    "Dec": "-12.29045200",
    "Distance": "162.074554294976",
    "Mag": 4.88,
    "ColorIndex": -0.027
  },
  {
    "BayerFlamsteed": " 43    Sgr",
    "ProperName": " ",
    "RA": "19.29391266",
    "Dec": "-18.95288215",
    "Distance": "164.203612479475",
    "Mag": 4.88,
    "ColorIndex": 1.013
  },
  {
    "BayerFlamsteed": " 37    Com",
    "ProperName": " ",
    "RA": "13.00457929",
    "Dec": "+30.78503676",
    "Distance": "275.482093663912",
    "Mag": 4.88,
    "ColorIndex": 1.165
  },
  {
    "BayerFlamsteed": " 30Rho Vir",
    "ProperName": " ",
    "RA": "12.69805765",
    "Dec": "+10.23584294",
    "Distance": "36.90036900369",
    "Mag": 4.88,
    "ColorIndex": 0.076
  },
  {
    "BayerFlamsteed": " 29    CMa",
    "ProperName": " ",
    "RA": "7.31121689",
    "Dec": "-24.55870761",
    "Distance": "917.43119266055",
    "Mag": 4.88,
    "ColorIndex": -0.16
  },
  {
    "BayerFlamsteed": " 27    Psc",
    "ProperName": " ",
    "RA": "23.97789182",
    "Dec": "-03.55580738",
    "Distance": "68.5871056241427",
    "Mag": 4.88,
    "ColorIndex": 0.93
  },
  {
    "BayerFlamsteed": " 19    Dra",
    "ProperName": " ",
    "RA": "16.93371098",
    "Dec": "+65.13467204",
    "Distance": "15.0875075437538",
    "Mag": 4.88,
    "ColorIndex": 0.481
  },
  {
    "BayerFlamsteed": "  8Sig Cas",
    "ProperName": " ",
    "RA": "23.98348048",
    "Dec": "+55.75494069",
    "Distance": "467.289719626168",
    "Mag": 4.88,
    "ColorIndex": -0.071
  },
  {
    "BayerFlamsteed": "  5Tau Cas",
    "ProperName": " ",
    "RA": "23.78427407",
    "Dec": "+58.65185164",
    "Distance": "52.9661016949153",
    "Mag": 4.88,
    "ColorIndex": 1.122
  },
  {
    "BayerFlamsteed": "   Lam Col",
    "ProperName": " ",
    "RA": "5.88524565",
    "Dec": "-33.80143950",
    "Distance": "104.712041884817",
    "Mag": 4.88,
    "ColorIndex": -0.154
  },
  {
    "BayerFlamsteed": "   Iot Tel",
    "ProperName": " ",
    "RA": "19.58694271",
    "Dec": "-48.09911061",
    "Distance": "122.100122100122",
    "Mag": 4.88,
    "ColorIndex": 1.096
  },
  {
    "BayerFlamsteed": "   Eps Sct",
    "ProperName": " ",
    "RA": "18.7253445",
    "Dec": "-08.27524299",
    "Distance": "160.25641025641",
    "Mag": 4.88,
    "ColorIndex": 1.112
  },
  {
    "BayerFlamsteed": "   Eps Cha",
    "ProperName": " ",
    "RA": "11.99380313",
    "Dec": "-78.22181833",
    "Distance": "111.731843575419",
    "Mag": 4.88,
    "ColorIndex": -0.054
  },
  {
    "BayerFlamsteed": " 78Nu  Cet",
    "ProperName": " ",
    "RA": "2.59791361",
    "Dec": "+05.59330163",
    "Distance": "114.025085518814",
    "Mag": 4.87,
    "ColorIndex": 0.88
  },
  {
    "BayerFlamsteed": " 72    Cyg",
    "ProperName": " ",
    "RA": "21.57957705",
    "Dec": "+38.53382684",
    "Distance": "78.3699059561129",
    "Mag": 4.87,
    "ColorIndex": 1.085
  },
  {
    "BayerFlamsteed": " 58Zet Ari",
    "ProperName": " ",
    "RA": "3.24836521",
    "Dec": "+21.04462873",
    "Distance": "104.275286757039",
    "Mag": 4.87,
    "ColorIndex": -0.007
  },
  {
    "BayerFlamsteed": " 56    Sgr",
    "ProperName": " ",
    "RA": "19.77272756",
    "Dec": "-19.76089482",
    "Distance": "62.8140703517588",
    "Mag": 4.87,
    "ColorIndex": 1.061
  },
  {
    "BayerFlamsteed": " 46Xi  And",
    "ProperName": " ",
    "RA": "1.37233139",
    "Dec": "+45.52875660",
    "Distance": "59.9520383693046",
    "Mag": 4.87,
    "ColorIndex": 1.077
  },
  {
    "BayerFlamsteed": " 39    Eri",
    "ProperName": " ",
    "RA": "4.23991476",
    "Dec": "-10.25588890",
    "Distance": "63.2911392405063",
    "Mag": 4.87,
    "ColorIndex": 1.156
  },
  {
    "BayerFlamsteed": "  9    Hya",
    "ProperName": " ",
    "RA": "8.69537054",
    "Dec": "-15.94314865",
    "Distance": "62.5782227784731",
    "Mag": 4.87,
    "ColorIndex": 1.063
  },
  {
    "BayerFlamsteed": "   Del Pyx",
    "ProperName": " ",
    "RA": "8.92542099",
    "Dec": "-27.68162589",
    "Distance": "69.4444444444444",
    "Mag": 4.87,
    "ColorIndex": 0.142
  },
  {
    "BayerFlamsteed": " 58    Oph",
    "ProperName": " ",
    "RA": "17.72384859",
    "Dec": "-21.68308566",
    "Distance": "17.5438596491228",
    "Mag": 4.86,
    "ColorIndex": 0.469
  },
  {
    "BayerFlamsteed": " 42Psi Sgr",
    "ProperName": " ",
    "RA": "19.25899921",
    "Dec": "-25.25660677",
    "Distance": "101.112234580384",
    "Mag": 4.86,
    "ColorIndex": 0.569
  },
  {
    "BayerFlamsteed": " 42    Her",
    "ProperName": " ",
    "RA": "16.64580222",
    "Dec": "+48.92827739",
    "Distance": "115.340253748558",
    "Mag": 4.86,
    "ColorIndex": 1.562
  },
  {
    "BayerFlamsteed": " 32Nu 1Sgr",
    "ProperName": " ",
    "RA": "18.90282646",
    "Dec": "-22.74482020",
    "Distance": "568.181818181818",
    "Mag": 4.86,
    "ColorIndex": 1.412
  },
  {
    "BayerFlamsteed": " 31    Boo",
    "ProperName": " ",
    "RA": "14.69409903",
    "Dec": "+08.16175611",
    "Distance": "176.678445229682",
    "Mag": 4.86,
    "ColorIndex": 0.992
  },
  {
    "BayerFlamsteed": " 25Nu 2Dra",
    "ProperName": " ",
    "RA": "17.53774416",
    "Dec": "+55.17280674",
    "Distance": "30.6372549019608",
    "Mag": 4.86,
    "ColorIndex": 0.279
  },
  {
    "BayerFlamsteed": " 22Nu  Peg",
    "ProperName": " ",
    "RA": "22.09463666",
    "Dec": "+05.05828453",
    "Distance": "80.7754442649434",
    "Mag": 4.86,
    "ColorIndex": 1.443
  },
  {
    "BayerFlamsteed": " 19Xi  CrB",
    "ProperName": " ",
    "RA": "16.36830281",
    "Dec": "+30.89173424",
    "Distance": "56.3063063063063",
    "Mag": 4.86,
    "ColorIndex": 0.97
  },
  {
    "BayerFlamsteed": " 15    Eri",
    "ProperName": " ",
    "RA": "3.30613767",
    "Dec": "-22.51114978",
    "Distance": "78.1860828772479",
    "Mag": 4.86,
    "ColorIndex": 0.904
  },
  {
    "BayerFlamsteed": " 12    Lyn",
    "ProperName": " ",
    "RA": "6.77059796",
    "Dec": "+59.44169108",
    "Distance": "70.1262272089762",
    "Mag": 4.86,
    "ColorIndex": 0.084
  },
  {
    "BayerFlamsteed": "   Zet Pyx",
    "ProperName": " ",
    "RA": "8.66180298",
    "Dec": "-29.56086189",
    "Distance": "72.3065798987708",
    "Mag": 4.86,
    "ColorIndex": 0.9
  },
  {
    "BayerFlamsteed": "   Rho Pav",
    "ProperName": " ",
    "RA": "20.62645548",
    "Dec": "-61.52974415",
    "Distance": "59.7728631201435",
    "Mag": 4.86,
    "ColorIndex": 0.447
  },
  {
    "BayerFlamsteed": "   Mu  Nor",
    "ProperName": " ",
    "RA": "16.56806118",
    "Dec": "-44.04530868",
    "Distance": "1428.57142857143",
    "Mag": 4.86,
    "ColorIndex": 0.045
  },
  {
    "BayerFlamsteed": "   Eta Scl",
    "ProperName": " ",
    "RA": "0.46547573",
    "Dec": "-33.00704460",
    "Distance": "168.067226890756",
    "Mag": 4.86,
    "ColorIndex": 1.634
  },
  {
    "BayerFlamsteed": " 74    Oph",
    "ProperName": " ",
    "RA": "18.34779503",
    "Dec": "+03.37714467",
    "Distance": "82.5763831544178",
    "Mag": 4.85,
    "ColorIndex": 0.911
  },
  {
    "BayerFlamsteed": "   Lam Tel",
    "ProperName": " ",
    "RA": "18.97437645",
    "Dec": "-52.93860748",
    "Distance": "162.866449511401",
    "Mag": 4.85,
    "ColorIndex": -0.051
  },
  {
    "BayerFlamsteed": "   Eps Cir",
    "ProperName": " ",
    "RA": "15.2941355",
    "Dec": "-63.61048662",
    "Distance": "114.41647597254",
    "Mag": 4.85,
    "ColorIndex": 1.26
  },
  {
    "BayerFlamsteed": "126    Tau",
    "ProperName": " ",
    "RA": "5.68825428",
    "Dec": "+16.53418318",
    "Distance": "229.357798165138",
    "Mag": 4.84,
    "ColorIndex": -0.125
  },
  {
    "BayerFlamsteed": " 98Mu  Psc",
    "ProperName": " ",
    "RA": "1.50303973",
    "Dec": "+06.14393314",
    "Distance": "110.497237569061",
    "Mag": 4.84,
    "ColorIndex": 1.372
  },
  {
    "BayerFlamsteed": " 96Kap1Cet",
    "ProperName": " ",
    "RA": "3.32264969",
    "Dec": "+03.36997055",
    "Distance": "9.15918666422422",
    "Mag": 4.84,
    "ColorIndex": 0.681
  },
  {
    "BayerFlamsteed": " 60    Sgr",
    "ProperName": " ",
    "RA": "19.98254898",
    "Dec": "-26.19582739",
    "Distance": "104.384133611691",
    "Mag": 4.84,
    "ColorIndex": 0.882
  },
  {
    "BayerFlamsteed": " 60    And",
    "ProperName": " ",
    "RA": "2.22037244",
    "Dec": "+44.23168903",
    "Distance": "170.648464163823",
    "Mag": 4.84,
    "ColorIndex": 1.476
  },
  {
    "BayerFlamsteed": " 58    Leo",
    "ProperName": " ",
    "RA": "11.00934429",
    "Dec": "+03.61753351",
    "Distance": "104.82180293501",
    "Mag": 4.84,
    "ColorIndex": 1.144
  },
  {
    "BayerFlamsteed": " 47    Tau",
    "ProperName": " ",
    "RA": "4.23233081",
    "Dec": "+09.26389844",
    "Distance": "113.895216400911",
    "Mag": 4.84,
    "ColorIndex": 0.799
  },
  {
    "BayerFlamsteed": " 29    Her",
    "ProperName": " ",
    "RA": "16.54344396",
    "Dec": "+11.48823391",
    "Distance": "97.1817298347911",
    "Mag": 4.84,
    "ColorIndex": 1.495
  },
  {
    "BayerFlamsteed": " 20    Boo",
    "ProperName": " ",
    "RA": "14.32925578",
    "Dec": "+16.30680118",
    "Distance": "58.411214953271",
    "Mag": 4.84,
    "ColorIndex": 1.228
  },
  {
    "BayerFlamsteed": " 18    Dra",
    "ProperName": " ",
    "RA": "16.68197761",
    "Dec": "+64.58908555",
    "Distance": "208.768267223382",
    "Mag": 4.84,
    "ColorIndex": 1.212
  },
  {
    "BayerFlamsteed": "  8Del Tri",
    "ProperName": " ",
    "RA": "2.28400489",
    "Dec": "+34.22482985",
    "Distance": "10.8459869848156",
    "Mag": 4.84,
    "ColorIndex": 0.607
  },
  {
    "BayerFlamsteed": "  2Xi  Vir",
    "ProperName": " ",
    "RA": "11.75472355",
    "Dec": "+08.25817475",
    "Distance": "36.6434591425431",
    "Mag": 4.84,
    "ColorIndex": 0.174
  },
  {
    "BayerFlamsteed": "  1    Cas",
    "ProperName": " ",
    "RA": "23.11022495",
    "Dec": "+59.41976474",
    "Distance": "338.983050847458",
    "Mag": 4.84,
    "ColorIndex": -0.06
  },
  {
    "BayerFlamsteed": "   Rho Gru",
    "ProperName": " ",
    "RA": "22.7249913",
    "Dec": "-41.41411843",
    "Distance": "67.9809653297077",
    "Mag": 4.84,
    "ColorIndex": 1.027
  },
  {
    "BayerFlamsteed": "   Eta Gru",
    "ProperName": " ",
    "RA": "22.76051446",
    "Dec": "-53.50016316",
    "Distance": "120.918984280532",
    "Mag": 4.84,
    "ColorIndex": 1.18
  },
  {
    "BayerFlamsteed": " 83Eps Cet",
    "ProperName": " ",
    "RA": "2.65936992",
    "Dec": "-11.87158159",
    "Distance": "27.0343336036767",
    "Mag": 4.83,
    "ColorIndex": 0.447
  },
  {
    "BayerFlamsteed": " 48Ome And",
    "ProperName": " ",
    "RA": "1.46085698",
    "Dec": "+45.40695287",
    "Distance": "28.3045570336824",
    "Mag": 4.83,
    "ColorIndex": 0.421
  },
  {
    "BayerFlamsteed": " 44    Boo",
    "ProperName": " ",
    "RA": "15.06324494",
    "Dec": "+47.65401409",
    "Distance": "12.7567291746396",
    "Mag": 4.83,
    "ColorIndex": 0.647
  },
  {
    "BayerFlamsteed": " 30    Her",
    "ProperName": " ",
    "RA": "16.47736803",
    "Dec": "+41.88169065",
    "Distance": "110.741971207087",
    "Mag": 4.83,
    "ColorIndex": 1.289
  },
  {
    "BayerFlamsteed": " 26Ups1Cas",
    "ProperName": " ",
    "RA": "0.91672028",
    "Dec": "+58.97279657",
    "Distance": "124.688279301746",
    "Mag": 4.83,
    "ColorIndex": 1.216
  },
  {
    "BayerFlamsteed": "   Zet Hyi",
    "ProperName": " ",
    "RA": "2.75903683",
    "Dec": "-67.61672400",
    "Distance": "90.4159132007233",
    "Mag": 4.83,
    "ColorIndex": 0.058
  },
  {
    "BayerFlamsteed": "   Xi 1Cen",
    "ProperName": " ",
    "RA": "13.05926331",
    "Dec": "-49.52723627",
    "Distance": "66.8002672010688",
    "Mag": 4.83,
    "ColorIndex": 0.029
  },
  {
    "BayerFlamsteed": "   Eta Sct",
    "ProperName": " ",
    "RA": "18.95100947",
    "Dec": "-05.84621426",
    "Distance": "63.4115409004439",
    "Mag": 4.83,
    "ColorIndex": 1.057
  },
  {
    "BayerFlamsteed": "   Eps CrA",
    "ProperName": " ",
    "RA": "18.97874276",
    "Dec": "-37.10708855",
    "Distance": "29.9132515704457",
    "Mag": 4.83,
    "ColorIndex": 0.396
  },
  {
    "BayerFlamsteed": "104    Aqr",
    "ProperName": " ",
    "RA": "23.69605484",
    "Dec": "-17.81653327",
    "Distance": "197.23865877712",
    "Mag": 4.82,
    "ColorIndex": 0.822
  },
  {
    "BayerFlamsteed": " 57Sig Aqr",
    "ProperName": " ",
    "RA": "22.51078194",
    "Dec": "-10.67788619",
    "Distance": "81.3669650122051",
    "Mag": 4.82,
    "ColorIndex": -0.053
  },
  {
    "BayerFlamsteed": " 52Ups Dra",
    "ProperName": " ",
    "RA": "18.90660174",
    "Dec": "+71.29708956",
    "Distance": "105.596620908131",
    "Mag": 4.82,
    "ColorIndex": 1.151
  },
  {
    "BayerFlamsteed": " 52    Her",
    "ProperName": " ",
    "RA": "16.82061092",
    "Dec": "+45.98344780",
    "Distance": "53.7056928034372",
    "Mag": 4.82,
    "ColorIndex": 0.087
  },
  {
    "BayerFlamsteed": " 50Sig Ser",
    "ProperName": " ",
    "RA": "16.3679",
    "Dec": "+01.02892219",
    "Distance": "27.3522975929978",
    "Mag": 4.82,
    "ColorIndex": 0.338
  },
  {
    "BayerFlamsteed": " 44Pi  Ser",
    "ProperName": " ",
    "RA": "16.03824696",
    "Dec": "+22.80439543",
    "Distance": "54.2888165038002",
    "Mag": 4.82,
    "ColorIndex": 0.066
  },
  {
    "BayerFlamsteed": " 42    Dra",
    "ProperName": " ",
    "RA": "18.43305219",
    "Dec": "+65.56354628",
    "Distance": "97.2762645914397",
    "Mag": 4.82,
    "ColorIndex": 1.179
  },
  {
    "BayerFlamsteed": " 36    UMa",
    "ProperName": " ",
    "RA": "10.51049009",
    "Dec": "+55.98061759",
    "Distance": "12.8501670521717",
    "Mag": 4.82,
    "ColorIndex": 0.541
  },
  {
    "BayerFlamsteed": " 31    Peg",
    "ProperName": " ",
    "RA": "22.35863091",
    "Dec": "+12.20517262",
    "Distance": "297.619047619048",
    "Mag": 4.82,
    "ColorIndex": -0.132
  },
  {
    "BayerFlamsteed": " 30    Oph",
    "ProperName": " ",
    "RA": "17.01767375",
    "Dec": "-04.22245426",
    "Distance": "123.304562268804",
    "Mag": 4.82,
    "ColorIndex": 1.483
  },
  {
    "BayerFlamsteed": " 25    CVn",
    "ProperName": " ",
    "RA": "13.62436025",
    "Dec": "+36.29484073",
    "Distance": "58.7889476778366",
    "Mag": 4.82,
    "ColorIndex": 0.239
  },
  {
    "BayerFlamsteed": " 22Eta Cap",
    "ProperName": " ",
    "RA": "21.0734235",
    "Dec": "-19.85493122",
    "Distance": "48.4496124031008",
    "Mag": 4.82,
    "ColorIndex": 0.169
  },
  {
    "BayerFlamsteed": " 15    CMa",
    "ProperName": " ",
    "RA": "6.89247515",
    "Dec": "-20.22426366",
    "Distance": "495.049504950495",
    "Mag": 4.82,
    "ColorIndex": -0.212
  },
  {
    "BayerFlamsteed": " 12    Boo",
    "ProperName": " ",
    "RA": "14.17331901",
    "Dec": "+25.09182293",
    "Distance": "36.6703337000367",
    "Mag": 4.82,
    "ColorIndex": 0.541
  },
  {
    "BayerFlamsteed": " 11Mu  Aur",
    "ProperName": " ",
    "RA": "5.22381432",
    "Dec": "+38.48467394",
    "Distance": "49.800796812749",
    "Mag": 4.82,
    "ColorIndex": 0.189
  },
  {
    "BayerFlamsteed": "  8    And",
    "ProperName": " ",
    "RA": "23.29572675",
    "Dec": "+49.01528336",
    "Distance": "200.803212851406",
    "Mag": 4.82,
    "ColorIndex": 1.668
  },
  {
    "BayerFlamsteed": "139    Tau",
    "ProperName": " ",
    "RA": "5.96657158",
    "Dec": "+25.95391597",
    "Distance": "645.161290322581",
    "Mag": 4.81,
    "ColorIndex": -0.088
  },
  {
    "BayerFlamsteed": "105Phi Vir",
    "ProperName": " ",
    "RA": "14.4700612",
    "Dec": "-02.22795009",
    "Distance": "41.407867494824",
    "Mag": 4.81,
    "ColorIndex": 0.693
  },
  {
    "BayerFlamsteed": " 55    Cyg",
    "ProperName": " ",
    "RA": "20.81563705",
    "Dec": "+46.11414081",
    "Distance": "689.655172413793",
    "Mag": 4.81,
    "ColorIndex": 0.571
  },
  {
    "BayerFlamsteed": " 29    Vul",
    "ProperName": " ",
    "RA": "20.64202565",
    "Dec": "+21.20117964",
    "Distance": "65.4878847413229",
    "Mag": 4.81,
    "ColorIndex": -0.03
  },
  {
    "BayerFlamsteed": " 21    Sgr",
    "ProperName": " ",
    "RA": "18.42250987",
    "Dec": "-20.54160918",
    "Distance": "183.150183150183",
    "Mag": 4.81,
    "ColorIndex": 1.31
  },
  {
    "BayerFlamsteed": " 15    Ori",
    "ProperName": " ",
    "RA": "5.16165538",
    "Dec": "+15.59727157",
    "Distance": "97.65625",
    "Mag": 4.81,
    "ColorIndex": 0.313
  },
  {
    "BayerFlamsteed": " 14Eps Crt",
    "ProperName": " ",
    "RA": "11.41016816",
    "Dec": "-10.85938276",
    "Distance": "111.731843575419",
    "Mag": 4.81,
    "ColorIndex": 1.556
  },
  {
    "BayerFlamsteed": "   The Dor",
    "ProperName": " ",
    "RA": "5.22928523",
    "Dec": "-67.18534976",
    "Distance": "167.50418760469",
    "Mag": 4.81,
    "ColorIndex": 1.274
  },
  {
    "BayerFlamsteed": "   Omi Col",
    "ProperName": " ",
    "RA": "5.29139537",
    "Dec": "-34.89438961",
    "Distance": "33.7495781302734",
    "Mag": 4.81,
    "ColorIndex": 0.987
  },
  {
    "BayerFlamsteed": " 68    Her",
    "ProperName": " ",
    "RA": "17.28876958",
    "Dec": "+33.10011419",
    "Distance": "265.25198938992",
    "Mag": 4.8,
    "ColorIndex": -0.166
  },
  {
    "BayerFlamsteed": " 65Psi Eri",
    "ProperName": " ",
    "RA": "5.0239847",
    "Dec": "-07.17397802",
    "Distance": "293.255131964809",
    "Mag": 4.8,
    "ColorIndex": -0.164
  },
  {
    "BayerFlamsteed": " 64Del2Tau",
    "ProperName": " ",
    "RA": "4.4015816",
    "Dec": "+17.44421161",
    "Distance": "44.7227191413238",
    "Mag": 4.8,
    "ColorIndex": 0.154
  },
  {
    "BayerFlamsteed": " 57    Cyg",
    "ProperName": " ",
    "RA": "20.88742924",
    "Dec": "+44.38726652",
    "Distance": "153.609831029186",
    "Mag": 4.8,
    "ColorIndex": -0.134
  },
  {
    "BayerFlamsteed": " 53    Per",
    "ProperName": " ",
    "RA": "4.35920796",
    "Dec": "+46.49896290",
    "Distance": "142.247510668563",
    "Mag": 4.8,
    "ColorIndex": -0.022
  },
  {
    "BayerFlamsteed": " 52Pi  Aqr",
    "ProperName": " ",
    "RA": "22.42128123",
    "Dec": "+01.37739245",
    "Distance": "337.837837837838",
    "Mag": 4.8,
    "ColorIndex": -0.171
  },
  {
    "BayerFlamsteed": " 50Psi2Aur",
    "ProperName": " ",
    "RA": "6.6555075",
    "Dec": "+42.48901214",
    "Distance": "132.62599469496",
    "Mag": 4.8,
    "ColorIndex": 1.236
  },
  {
    "BayerFlamsteed": " 43Omi Peg",
    "ProperName": " ",
    "RA": "22.69594714",
    "Dec": "+29.30768920",
    "Distance": "93.4579439252336",
    "Mag": 4.8,
    "ColorIndex": -0.013
  },
  {
    "BayerFlamsteed": " 41Ome Boo",
    "ProperName": " ",
    "RA": "15.03514226",
    "Dec": "+25.00825646",
    "Distance": "110.253583241455",
    "Mag": 4.8,
    "ColorIndex": 1.506
  },
  {
    "BayerFlamsteed": " 41    Com",
    "ProperName": " ",
    "RA": "13.11964107",
    "Dec": "+27.62490672",
    "Distance": "93.0232558139535",
    "Mag": 4.8,
    "ColorIndex": 1.482
  },
  {
    "BayerFlamsteed": " 34    Boo",
    "ProperName": " ",
    "RA": "14.72371441",
    "Dec": "+26.52789077",
    "Distance": "272.479564032698",
    "Mag": 4.8,
    "ColorIndex": 1.672
  },
  {
    "BayerFlamsteed": " 30    Cyg",
    "ProperName": " ",
    "RA": "20.22167833",
    "Dec": "+46.81567038",
    "Distance": "219.78021978022",
    "Mag": 4.8,
    "ColorIndex": 0.1
  },
  {
    "BayerFlamsteed": " 27    Hya",
    "ProperName": " ",
    "RA": "9.3413961",
    "Dec": "-09.55562948",
    "Distance": "74.6825989544436",
    "Mag": 4.8,
    "ColorIndex": 0.913
  },
  {
    "BayerFlamsteed": " 23    Com",
    "ProperName": " ",
    "RA": "12.58086605",
    "Dec": "+22.62918961",
    "Distance": "111.856823266219",
    "Mag": 4.8,
    "ColorIndex": 0.012
  },
  {
    "BayerFlamsteed": " 19Xi  Cas",
    "ProperName": " ",
    "RA": "0.70107895",
    "Dec": "+50.51254151",
    "Distance": "340.136054421769",
    "Mag": 4.8,
    "ColorIndex": -0.105
  },
  {
    "BayerFlamsteed": " 18    UMa",
    "ProperName": " ",
    "RA": "9.26979967",
    "Dec": "+54.02171207",
    "Distance": "36.2976406533575",
    "Mag": 4.8,
    "ColorIndex": 0.199
  },
  {
    "BayerFlamsteed": " 13Zet Eri",
    "ProperName": " ",
    "RA": "3.26389633",
    "Dec": "-08.81984050",
    "Distance": "36.7917586460633",
    "Mag": 4.8,
    "ColorIndex": 0.232
  },
  {
    "BayerFlamsteed": " 13Sig2UMa",
    "ProperName": " ",
    "RA": "9.17320171",
    "Dec": "+67.13423886",
    "Distance": "20.4624514016779",
    "Mag": 4.8,
    "ColorIndex": 0.489
  },
  {
    "BayerFlamsteed": "  4    UMi",
    "ProperName": " ",
    "RA": "14.1475025",
    "Dec": "+77.54743312",
    "Distance": "153.374233128834",
    "Mag": 4.8,
    "ColorIndex": 1.368
  },
  {
    "BayerFlamsteed": "   The1Mic",
    "ProperName": " ",
    "RA": "21.34599543",
    "Dec": "-40.80950852",
    "Distance": "57.1755288736421",
    "Mag": 4.8,
    "ColorIndex": 0.029
  },
  {
    "BayerFlamsteed": " 89Chi Peg",
    "ProperName": " ",
    "RA": "0.24336336",
    "Dec": "+20.20669720",
    "Distance": "99.9000999000999",
    "Mag": 4.79,
    "ColorIndex": 1.572
  },
  {
    "BayerFlamsteed": " 66    Oph",
    "ProperName": " ",
    "RA": "18.00438821",
    "Dec": "+04.36864674",
    "Distance": "207.46887966805",
    "Mag": 4.79,
    "ColorIndex": -0.1
  },
  {
    "BayerFlamsteed": " 24    Cep",
    "ProperName": " ",
    "RA": "22.16343527",
    "Dec": "+72.34119936",
    "Distance": "115.740740740741",
    "Mag": 4.79,
    "ColorIndex": 0.919
  },
  {
    "BayerFlamsteed": " 22    Sco",
    "ProperName": " ",
    "RA": "16.50346608",
    "Dec": "-25.11515935",
    "Distance": "120.481927710843",
    "Mag": 4.79,
    "ColorIndex": -0.116
  },
  {
    "BayerFlamsteed": " 11Kap CrB",
    "ProperName": " ",
    "RA": "15.85387148",
    "Dec": "+35.65822638",
    "Distance": "31.1235605353252",
    "Mag": 4.79,
    "ColorIndex": 0.996
  },
  {
    "BayerFlamsteed": "  9Lam Ari",
    "ProperName": " ",
    "RA": "1.96549317",
    "Dec": "+23.59609610",
    "Distance": "40.8329930583912",
    "Mag": 4.79,
    "ColorIndex": 0.29
  },
  {
    "BayerFlamsteed": "  2    Aur",
    "ProperName": " ",
    "RA": "4.877222",
    "Dec": "+36.70319636",
    "Distance": "172.117039586919",
    "Mag": 4.79,
    "ColorIndex": 1.414
  },
  {
    "BayerFlamsteed": "   Mu 1Gru",
    "ProperName": " ",
    "RA": "22.26024577",
    "Dec": "-41.34675029",
    "Distance": "80.3858520900322",
    "Mag": 4.79,
    "ColorIndex": 0.79
  },
  {
    "BayerFlamsteed": "   Eta Mus",
    "ProperName": " ",
    "RA": "13.25416605",
    "Dec": "-67.89456330",
    "Distance": "124.378109452736",
    "Mag": 4.79,
    "ColorIndex": -0.078
  },
  {
    "BayerFlamsteed": " 64    Eri",
    "ProperName": " ",
    "RA": "4.99880918",
    "Dec": "-12.53720450",
    "Distance": "89.2060660124888",
    "Mag": 4.78,
    "ColorIndex": 0.267
  },
  {
    "BayerFlamsteed": " 60Sig Vir",
    "ProperName": " ",
    "RA": "13.29341302",
    "Dec": "+05.46984502",
    "Distance": "165.837479270315",
    "Mag": 4.78,
    "ColorIndex": 1.638
  },
  {
    "BayerFlamsteed": " 58    And",
    "ProperName": " ",
    "RA": "2.1414294",
    "Dec": "+37.85918337",
    "Distance": "60.6796116504854",
    "Mag": 4.78,
    "ColorIndex": 0.12
  },
  {
    "BayerFlamsteed": " 51    Oph",
    "ProperName": " ",
    "RA": "17.52359746",
    "Dec": "-23.96258036",
    "Distance": "130.718954248366",
    "Mag": 4.78,
    "ColorIndex": 0.016
  },
  {
    "BayerFlamsteed": " 51    Hya",
    "ProperName": " ",
    "RA": "14.38497437",
    "Dec": "-27.75373395",
    "Distance": "53.5618639528656",
    "Mag": 4.78,
    "ColorIndex": 1.3
  },
  {
    "BayerFlamsteed": " 40    Leo",
    "ProperName": " ",
    "RA": "10.32897506",
    "Dec": "+19.47143591",
    "Distance": "21.1685012701101",
    "Mag": 4.78,
    "ColorIndex": 0.452
  },
  {
    "BayerFlamsteed": " 35    Peg",
    "ProperName": " ",
    "RA": "22.46429917",
    "Dec": "+04.69641070",
    "Distance": "49.0436488474743",
    "Mag": 4.78,
    "ColorIndex": 1.039
  },
  {
    "BayerFlamsteed": " 32    Peg",
    "ProperName": " ",
    "RA": "22.35536877",
    "Dec": "+28.33051592",
    "Distance": "186.219739292365",
    "Mag": 4.78,
    "ColorIndex": -0.01
  },
  {
    "BayerFlamsteed": " 27    Lyn",
    "ProperName": " ",
    "RA": "8.14097319",
    "Dec": "+51.50667575",
    "Distance": "66.8449197860963",
    "Mag": 4.78,
    "ColorIndex": 0.048
  },
  {
    "BayerFlamsteed": " 20    Cet",
    "ProperName": " ",
    "RA": "0.88346958",
    "Dec": "-01.14422060",
    "Distance": "159.235668789809",
    "Mag": 4.78,
    "ColorIndex": 1.55
  },
  {
    "BayerFlamsteed": " 12    Com",
    "ProperName": " ",
    "RA": "12.37508865",
    "Dec": "+25.84618178",
    "Distance": "83.8222967309304",
    "Mag": 4.78,
    "ColorIndex": 0.515
  },
  {
    "BayerFlamsteed": "   The Oct",
    "ProperName": " ",
    "RA": "0.02662528",
    "Dec": "-77.06529438",
    "Distance": "67.7048070412999",
    "Mag": 4.78,
    "ColorIndex": 1.254
  },
  {
    "BayerFlamsteed": "   The Ant",
    "ProperName": " ",
    "RA": "9.73670284",
    "Dec": "-27.76956287",
    "Distance": "117.785630153121",
    "Mag": 4.78,
    "ColorIndex": 0.516
  },
  {
    "BayerFlamsteed": "   Iot2Sco",
    "ProperName": " ",
    "RA": "17.83641944",
    "Dec": "-40.09042823",
    "Distance": "1136.36363636364",
    "Mag": 4.78,
    "ColorIndex": 0.259
  },
  {
    "BayerFlamsteed": " 87    Leo",
    "ProperName": " ",
    "RA": "11.50524494",
    "Dec": "-03.00345887",
    "Distance": "185.185185185185",
    "Mag": 4.77,
    "ColorIndex": 1.529
  },
  {
    "BayerFlamsteed": " 69Tau Oph",
    "ProperName": " ",
    "RA": "18.05136409",
    "Dec": "-08.18025785",
    "Distance": "52.0291363163372",
    "Mag": 4.77,
    "ColorIndex": 0.41
  },
  {
    "BayerFlamsteed": " 49    Ori",
    "ProperName": " ",
    "RA": "5.64808105",
    "Dec": "-07.21270844",
    "Distance": "47.0809792843691",
    "Mag": 4.77,
    "ColorIndex": 0.139
  },
  {
    "BayerFlamsteed": " 45    Dra",
    "ProperName": " ",
    "RA": "18.54292285",
    "Dec": "+57.04561627",
    "Distance": "884.955752212389",
    "Mag": 4.77,
    "ColorIndex": 0.611
  },
  {
    "BayerFlamsteed": " 40Psi Vir",
    "ProperName": " ",
    "RA": "12.9058817",
    "Dec": "-09.53894647",
    "Distance": "127.877237851662",
    "Mag": 4.77,
    "ColorIndex": 1.59
  },
  {
    "BayerFlamsteed": " 34    Cyg",
    "ProperName": " ",
    "RA": "20.29644567",
    "Dec": "+38.03294710",
    "Distance": "1923.07692307692",
    "Mag": 4.77,
    "ColorIndex": 0.377
  },
  {
    "BayerFlamsteed": " 28Ome Dra",
    "ProperName": " ",
    "RA": "17.61585831",
    "Dec": "+68.75718933",
    "Distance": "23.4631628343501",
    "Mag": 4.77,
    "ColorIndex": 0.43
  },
  {
    "BayerFlamsteed": " 26    Hya",
    "ProperName": " ",
    "RA": "9.32955521",
    "Dec": "-11.97488214",
    "Distance": "98.0392156862745",
    "Mag": 4.77,
    "ColorIndex": 0.927
  },
  {
    "BayerFlamsteed": " 17Phi1Cet",
    "ProperName": " ",
    "RA": "0.73650151",
    "Dec": "-10.60927365",
    "Distance": "64.3500643500644",
    "Mag": 4.77,
    "ColorIndex": 0.998
  },
  {
    "BayerFlamsteed": " 17    Mon",
    "ProperName": " ",
    "RA": "6.78884564",
    "Dec": "+08.03728277",
    "Distance": "148.588410104012",
    "Mag": 4.77,
    "ColorIndex": 1.396
  },
  {
    "BayerFlamsteed": " 11Rho Cap",
    "ProperName": " ",
    "RA": "20.48100657",
    "Dec": "-17.81366828",
    "Distance": "30.2663438256659",
    "Mag": 4.77,
    "ColorIndex": 0.386
  },
  {
    "BayerFlamsteed": "  8Nu  Cap",
    "ProperName": " ",
    "RA": "20.34439076",
    "Dec": "-12.75904403",
    "Distance": "83.4724540901503",
    "Mag": 4.77,
    "ColorIndex": -0.047
  },
  {
    "BayerFlamsteed": " 69    Vir",
    "ProperName": " ",
    "RA": "13.45756613",
    "Dec": "-15.97363075",
    "Distance": "79.1765637371338",
    "Mag": 4.76,
    "ColorIndex": 1.096
  },
  {
    "BayerFlamsteed": " 56    Peg",
    "ProperName": " ",
    "RA": "23.11853866",
    "Dec": "+25.46833959",
    "Distance": "164.744645799012",
    "Mag": 4.76,
    "ColorIndex": 1.285
  },
  {
    "BayerFlamsteed": " 56    Ori",
    "ProperName": " ",
    "RA": "5.87401187",
    "Dec": "+01.85515790",
    "Distance": "341.296928327645",
    "Mag": 4.76,
    "ColorIndex": 1.382
  },
  {
    "BayerFlamsteed": " 55    UMa",
    "ProperName": " ",
    "RA": "11.31887317",
    "Dec": "+38.18572227",
    "Distance": "56.1167227833894",
    "Mag": 4.76,
    "ColorIndex": 0.113
  },
  {
    "BayerFlamsteed": " 36    Com",
    "ProperName": " ",
    "RA": "12.98207385",
    "Dec": "+17.40936063",
    "Distance": "91.6590284142988",
    "Mag": 4.76,
    "ColorIndex": 1.568
  },
  {
    "BayerFlamsteed": "  9    Cep",
    "ProperName": " ",
    "RA": "21.63200744",
    "Dec": "+62.08194667",
    "Distance": "1388.88888888889",
    "Mag": 4.76,
    "ColorIndex": 0.246
  },
  {
    "BayerFlamsteed": "  5    CVn",
    "ProperName": " ",
    "RA": "12.40041175",
    "Dec": "+51.56222925",
    "Distance": "120.481927710843",
    "Mag": 4.76,
    "ColorIndex": 0.877
  },
  {
    "BayerFlamsteed": "  2Tau2Eri",
    "ProperName": " ",
    "RA": "2.85065164",
    "Dec": "-21.00397890",
    "Distance": "56.0224089635854",
    "Mag": 4.76,
    "ColorIndex": 0.906
  },
  {
    "BayerFlamsteed": "  1    Vul",
    "ProperName": " ",
    "RA": "19.27028877",
    "Dec": "+21.39044277",
    "Distance": "279.329608938547",
    "Mag": 4.76,
    "ColorIndex": -0.058
  },
  {
    "BayerFlamsteed": "   Zet Aps",
    "ProperName": " ",
    "RA": "17.36653743",
    "Dec": "-67.77064960",
    "Distance": "95.6022944550669",
    "Mag": 4.76,
    "ColorIndex": 1.194
  },
  {
    "BayerFlamsteed": "   Nu  Hyi",
    "ProperName": " ",
    "RA": "2.84126023",
    "Dec": "-75.06688108",
    "Distance": "100.806451612903",
    "Mag": 4.76,
    "ColorIndex": 1.337
  },
  {
    "BayerFlamsteed": "   Lam1Phe",
    "ProperName": " ",
    "RA": "0.52357113",
    "Dec": "-48.80356171",
    "Distance": "52.7148128624143",
    "Mag": 4.76,
    "ColorIndex": 0.018
  },
  {
    "BayerFlamsteed": "   Lam Ara",
    "ProperName": " ",
    "RA": "17.67325897",
    "Dec": "-49.41515481",
    "Distance": "21.8722659667542",
    "Mag": 4.76,
    "ColorIndex": 0.415
  },
  {
    "BayerFlamsteed": " 43Kap Lib",
    "ProperName": " ",
    "RA": "15.69911629",
    "Dec": "-19.67857446",
    "Distance": "122.549019607843",
    "Mag": 4.75,
    "ColorIndex": 1.574
  },
  {
    "BayerFlamsteed": " 34Omi Cep",
    "ProperName": " ",
    "RA": "23.3103908",
    "Dec": "+68.11141807",
    "Distance": "64.5994832041344",
    "Mag": 4.75,
    "ColorIndex": 0.836
  },
  {
    "BayerFlamsteed": " 21Iot Boo",
    "ProperName": " ",
    "RA": "14.26946375",
    "Dec": "+51.36701398",
    "Distance": "29.8151460942159",
    "Mag": 4.75,
    "ColorIndex": 0.236
  },
  {
    "BayerFlamsteed": "  4Psi2Lup",
    "ProperName": " ",
    "RA": "15.7113992",
    "Dec": "-34.71033325",
    "Distance": "121.359223300971",
    "Mag": 4.75,
    "ColorIndex": -0.151
  },
  {
    "BayerFlamsteed": "  4    Cen",
    "ProperName": " ",
    "RA": "13.88681897",
    "Dec": "-31.92758624",
    "Distance": "205.338809034908",
    "Mag": 4.75,
    "ColorIndex": -0.111
  },
  {
    "BayerFlamsteed": "   Phi1Pav",
    "ProperName": " ",
    "RA": "20.59299116",
    "Dec": "-60.58129819",
    "Distance": "27.5709953129308",
    "Mag": 4.75,
    "ColorIndex": 0.291
  },
  {
    "BayerFlamsteed": "   Mu  Mus",
    "ProperName": " ",
    "RA": "11.80402484",
    "Dec": "-66.81487085",
    "Distance": "132.450331125828",
    "Mag": 4.75,
    "ColorIndex": 1.522
  },
  {
    "BayerFlamsteed": " 90Ups Psc",
    "ProperName": " ",
    "RA": "1.3244383",
    "Dec": "+27.26408682",
    "Distance": "95.3288846520496",
    "Mag": 4.74,
    "ColorIndex": 0.032
  },
  {
    "BayerFlamsteed": " 76Sig Cet",
    "ProperName": " ",
    "RA": "2.53479923",
    "Dec": "-15.24431990",
    "Distance": "25.8197779499096",
    "Mag": 4.74,
    "ColorIndex": 0.454
  },
  {
    "BayerFlamsteed": " 61    Vir",
    "ProperName": " ",
    "RA": "13.30693666",
    "Dec": "-18.30861062",
    "Distance": "8.52514919011083",
    "Mag": 4.74,
    "ColorIndex": 0.709
  },
  {
    "BayerFlamsteed": " 59    Cyg",
    "ProperName": " ",
    "RA": "20.99709729",
    "Dec": "+47.52094507",
    "Distance": "344.827586206897",
    "Mag": 4.74,
    "ColorIndex": -0.084
  },
  {
    "BayerFlamsteed": " 38Rho Ser",
    "ProperName": " ",
    "RA": "15.85442865",
    "Dec": "+20.97787548",
    "Distance": "121.065375302663",
    "Mag": 4.74,
    "ColorIndex": 1.534
  },
  {
    "BayerFlamsteed": " 31Omi Aqr",
    "ProperName": " ",
    "RA": "22.05523045",
    "Dec": "-02.15533588",
    "Distance": "116.822429906542",
    "Mag": 4.74,
    "ColorIndex": -0.1
  },
  {
    "BayerFlamsteed": " 17    Eri",
    "ProperName": " ",
    "RA": "3.51029163",
    "Dec": "-05.07516332",
    "Distance": "116.686114352392",
    "Mag": 4.74,
    "ColorIndex": -0.092
  },
  {
    "BayerFlamsteed": " 14Lam Cas",
    "ProperName": " ",
    "RA": "0.52953275",
    "Dec": "+54.52232473",
    "Distance": "108.695652173913",
    "Mag": 4.74,
    "ColorIndex": -0.098
  },
  {
    "BayerFlamsteed": "  8Rho UMa",
    "ProperName": " ",
    "RA": "9.04242386",
    "Dec": "+67.62957486",
    "Distance": "88.1057268722467",
    "Mag": 4.74,
    "ColorIndex": 1.542
  },
  {
    "BayerFlamsteed": "  8    Cyg",
    "ProperName": " ",
    "RA": "19.52953362",
    "Dec": "+34.45297707",
    "Distance": "192.307692307692",
    "Mag": 4.74,
    "ColorIndex": -0.15
  },
  {
    "BayerFlamsteed": "  4    Sgr",
    "ProperName": " ",
    "RA": "17.99654353",
    "Dec": "-23.81601126",
    "Distance": "133.868808567604",
    "Mag": 4.74,
    "ColorIndex": -0.03
  },
  {
    "BayerFlamsteed": "   Zet CrA",
    "ProperName": " ",
    "RA": "19.05189794",
    "Dec": "-42.09499443",
    "Distance": "56.3380281690141",
    "Mag": 4.74,
    "ColorIndex": -0.027
  },
  {
    "BayerFlamsteed": " 65    And",
    "ProperName": " ",
    "RA": "2.42705582",
    "Dec": "+50.27866588",
    "Distance": "105.708245243129",
    "Mag": 4.73,
    "ColorIndex": 1.532
  },
  {
    "BayerFlamsteed": " 61    Leo",
    "ProperName": " ",
    "RA": "11.03046358",
    "Dec": "-02.48449705",
    "Distance": "157.728706624606",
    "Mag": 4.73,
    "ColorIndex": 1.593
  },
  {
    "BayerFlamsteed": " 38    Gem",
    "ProperName": " ",
    "RA": "6.91072051",
    "Dec": "+13.17801628",
    "Distance": "27.9407655769768",
    "Mag": 4.73,
    "ColorIndex": 0.321
  },
  {
    "BayerFlamsteed": " 16Tau CrB",
    "ProperName": " ",
    "RA": "16.14953571",
    "Dec": "+36.49010935",
    "Distance": "34.6740638002774",
    "Mag": 4.73,
    "ColorIndex": 1.015
  },
  {
    "BayerFlamsteed": "  6Mu  Aqr",
    "ProperName": " ",
    "RA": "20.87755716",
    "Dec": "-08.98323782",
    "Distance": "47.5963826749167",
    "Mag": 4.73,
    "ColorIndex": 0.325
  },
  {
    "BayerFlamsteed": "   Del Nor",
    "ProperName": " ",
    "RA": "16.10817322",
    "Dec": "-45.17327517",
    "Distance": "37.8644452858766",
    "Mag": 4.73,
    "ColorIndex": 0.23
  },
  {
    "BayerFlamsteed": " 43Kap Cap",
    "ProperName": " ",
    "RA": "21.71094923",
    "Dec": "-18.86630105",
    "Distance": "89.126559714795",
    "Mag": 4.72,
    "ColorIndex": 0.868
  },
  {
    "BayerFlamsteed": " 41    Oph",
    "ProperName": " ",
    "RA": "17.27686266",
    "Dec": "-00.44514192",
    "Distance": "66.8002672010688",
    "Mag": 4.72,
    "ColorIndex": 1.119
  },
  {
    "BayerFlamsteed": " 36Psi Cas",
    "ProperName": " ",
    "RA": "1.4321956",
    "Dec": "+68.12994739",
    "Distance": "59.206631142688",
    "Mag": 4.72,
    "ColorIndex": 1.047
  },
  {
    "BayerFlamsteed": " 31Ups Aur",
    "ProperName": " ",
    "RA": "5.85066951",
    "Dec": "+37.30568175",
    "Distance": "145.772594752187",
    "Mag": 4.72,
    "ColorIndex": 1.621
  },
  {
    "BayerFlamsteed": " 30    LMi",
    "ProperName": " ",
    "RA": "10.43190753",
    "Dec": "+33.79626364",
    "Distance": "63.4517766497462",
    "Mag": 4.72,
    "ColorIndex": 0.26
  },
  {
    "BayerFlamsteed": " 22    Ori",
    "ProperName": " ",
    "RA": "5.36270768",
    "Dec": "-00.38246922",
    "Distance": "395.256916996047",
    "Mag": 4.72,
    "ColorIndex": -0.168
  },
  {
    "BayerFlamsteed": " 20    CVn",
    "ProperName": " ",
    "RA": "13.2923992",
    "Dec": "+40.57256275",
    "Distance": "87.7963125548727",
    "Mag": 4.72,
    "ColorIndex": 0.306
  },
  {
    "BayerFlamsteed": " 19    Pup",
    "ProperName": " ",
    "RA": "8.18786703",
    "Dec": "-12.92701835",
    "Distance": "56.6893424036281",
    "Mag": 4.72,
    "ColorIndex": 0.939
  },
  {
    "BayerFlamsteed": " 11    Com",
    "ProperName": " ",
    "RA": "12.34530365",
    "Dec": "+17.79265307",
    "Distance": "110.619469026549",
    "Mag": 4.72,
    "ColorIndex": 1.01
  },
  {
    "BayerFlamsteed": "  6Ups Her",
    "ProperName": " ",
    "RA": "16.0466253",
    "Dec": "+46.03685378",
    "Distance": "115.207373271889",
    "Mag": 4.72,
    "ColorIndex": -0.094
  },
  {
    "BayerFlamsteed": "   The2Cru",
    "ProperName": " ",
    "RA": "12.07201048",
    "Dec": "-63.16571094",
    "Distance": "230.94688221709",
    "Mag": 4.72,
    "ColorIndex": -0.081
  },
  {
    "BayerFlamsteed": "   Del Pic",
    "ProperName": " ",
    "RA": "6.17164253",
    "Dec": "-54.96866057",
    "Distance": "507.61421319797",
    "Mag": 4.72,
    "ColorIndex": -0.229
  },
  {
    "BayerFlamsteed": " 91Lam Cet",
    "ProperName": " ",
    "RA": "2.99524897",
    "Dec": "+08.90740111",
    "Distance": "130.039011703511",
    "Mag": 4.71,
    "ColorIndex": -0.109
  },
  {
    "BayerFlamsteed": " 89    Aqr",
    "ProperName": " ",
    "RA": "23.16524358",
    "Dec": "-22.45759248",
    "Distance": "159.744408945687",
    "Mag": 4.71,
    "ColorIndex": 0.674
  },
  {
    "BayerFlamsteed": " 59Xi  Aql",
    "ProperName": " ",
    "RA": "19.90411715",
    "Dec": "+08.46165027",
    "Distance": "62.65664160401",
    "Mag": 4.71,
    "ColorIndex": 1.023
  },
  {
    "BayerFlamsteed": " 31    Ori",
    "ProperName": " ",
    "RA": "5.49555043",
    "Dec": "-01.09217557",
    "Distance": "139.86013986014",
    "Mag": 4.71,
    "ColorIndex": 1.592
  },
  {
    "BayerFlamsteed": " 27Zet Crt",
    "ProperName": " ",
    "RA": "11.74604415",
    "Dec": "-18.35061467",
    "Distance": "107.411385606874",
    "Mag": 4.71,
    "ColorIndex": 0.958
  },
  {
    "BayerFlamsteed": " 25Chi Aur",
    "ProperName": " ",
    "RA": "5.54546505",
    "Dec": "+32.19203140",
    "Distance": "1250",
    "Mag": 4.71,
    "ColorIndex": 0.281
  },
  {
    "BayerFlamsteed": "  4Omi1Ori",
    "ProperName": " ",
    "RA": "4.87554547",
    "Dec": "+14.25077828",
    "Distance": "166.112956810631",
    "Mag": 4.71,
    "ColorIndex": 1.773
  },
  {
    "BayerFlamsteed": "   Zet Dor",
    "ProperName": " ",
    "RA": "5.09185843",
    "Dec": "-57.47298928",
    "Distance": "11.650937900501",
    "Mag": 4.71,
    "ColorIndex": 0.526
  },
  {
    "BayerFlamsteed": "   The Pyx",
    "ProperName": " ",
    "RA": "9.35822189",
    "Dec": "-25.96541642",
    "Distance": "160",
    "Mag": 4.71,
    "ColorIndex": 1.633
  },
  {
    "BayerFlamsteed": "   Lam Pyx",
    "ProperName": " ",
    "RA": "9.38676009",
    "Dec": "-28.83392305",
    "Distance": "55.8035714285714",
    "Mag": 4.71,
    "ColorIndex": 0.892
  },
  {
    "BayerFlamsteed": "   Kap Ret",
    "ProperName": " ",
    "RA": "3.48949623",
    "Dec": "-62.93843436",
    "Distance": "21.4362272240086",
    "Mag": 4.71,
    "ColorIndex": 0.41
  },
  {
    "BayerFlamsteed": "   Eps Mic",
    "ProperName": " ",
    "RA": "21.29895737",
    "Dec": "-32.17248551",
    "Distance": "50.6072874493927",
    "Mag": 4.71,
    "ColorIndex": 0.07
  },
  {
    "BayerFlamsteed": "101    Aqr",
    "ProperName": " ",
    "RA": "23.55461808",
    "Dec": "-20.91452506",
    "Distance": "98.2318271119843",
    "Mag": 4.7,
    "ColorIndex": 0.02
  },
  {
    "BayerFlamsteed": " 58Ome Sgr",
    "ProperName": " ",
    "RA": "19.93061806",
    "Dec": "-26.29968718",
    "Distance": "23.7925291458482",
    "Mag": 4.7,
    "ColorIndex": 0.748
  },
  {
    "BayerFlamsteed": " 21The Crt",
    "ProperName": " ",
    "RA": "11.61137458",
    "Dec": "-09.80225368",
    "Distance": "93.4579439252336",
    "Mag": 4.7,
    "ColorIndex": -0.073
  },
  {
    "BayerFlamsteed": "  5Gam Equ",
    "ProperName": " ",
    "RA": "21.17235307",
    "Dec": "+10.13194861",
    "Distance": "35.2360817477097",
    "Mag": 4.7,
    "ColorIndex": 0.262
  },
  {
    "BayerFlamsteed": "   Zet1Sco",
    "ProperName": " ",
    "RA": "16.89992424",
    "Dec": "-42.36201968",
    "Distance": "10000000",
    "Mag": 4.7,
    "ColorIndex": 0.444
  },
  {
    "BayerFlamsteed": "   Omi Hya",
    "ProperName": " ",
    "RA": "11.67022789",
    "Dec": "-34.74465530",
    "Distance": "151.745068285281",
    "Mag": 4.7,
    "ColorIndex": -0.07
  },
  {
    "BayerFlamsteed": "   Del Sct",
    "ProperName": " ",
    "RA": "18.7045617",
    "Dec": "-09.05255357",
    "Distance": "57.3394495412844",
    "Mag": 4.7,
    "ColorIndex": 0.358
  },
  {
    "BayerFlamsteed": " 80Pi 1Cyg",
    "ProperName": " ",
    "RA": "21.70157213",
    "Dec": "+51.18962710",
    "Distance": "515.463917525773",
    "Mag": 4.69,
    "ColorIndex": -0.119
  },
  {
    "BayerFlamsteed": " 73Pi  Tau",
    "ProperName": " ",
    "RA": "4.44343799",
    "Dec": "+14.71385764",
    "Distance": "139.470013947001",
    "Mag": 4.69,
    "ColorIndex": 0.979
  },
  {
    "BayerFlamsteed": " 28    Mon",
    "ProperName": " ",
    "RA": "8.0203601",
    "Dec": "-01.39242418",
    "Distance": "144.927536231884",
    "Mag": 4.69,
    "ColorIndex": 1.475
  },
  {
    "BayerFlamsteed": " 15Lam Aur",
    "ProperName": " ",
    "RA": "5.31891066",
    "Dec": "+40.10066732",
    "Distance": "12.6454223571067",
    "Mag": 4.69,
    "ColorIndex": 0.63
  },
  {
    "BayerFlamsteed": "   Iot Phe",
    "ProperName": " ",
    "RA": "23.58459182",
    "Dec": "-42.61510105",
    "Distance": "83.8926174496644",
    "Mag": 4.69,
    "ColorIndex": 0.078
  },
  {
    "BayerFlamsteed": "   Iot Cru",
    "ProperName": " ",
    "RA": "12.76053228",
    "Dec": "-60.98116057",
    "Distance": "38.2116927779901",
    "Mag": 4.69,
    "ColorIndex": 1.049
  },
  {
    "BayerFlamsteed": "   Eps Ind",
    "ProperName": " ",
    "RA": "22.05484433",
    "Dec": "-56.77980602",
    "Distance": "3.62634174644619",
    "Mag": 4.69,
    "ColorIndex": 1.056
  },
  {
    "BayerFlamsteed": " 74    Vir",
    "ProperName": " ",
    "RA": "13.53276278",
    "Dec": "-06.25571065",
    "Distance": "132.450331125828",
    "Mag": 4.68,
    "ColorIndex": 1.606
  },
  {
    "BayerFlamsteed": " 66    Aqr",
    "ProperName": " ",
    "RA": "22.72645904",
    "Dec": "-18.83030716",
    "Distance": "148.588410104012",
    "Mag": 4.68,
    "ColorIndex": 1.358
  },
  {
    "BayerFlamsteed": " 39Chi Cas",
    "ProperName": " ",
    "RA": "1.56553499",
    "Dec": "+59.23209665",
    "Distance": "62.65664160401",
    "Mag": 4.68,
    "ColorIndex": 0.991
  },
  {
    "BayerFlamsteed": " 37    LMi",
    "ProperName": " ",
    "RA": "10.64533684",
    "Dec": "+31.97622008",
    "Distance": "145.348837209302",
    "Mag": 4.68,
    "ColorIndex": 0.823
  },
  {
    "BayerFlamsteed": " 29Pi  Leo",
    "ProperName": " ",
    "RA": "10.00356231",
    "Dec": "+08.04427686",
    "Distance": "161.030595813205",
    "Mag": 4.68,
    "ColorIndex": 1.589
  },
  {
    "BayerFlamsteed": " 24    CVn",
    "ProperName": " ",
    "RA": "13.5742704",
    "Dec": "+49.01590606",
    "Distance": "58.411214953271",
    "Mag": 4.68,
    "ColorIndex": 0.132
  },
  {
    "BayerFlamsteed": " 23Xi  Aqr",
    "ProperName": " ",
    "RA": "21.62917834",
    "Dec": "-07.85414212",
    "Distance": "54.7645125958379",
    "Mag": 4.68,
    "ColorIndex": 0.175
  },
  {
    "BayerFlamsteed": " 22Pi  Per",
    "ProperName": " ",
    "RA": "2.97934735",
    "Dec": "+39.66282739",
    "Distance": "99.8003992015968",
    "Mag": 4.68,
    "ColorIndex": 0.065
  },
  {
    "BayerFlamsteed": " 12Phi Cyg",
    "ProperName": " ",
    "RA": "19.65627897",
    "Dec": "+30.15323350",
    "Distance": "76.9230769230769",
    "Mag": 4.68,
    "ColorIndex": 0.971
  },
  {
    "BayerFlamsteed": "   Nu  For",
    "ProperName": " ",
    "RA": "2.07484168",
    "Dec": "-29.29683966",
    "Distance": "110.741971207087",
    "Mag": 4.68,
    "ColorIndex": -0.156
  },
  {
    "BayerFlamsteed": "   Eta2Hyi",
    "ProperName": " ",
    "RA": "1.91555967",
    "Dec": "-67.64748114",
    "Distance": "66.4893617021277",
    "Mag": 4.68,
    "ColorIndex": 0.931
  },
  {
    "BayerFlamsteed": "   Del1Aps",
    "ProperName": " ",
    "RA": "16.33912085",
    "Dec": "-78.69565609",
    "Distance": "234.741784037559",
    "Mag": 4.68,
    "ColorIndex": 1.68
  },
  {
    "BayerFlamsteed": " 93    Her",
    "ProperName": " ",
    "RA": "18.00095018",
    "Dec": "+16.75094481",
    "Distance": "201.207243460765",
    "Mag": 4.67,
    "ColorIndex": 1.254
  },
  {
    "BayerFlamsteed": " 92Sig2Tau",
    "ProperName": " ",
    "RA": "4.65456995",
    "Dec": "+15.91802420",
    "Distance": "48.7567040468064",
    "Mag": 4.67,
    "ColorIndex": 0.147
  },
  {
    "BayerFlamsteed": " 85Phi Psc",
    "ProperName": " ",
    "RA": "1.22914867",
    "Dec": "+24.58376482",
    "Distance": "115.740740740741",
    "Mag": 4.67,
    "ColorIndex": 1.047
  },
  {
    "BayerFlamsteed": " 61Sig Dra",
    "ProperName": " ",
    "RA": "19.53905179",
    "Dec": "+69.66540172",
    "Distance": "5.76668012225362",
    "Mag": 4.67,
    "ColorIndex": 0.786
  },
  {
    "BayerFlamsteed": " 52    Per",
    "ProperName": " ",
    "RA": "4.24814171",
    "Dec": "+40.48372394",
    "Distance": "192.307692307692",
    "Mag": 4.67,
    "ColorIndex": 1.007
  },
  {
    "BayerFlamsteed": " 34    Per",
    "ProperName": " ",
    "RA": "3.48945286",
    "Dec": "+49.50901919",
    "Distance": "171.232876712329",
    "Mag": 4.67,
    "ColorIndex": -0.096
  },
  {
    "BayerFlamsteed": " 18The Lep",
    "ProperName": " ",
    "RA": "6.10259278",
    "Dec": "-14.93528629",
    "Distance": "52.2466039707419",
    "Mag": 4.67,
    "ColorIndex": 0.046
  },
  {
    "BayerFlamsteed": " 16Zet2Cnc",
    "ProperName": " ",
    "RA": "8.20353023",
    "Dec": "+17.64813756",
    "Distance": "25.5689082076195",
    "Mag": 4.67,
    "ColorIndex": 0.531
  },
  {
    "BayerFlamsteed": " 14Tau UMa",
    "ProperName": " ",
    "RA": "9.18192532",
    "Dec": "+63.51378087",
    "Distance": "37.3273609555804",
    "Mag": 4.67,
    "ColorIndex": 0.381
  },
  {
    "BayerFlamsteed": "  4Eps1Lyr",
    "ProperName": " ",
    "RA": "18.73898258",
    "Dec": "+39.66997686",
    "Distance": "49.7512437810945",
    "Mag": 4.67,
    "ColorIndex": 0.17
  },
  {
    "BayerFlamsteed": "   Gam Sct",
    "ProperName": " ",
    "RA": "18.48662553",
    "Dec": "-14.56580499",
    "Distance": "89.3655049151028",
    "Mag": 4.67,
    "ColorIndex": 0.076
  },
  {
    "BayerFlamsteed": "   Gam Mic",
    "ProperName": " ",
    "RA": "21.02151713",
    "Dec": "-32.25776681",
    "Distance": "68.5400959561343",
    "Mag": 4.67,
    "ColorIndex": 0.89
  },
  {
    "BayerFlamsteed": " 84Chi Psc",
    "ProperName": " ",
    "RA": "1.19088726",
    "Dec": "+21.03467568",
    "Distance": "134.770889487871",
    "Mag": 4.66,
    "ColorIndex": 1.024
  },
  {
    "BayerFlamsteed": " 53Chi Cet",
    "ProperName": " ",
    "RA": "1.82644197",
    "Dec": "-10.68618074",
    "Distance": "23.6127508854782",
    "Mag": 4.66,
    "ColorIndex": 0.333
  },
  {
    "BayerFlamsteed": " 45Ome UMa",
    "ProperName": " ",
    "RA": "10.89964055",
    "Dec": "+43.19001538",
    "Distance": "81.8330605564648",
    "Mag": 4.66,
    "ColorIndex": -0.039
  },
  {
    "BayerFlamsteed": " 43Gam Cnc",
    "ProperName": " ",
    "RA": "8.72144808",
    "Dec": "+21.46859609",
    "Distance": "48.5908649173955",
    "Mag": 4.66,
    "ColorIndex": 0.01
  },
  {
    "BayerFlamsteed": " 26Chi Vir",
    "ProperName": " ",
    "RA": "12.65411457",
    "Dec": "-07.99550439",
    "Distance": "97.65625",
    "Mag": 4.66,
    "ColorIndex": 1.24
  },
  {
    "BayerFlamsteed": " 19Pi  CMa",
    "ProperName": " ",
    "RA": "6.92705526",
    "Dec": "-20.13659348",
    "Distance": "29.2312189418299",
    "Mag": 4.66,
    "ColorIndex": 0.374
  },
  {
    "BayerFlamsteed": " 15    Vul",
    "ProperName": " ",
    "RA": "20.01833614",
    "Dec": "+27.75356392",
    "Distance": "68.1663258350375",
    "Mag": 4.66,
    "ColorIndex": 0.184
  },
  {
    "BayerFlamsteed": " 15    Mon",
    "ProperName": " ",
    "RA": "6.6829614",
    "Dec": "+09.89576021",
    "Distance": "313.479623824451",
    "Mag": 4.66,
    "ColorIndex": -0.233
  },
  {
    "BayerFlamsteed": "  3Psi1Lup",
    "ProperName": " ",
    "RA": "15.66277079",
    "Dec": "-34.41189273",
    "Distance": "75.0750750750751",
    "Mag": 4.66,
    "ColorIndex": 0.964
  },
  {
    "BayerFlamsteed": "   Zet Sct",
    "ProperName": " ",
    "RA": "18.3943205",
    "Dec": "-08.93451038",
    "Distance": "58.5480093676815",
    "Mag": 4.66,
    "ColorIndex": 0.932
  },
  {
    "BayerFlamsteed": "   Gam1Sgr",
    "ProperName": " ",
    "RA": "18.08367292",
    "Dec": "-29.58007620",
    "Distance": "636.942675159236",
    "Mag": 4.66,
    "ColorIndex": 0.774
  },
  {
    "BayerFlamsteed": " 86Rho Tau",
    "ProperName": " ",
    "RA": "4.56412643",
    "Dec": "+14.84448749",
    "Distance": "46.7508181393174",
    "Mag": 4.65,
    "ColorIndex": 0.255
  },
  {
    "BayerFlamsteed": " 35    Ari",
    "ProperName": " ",
    "RA": "2.72419735",
    "Dec": "+27.70717078",
    "Distance": "113.378684807256",
    "Mag": 4.65,
    "ColorIndex": -0.122
  },
  {
    "BayerFlamsteed": " 11    Ori",
    "ProperName": " ",
    "RA": "5.07614954",
    "Dec": "+15.40418059",
    "Distance": "122.699386503067",
    "Mag": 4.65,
    "ColorIndex": -0.064
  },
  {
    "BayerFlamsteed": "  8Pi  Vir",
    "ProperName": " ",
    "RA": "12.01455285",
    "Dec": "+06.61439464",
    "Distance": "109.170305676856",
    "Mag": 4.65,
    "ColorIndex": 0.122
  },
  {
    "BayerFlamsteed": "   Eta Nor",
    "ProperName": " ",
    "RA": "16.05357227",
    "Dec": "-49.22972074",
    "Distance": "66.8002672010688",
    "Mag": 4.65,
    "ColorIndex": 0.902
  },
  {
    "BayerFlamsteed": " 71    Oph",
    "ProperName": " ",
    "RA": "18.12176509",
    "Dec": "+08.73379361",
    "Distance": "72.9394602479942",
    "Mag": 4.64,
    "ColorIndex": 0.951
  },
  {
    "BayerFlamsteed": " 69    Her",
    "ProperName": " ",
    "RA": "17.29452385",
    "Dec": "+37.29134328",
    "Distance": "54.525627044711",
    "Mag": 4.64,
    "ColorIndex": 0.043
  },
  {
    "BayerFlamsteed": " 62Chi2Ori",
    "ProperName": " ",
    "RA": "6.06532876",
    "Dec": "+20.13845865",
    "Distance": "10000",
    "Mag": 4.64,
    "ColorIndex": 0.236
  },
  {
    "BayerFlamsteed": " 33Tau8Eri",
    "ProperName": " ",
    "RA": "3.89518971",
    "Dec": "-24.61221253",
    "Distance": "118.203309692671",
    "Mag": 4.64,
    "ColorIndex": -0.136
  },
  {
    "BayerFlamsteed": " 32Nu  Aql",
    "ProperName": " ",
    "RA": "19.44196935",
    "Dec": "+00.33857566",
    "Distance": "3571.42857142857",
    "Mag": 4.64,
    "ColorIndex": 0.576
  },
  {
    "BayerFlamsteed": " 20    Oph",
    "ProperName": " ",
    "RA": "16.83054809",
    "Dec": "-10.78280069",
    "Distance": "36.9822485207101",
    "Mag": 4.64,
    "ColorIndex": 0.478
  },
  {
    "BayerFlamsteed": "  9    Lac",
    "ProperName": " ",
    "RA": "22.62290716",
    "Dec": "+51.54537399",
    "Distance": "52.7704485488127",
    "Mag": 4.64,
    "ColorIndex": 0.254
  },
  {
    "BayerFlamsteed": "  7Zet1CrB",
    "ProperName": " ",
    "RA": "15.65630105",
    "Dec": "+36.63582763",
    "Distance": "145.137880986938",
    "Mag": 4.64,
    "ColorIndex": -0.103
  },
  {
    "BayerFlamsteed": "  7Pi 1Ori",
    "ProperName": " ",
    "RA": "4.91491781",
    "Dec": "+10.15114511",
    "Distance": "36.9822485207101",
    "Mag": 4.64,
    "ColorIndex": 0.085
  },
  {
    "BayerFlamsteed": "  4Zet Del",
    "ProperName": " ",
    "RA": "20.58847449",
    "Dec": "+14.67418482",
    "Distance": "69.6378830083566",
    "Mag": 4.64,
    "ColorIndex": 0.12
  },
  {
    "BayerFlamsteed": "  3    And",
    "ProperName": " ",
    "RA": "23.06967548",
    "Dec": "+50.05168397",
    "Distance": "54.9450549450549",
    "Mag": 4.64,
    "ColorIndex": 1.058
  },
  {
    "BayerFlamsteed": " 84Psi Peg",
    "ProperName": " ",
    "RA": "23.96265266",
    "Dec": "+25.14147936",
    "Distance": "132.62599469496",
    "Mag": 4.63,
    "ColorIndex": 1.584
  },
  {
    "BayerFlamsteed": " 83    UMa",
    "ProperName": " ",
    "RA": "13.67897014",
    "Dec": "+54.68166149",
    "Distance": "168.350168350168",
    "Mag": 4.63,
    "ColorIndex": 1.63
  },
  {
    "BayerFlamsteed": " 48Eps Ari",
    "ProperName": " ",
    "RA": "2.98687051",
    "Dec": "+21.34044477",
    "Distance": "89.6860986547085",
    "Mag": 4.63,
    "ColorIndex": 0.048
  },
  {
    "BayerFlamsteed": " 47Omi Dra",
    "ProperName": " ",
    "RA": "18.85333516",
    "Dec": "+59.38828895",
    "Distance": "98.8142292490119",
    "Mag": 4.63,
    "ColorIndex": 1.185
  },
  {
    "BayerFlamsteed": "  1    Sco",
    "ProperName": " ",
    "RA": "15.84965378",
    "Dec": "-25.75123489",
    "Distance": "160",
    "Mag": 4.63,
    "ColorIndex": -0.072
  },
  {
    "BayerFlamsteed": "  1    Pup",
    "ProperName": " ",
    "RA": "7.72566545",
    "Dec": "-28.41095714",
    "Distance": "299.40119760479",
    "Mag": 4.63,
    "ColorIndex": 1.632
  },
  {
    "BayerFlamsteed": "   Nu  Pav",
    "ProperName": " ",
    "RA": "18.52289626",
    "Dec": "-62.27819116",
    "Distance": "146.842878120411",
    "Mag": 4.63,
    "ColorIndex": -0.116
  },
  {
    "BayerFlamsteed": "   Iot1Nor",
    "ProperName": " ",
    "RA": "16.05895029",
    "Dec": "-57.77487351",
    "Distance": "42.9922613929493",
    "Mag": 4.63,
    "ColorIndex": 0.252
  },
  {
    "BayerFlamsteed": "102Iot Tau",
    "ProperName": " ",
    "RA": "5.05158446",
    "Dec": "+21.59006170",
    "Distance": "49.9750124937531",
    "Mag": 4.62,
    "ColorIndex": 0.155
  },
  {
    "BayerFlamsteed": " 63The1Ser",
    "ProperName": " ",
    "RA": "18.93698898",
    "Dec": "+04.20352956",
    "Distance": "40.4367165386171",
    "Mag": 4.62,
    "ColorIndex": 0.161
  },
  {
    "BayerFlamsteed": " 63Chi Leo",
    "ProperName": " ",
    "RA": "11.08367497",
    "Dec": "+07.33612254",
    "Distance": "28.9519397799653",
    "Mag": 4.62,
    "ColorIndex": 0.332
  },
  {
    "BayerFlamsteed": " 57Zet Ser",
    "ProperName": " ",
    "RA": "18.00803345",
    "Dec": "-03.69016112",
    "Distance": "23.1964741359313",
    "Mag": 4.62,
    "ColorIndex": 0.39
  },
  {
    "BayerFlamsteed": " 36Ups Ori",
    "ProperName": " ",
    "RA": "5.53218341",
    "Dec": "-07.30152625",
    "Distance": "473.9336492891",
    "Mag": 4.62,
    "ColorIndex": -0.261
  },
  {
    "BayerFlamsteed": " 36Tau9Eri",
    "ProperName": " ",
    "RA": "3.99874336",
    "Dec": "-24.01625677",
    "Distance": "101.522842639594",
    "Mag": 4.62,
    "ColorIndex": -0.121
  },
  {
    "BayerFlamsteed": " 28Ups2Cas",
    "ProperName": " ",
    "RA": "0.94444736",
    "Dec": "+59.18116582",
    "Distance": "63.1313131313131",
    "Mag": 4.62,
    "ColorIndex": 0.957
  },
  {
    "BayerFlamsteed": "  3Ups Oph",
    "ProperName": " ",
    "RA": "16.46339637",
    "Dec": "-08.37170055",
    "Distance": "37.4953130858643",
    "Mag": 4.62,
    "ColorIndex": 0.185
  },
  {
    "BayerFlamsteed": "   The CrA",
    "ProperName": " ",
    "RA": "18.5583778",
    "Dec": "-42.31245871",
    "Distance": "265.957446808511",
    "Mag": 4.62,
    "ColorIndex": 0.994
  },
  {
    "BayerFlamsteed": "   Lam Cru",
    "ProperName": " ",
    "RA": "12.91089445",
    "Dec": "-59.14666538",
    "Distance": "110.37527593819",
    "Mag": 4.62,
    "ColorIndex": -0.153
  },
  {
    "BayerFlamsteed": "   Kap Pyx",
    "ProperName": " ",
    "RA": "9.13412701",
    "Dec": "-25.85853691",
    "Distance": "149.476831091181",
    "Mag": 4.62,
    "ColorIndex": 1.594
  },
  {
    "BayerFlamsteed": "111Xi  Psc",
    "ProperName": " ",
    "RA": "1.89259348",
    "Dec": "+03.18747844",
    "Distance": "58.4453535943892",
    "Mag": 4.61,
    "ColorIndex": 0.928
  },
  {
    "BayerFlamsteed": " 47    Cyg",
    "ProperName": " ",
    "RA": "20.56505228",
    "Dec": "+35.25086482",
    "Distance": "284.900284900285",
    "Mag": 4.61,
    "ColorIndex": 1.593
  },
  {
    "BayerFlamsteed": " 37    Lib",
    "ProperName": " ",
    "RA": "15.56958881",
    "Dec": "-10.06395962",
    "Distance": "28.9519397799653",
    "Mag": 4.61,
    "ColorIndex": 1
  },
  {
    "BayerFlamsteed": " 33    Psc",
    "ProperName": " ",
    "RA": "0.08892938",
    "Dec": "-05.70783255",
    "Distance": "39.4011032308905",
    "Mag": 4.61,
    "ColorIndex": 1.029
  },
  {
    "BayerFlamsteed": " 28Ome Per",
    "ProperName": " ",
    "RA": "3.18816707",
    "Dec": "+39.61157075",
    "Distance": "93.5453695042095",
    "Mag": 4.61,
    "ColorIndex": 1.115
  },
  {
    "BayerFlamsteed": " 24The And",
    "ProperName": " ",
    "RA": "0.28487101",
    "Dec": "+38.68167915",
    "Distance": "77.639751552795",
    "Mag": 4.61,
    "ColorIndex": 0.059
  },
  {
    "BayerFlamsteed": " 21    Lyn",
    "ProperName": " ",
    "RA": "7.44523915",
    "Dec": "+49.21164489",
    "Distance": "76.27765064836",
    "Mag": 4.61,
    "ColorIndex": -0.001
  },
  {
    "BayerFlamsteed": " 58Pi  Dra",
    "ProperName": " ",
    "RA": "19.34446443",
    "Dec": "+65.71443030",
    "Distance": "68.870523415978",
    "Mag": 4.6,
    "ColorIndex": 0.033
  },
  {
    "BayerFlamsteed": " 40Ups2Hya",
    "ProperName": " ",
    "RA": "10.08541457",
    "Dec": "-13.06467435",
    "Distance": "84.9617672047579",
    "Mag": 4.6,
    "ColorIndex": -0.087
  },
  {
    "BayerFlamsteed": " 35Omi Boo",
    "ProperName": " ",
    "RA": "14.754027",
    "Dec": "+16.96440296",
    "Distance": "69.060773480663",
    "Mag": 4.6,
    "ColorIndex": 0.972
  },
  {
    "BayerFlamsteed": "  1Chi Her",
    "ProperName": " ",
    "RA": "15.87783178",
    "Dec": "+42.44998796",
    "Distance": "15.852885225111",
    "Mag": 4.6,
    "ColorIndex": 0.563
  },
  {
    "BayerFlamsteed": "   Iot Ant",
    "ProperName": " ",
    "RA": "10.94527675",
    "Dec": "-37.13746290",
    "Distance": "60.9756097560976",
    "Mag": 4.6,
    "ColorIndex": 1.006
  },
  {
    "BayerFlamsteed": " 52    Sgr",
    "ProperName": " ",
    "RA": "19.61177473",
    "Dec": "-24.88356664",
    "Distance": "58.0046403712297",
    "Mag": 4.59,
    "ColorIndex": -0.075
  },
  {
    "BayerFlamsteed": " 31Tau1Hya",
    "ProperName": " ",
    "RA": "9.48578854",
    "Dec": "-02.76895627",
    "Distance": "17.0998632010944",
    "Mag": 4.59,
    "ColorIndex": 0.411
  },
  {
    "BayerFlamsteed": " 30Psi2Ori",
    "ProperName": " ",
    "RA": "5.4472856",
    "Dec": "+03.09567718",
    "Distance": "434.782608695652",
    "Mag": 4.59,
    "ColorIndex": -0.199
  },
  {
    "BayerFlamsteed": " 10Del CrB",
    "ProperName": " ",
    "RA": "15.82658278",
    "Dec": "+26.06854936",
    "Distance": "50.7356671740233",
    "Mag": 4.59,
    "ColorIndex": 0.794
  },
  {
    "BayerFlamsteed": "  5Eps2Lyr",
    "ProperName": " ",
    "RA": "18.73965995",
    "Dec": "+39.61259557",
    "Distance": "49.1642084562439",
    "Mag": 4.59,
    "ColorIndex": 0.18
  },
  {
    "BayerFlamsteed": "  4Pi 2UMa",
    "ProperName": " ",
    "RA": "8.67024961",
    "Dec": "+64.32787175",
    "Distance": "77.3993808049536",
    "Mag": 4.59,
    "ColorIndex": 1.179
  },
  {
    "BayerFlamsteed": "  2    Sco",
    "ProperName": " ",
    "RA": "15.89353566",
    "Dec": "-25.32708018",
    "Distance": "132.978723404255",
    "Mag": 4.59,
    "ColorIndex": -0.073
  },
  {
    "BayerFlamsteed": "   Mu  Phe",
    "ProperName": " ",
    "RA": "0.68877111",
    "Dec": "-46.08500934",
    "Distance": "75.8150113722517",
    "Mag": 4.59,
    "ColorIndex": 0.953
  },
  {
    "BayerFlamsteed": "   Gam Cam",
    "ProperName": " ",
    "RA": "3.83929883",
    "Dec": "+71.33236777",
    "Distance": "102.774922918808",
    "Mag": 4.59,
    "ColorIndex": 0.064
  },
  {
    "BayerFlamsteed": "   Del Scl",
    "ProperName": " ",
    "RA": "23.81541108",
    "Dec": "-28.13001480",
    "Distance": "43.994720633524",
    "Mag": 4.59,
    "ColorIndex": 0.001
  },
  {
    "BayerFlamsteed": " 62Tau Peg",
    "ProperName": " ",
    "RA": "23.34395088",
    "Dec": "+23.74035920",
    "Distance": "51.2820512820513",
    "Mag": 4.58,
    "ColorIndex": 0.18
  },
  {
    "BayerFlamsteed": " 57Mu  Oph",
    "ProperName": " ",
    "RA": "17.63075549",
    "Dec": "-08.11872126",
    "Distance": "168.350168350168",
    "Mag": 4.58,
    "ColorIndex": 0.132
  },
  {
    "BayerFlamsteed": " 42    Ori",
    "ProperName": " ",
    "RA": "5.58976712",
    "Dec": "-04.83834045",
    "Distance": "240.963855421687",
    "Mag": 4.58,
    "ColorIndex": -0.183
  },
  {
    "BayerFlamsteed": " 13    Sco",
    "ProperName": " ",
    "RA": "16.20505881",
    "Dec": "-27.92631564",
    "Distance": "143.472022955524",
    "Mag": 4.58,
    "ColorIndex": -0.172
  },
  {
    "BayerFlamsteed": " 10    Dra",
    "ProperName": " ",
    "RA": "13.85720488",
    "Dec": "+64.72328281",
    "Distance": "120.048019207683",
    "Mag": 4.58,
    "ColorIndex": 1.572
  },
  {
    "BayerFlamsteed": "113    Her",
    "ProperName": " ",
    "RA": "18.91246698",
    "Dec": "+22.64507357",
    "Distance": "149.03129657228",
    "Mag": 4.57,
    "ColorIndex": 0.782
  },
  {
    "BayerFlamsteed": " 31Psi1Dra",
    "ProperName": " ",
    "RA": "17.69897418",
    "Dec": "+72.14949916",
    "Distance": "22.0361392684002",
    "Mag": 4.57,
    "ColorIndex": 0.434
  },
  {
    "BayerFlamsteed": " 24Ome Her",
    "ProperName": " ",
    "RA": "16.42359154",
    "Dec": "+14.03341514",
    "Distance": "72.0980533525595",
    "Mag": 4.57,
    "ColorIndex": 0.002
  },
  {
    "BayerFlamsteed": " 13    Vul",
    "ProperName": " ",
    "RA": "19.89102245",
    "Dec": "+24.07952568",
    "Distance": "110.132158590308",
    "Mag": 4.57,
    "ColorIndex": -0.047
  },
  {
    "BayerFlamsteed": "  5Rho Oph",
    "ProperName": " ",
    "RA": "16.42642254",
    "Dec": "-23.44711764",
    "Distance": "120.918984280532",
    "Mag": 4.57,
    "ColorIndex": 0.227
  },
  {
    "BayerFlamsteed": "   Del CrA",
    "ProperName": " ",
    "RA": "19.13914805",
    "Dec": "-40.49663760",
    "Distance": "53.5618639528656",
    "Mag": 4.57,
    "ColorIndex": 1.07
  },
  {
    "BayerFlamsteed": "136    Tau",
    "ProperName": " ",
    "RA": "5.88878987",
    "Dec": "+27.61228536",
    "Distance": "134.228187919463",
    "Mag": 4.56,
    "ColorIndex": -0.008
  },
  {
    "BayerFlamsteed": " 72    Leo",
    "ProperName": " ",
    "RA": "11.25340086",
    "Dec": "+23.09552528",
    "Distance": "2040.81632653061",
    "Mag": 4.56,
    "ColorIndex": 1.657
  },
  {
    "BayerFlamsteed": " 63    Cyg",
    "ProperName": " ",
    "RA": "21.11002448",
    "Dec": "+47.64840597",
    "Distance": "301.204819277108",
    "Mag": 4.56,
    "ColorIndex": 1.569
  },
  {
    "BayerFlamsteed": " 31    Vul",
    "ProperName": " ",
    "RA": "20.86881265",
    "Dec": "+27.09712771",
    "Distance": "66.4010624169987",
    "Mag": 4.56,
    "ColorIndex": 0.835
  },
  {
    "BayerFlamsteed": " 17    Per",
    "ProperName": " ",
    "RA": "2.85856414",
    "Dec": "+35.05989415",
    "Distance": "125.470514429109",
    "Mag": 4.56,
    "ColorIndex": 1.554
  },
  {
    "BayerFlamsteed": "  1Sig Ara",
    "ProperName": " ",
    "RA": "17.59433647",
    "Dec": "-46.50559076",
    "Distance": "118.203309692671",
    "Mag": 4.56,
    "ColorIndex": -0.02
  },
  {
    "BayerFlamsteed": "   Tau1Lup",
    "ProperName": " ",
    "RA": "14.43562084",
    "Dec": "-45.22138996",
    "Distance": "317.460317460317",
    "Mag": 4.56,
    "ColorIndex": -0.147
  },
  {
    "BayerFlamsteed": "   Del Ret",
    "ProperName": " ",
    "RA": "3.97909374",
    "Dec": "-61.40015059",
    "Distance": "162.60162601626",
    "Mag": 4.56,
    "ColorIndex": 1.59
  },
  {
    "BayerFlamsteed": " 30Phi UMa",
    "ProperName": " ",
    "RA": "9.86843337",
    "Dec": "+54.06428574",
    "Distance": "133.868808567604",
    "Mag": 4.55,
    "ColorIndex": 0.038
  },
  {
    "BayerFlamsteed": " 19Omi Sco",
    "ProperName": " ",
    "RA": "16.34393983",
    "Dec": "-24.16928427",
    "Distance": "361.01083032491",
    "Mag": 4.55,
    "ColorIndex": 0.758
  },
  {
    "BayerFlamsteed": " 11    Cep",
    "ProperName": " ",
    "RA": "21.69863224",
    "Dec": "+71.31118695",
    "Distance": "53.9083557951482",
    "Mag": 4.55,
    "ColorIndex": 1.108
  },
  {
    "BayerFlamsteed": "  6    CMi",
    "ProperName": " ",
    "RA": "7.49660613",
    "Dec": "+12.00661101",
    "Distance": "171.821305841924",
    "Mag": 4.55,
    "ColorIndex": 1.276
  },
  {
    "BayerFlamsteed": "  4    Lac",
    "ProperName": " ",
    "RA": "22.40860992",
    "Dec": "+49.47640074",
    "Distance": "649.350649350649",
    "Mag": 4.55,
    "ColorIndex": 0.092
  },
  {
    "BayerFlamsteed": "  2    Lac",
    "ProperName": " ",
    "RA": "22.35042452",
    "Dec": "+46.53656484",
    "Distance": "156.25",
    "Mag": 4.55,
    "ColorIndex": -0.1
  },
  {
    "BayerFlamsteed": "  2    Cet",
    "ProperName": " ",
    "RA": "0.06232565",
    "Dec": "-17.33597002",
    "Distance": "69.8812019566736",
    "Mag": 4.55,
    "ColorIndex": -0.047
  },
  {
    "BayerFlamsteed": "   Gam1Cae",
    "ProperName": " ",
    "RA": "5.07341993",
    "Dec": "-35.48287057",
    "Distance": "56.7214974475326",
    "Mag": 4.55,
    "ColorIndex": 1.177
  },
  {
    "BayerFlamsteed": " 70    Peg",
    "ProperName": " ",
    "RA": "23.48590563",
    "Dec": "+12.76049243",
    "Distance": "54.525627044711",
    "Mag": 4.54,
    "ColorIndex": 0.939
  },
  {
    "BayerFlamsteed": " 59    Sgr",
    "ProperName": " ",
    "RA": "19.94911802",
    "Dec": "-27.16986250",
    "Distance": "370.37037037037",
    "Mag": 4.54,
    "ColorIndex": 1.462
  },
  {
    "BayerFlamsteed": " 55    Peg",
    "ProperName": " ",
    "RA": "23.11673773",
    "Dec": "+09.40952259",
    "Distance": "98.7166831194472",
    "Mag": 4.54,
    "ColorIndex": 1.559
  },
  {
    "BayerFlamsteed": " 37Xi  Boo",
    "ProperName": " ",
    "RA": "14.85646783",
    "Dec": "+19.10063329",
    "Distance": "6.6997186118183",
    "Mag": 4.54,
    "ColorIndex": 0.72
  },
  {
    "BayerFlamsteed": " 32Tau2Hya",
    "ProperName": " ",
    "RA": "9.53303743",
    "Dec": "-01.18465438",
    "Distance": "140.646976090014",
    "Mag": 4.54,
    "ColorIndex": 0.109
  },
  {
    "BayerFlamsteed": " 24Iot1Lib",
    "ProperName": " ",
    "RA": "15.20369782",
    "Dec": "-19.79163143",
    "Distance": "115.473441108545",
    "Mag": 4.54,
    "ColorIndex": -0.071
  },
  {
    "BayerFlamsteed": " 24    UMa",
    "ProperName": " ",
    "RA": "9.57471329",
    "Dec": "+69.83015419",
    "Distance": "32.3729362253156",
    "Mag": 4.54,
    "ColorIndex": 0.781
  },
  {
    "BayerFlamsteed": " 16    Aur",
    "ProperName": " ",
    "RA": "5.30293003",
    "Dec": "+33.37200411",
    "Distance": "71.8390804597701",
    "Mag": 4.54,
    "ColorIndex": 1.252
  },
  {
    "BayerFlamsteed": " 10    LMi",
    "ProperName": " ",
    "RA": "9.57038249",
    "Dec": "+36.39761338",
    "Distance": "53.9956803455724",
    "Mag": 4.54,
    "ColorIndex": 0.914
  },
  {
    "BayerFlamsteed": "  5Xi 2CMa",
    "ProperName": " ",
    "RA": "6.5842722",
    "Dec": "-22.96483318",
    "Distance": "126.262626262626",
    "Mag": 4.54,
    "ColorIndex": -0.035
  },
  {
    "BayerFlamsteed": "   Phi2Lup",
    "ProperName": " ",
    "RA": "15.38593424",
    "Dec": "-36.85843627",
    "Distance": "185.873605947955",
    "Mag": 4.54,
    "ColorIndex": -0.155
  },
  {
    "BayerFlamsteed": " 54Lam Cyg",
    "ProperName": " ",
    "RA": "20.79014637",
    "Dec": "+36.49073658",
    "Distance": "269.541778975741",
    "Mag": 4.53,
    "ColorIndex": -0.083
  },
  {
    "BayerFlamsteed": " 35Nu  And",
    "ProperName": " ",
    "RA": "0.83023048",
    "Dec": "+41.07895474",
    "Distance": "208.333333333333",
    "Mag": 4.53,
    "ColorIndex": -0.136
  },
  {
    "BayerFlamsteed": " 17Kap2Boo",
    "ProperName": " ",
    "RA": "14.2247083",
    "Dec": "+51.78999066",
    "Distance": "47.5511174512601",
    "Mag": 4.53,
    "ColorIndex": 0.233
  },
  {
    "BayerFlamsteed": "  7    And",
    "ProperName": " ",
    "RA": "23.20914525",
    "Dec": "+49.40597489",
    "Distance": "24.4977951984321",
    "Mag": 4.53,
    "ColorIndex": 0.302
  },
  {
    "BayerFlamsteed": "  3    Sgr",
    "ProperName": " ",
    "RA": "17.79267418",
    "Dec": "-27.83076255",
    "Distance": "330.03300330033",
    "Mag": 4.53,
    "ColorIndex": 0.6
  },
  {
    "BayerFlamsteed": "   Bet2Tuc",
    "ProperName": " ",
    "RA": "0.525932",
    "Dec": "-62.96544985",
    "Distance": "52.7704485488127",
    "Mag": 4.53,
    "ColorIndex": 0.147
  },
  {
    "BayerFlamsteed": "100Lam Vir",
    "ProperName": " ",
    "RA": "14.31850051",
    "Dec": "-13.37116634",
    "Distance": "57.2409845449342",
    "Mag": 4.52,
    "ColorIndex": 0.128
  },
  {
    "BayerFlamsteed": " 46Ups Sgr",
    "ProperName": " ",
    "RA": "19.36211722",
    "Dec": "-15.95500233",
    "Distance": "512.820512820513",
    "Mag": 4.52,
    "ColorIndex": 0.079
  },
  {
    "BayerFlamsteed": " 43Psi Boo",
    "ProperName": " ",
    "RA": "15.07412697",
    "Dec": "+26.94765981",
    "Distance": "76.6871165644172",
    "Mag": 4.52,
    "ColorIndex": 1.24
  },
  {
    "BayerFlamsteed": " 39    Ari",
    "ProperName": " ",
    "RA": "2.79845596",
    "Dec": "+29.24742385",
    "Distance": "55.3709856035437",
    "Mag": 4.52,
    "ColorIndex": 1.112
  },
  {
    "BayerFlamsteed": "  6    Lac",
    "ProperName": " ",
    "RA": "22.50812822",
    "Dec": "+43.12338985",
    "Distance": "420.168067226891",
    "Mag": 4.52,
    "ColorIndex": -0.086
  },
  {
    "BayerFlamsteed": "  2    Peg",
    "ProperName": " ",
    "RA": "21.49913323",
    "Dec": "+23.63882922",
    "Distance": "135.685210312076",
    "Mag": 4.52,
    "ColorIndex": 1.618
  },
  {
    "BayerFlamsteed": "   Eps Tel",
    "ProperName": " ",
    "RA": "18.18716006",
    "Dec": "-45.95432704",
    "Distance": "125.31328320802",
    "Mag": 4.52,
    "ColorIndex": 1.009
  },
  {
    "BayerFlamsteed": " 83Tau Psc",
    "ProperName": " ",
    "RA": "1.19432964",
    "Dec": "+30.08972962",
    "Distance": "49.7265042267529",
    "Mag": 4.51,
    "ColorIndex": 1.092
  },
  {
    "BayerFlamsteed": " 67Rho Dra",
    "ProperName": " ",
    "RA": "20.04695756",
    "Dec": "+67.87344872",
    "Distance": "124.688279301746",
    "Mag": 4.51,
    "ColorIndex": 1.313
  },
  {
    "BayerFlamsteed": " 39Eps Cap",
    "ProperName": " ",
    "RA": "21.61800632",
    "Dec": "-19.46601352",
    "Distance": "203.252032520325",
    "Mag": 4.51,
    "ColorIndex": -0.18
  },
  {
    "BayerFlamsteed": " 29Tau Aur",
    "ProperName": " ",
    "RA": "5.81957208",
    "Dec": "+39.18113322",
    "Distance": "65.1890482398957",
    "Mag": 4.51,
    "ColorIndex": 0.949
  },
  {
    "BayerFlamsteed": " 25Sig And",
    "ProperName": " ",
    "RA": "0.3054737",
    "Dec": "+36.78532730",
    "Distance": "43.271311120727",
    "Mag": 4.51,
    "ColorIndex": 0.054
  },
  {
    "BayerFlamsteed": " 21Iot Ser",
    "ProperName": " ",
    "RA": "15.69252564",
    "Dec": "+19.67050566",
    "Distance": "58.9275191514437",
    "Mag": 4.51,
    "ColorIndex": 0.062
  },
  {
    "BayerFlamsteed": "  7Rho Cas",
    "ProperName": " ",
    "RA": "23.90639925",
    "Dec": "+57.49939052",
    "Distance": "3571.42857142857",
    "Mag": 4.51,
    "ColorIndex": 1.19
  },
  {
    "BayerFlamsteed": "   Eta Ind",
    "ProperName": " ",
    "RA": "20.73394074",
    "Dec": "-51.92084051",
    "Distance": "24.1662638956017",
    "Mag": 4.51,
    "ColorIndex": 0.278
  },
  {
    "BayerFlamsteed": "   Eps Ant",
    "ProperName": " ",
    "RA": "9.48742707",
    "Dec": "-35.95134780",
    "Distance": "214.592274678112",
    "Mag": 4.51,
    "ColorIndex": 1.408
  },
  {
    "BayerFlamsteed": "   Del Tuc",
    "ProperName": " ",
    "RA": "22.4555203",
    "Dec": "-64.96637927",
    "Distance": "81.9000819000819",
    "Mag": 4.51,
    "ColorIndex": -0.029
  },
  {
    "BayerFlamsteed": " 93    Leo",
    "ProperName": " ",
    "RA": "11.7997851",
    "Dec": "+20.21894121",
    "Distance": "69.4444444444444",
    "Mag": 4.5,
    "ColorIndex": 0.547
  },
  {
    "BayerFlamsteed": " 47Ome Ori",
    "ProperName": " ",
    "RA": "5.65309608",
    "Dec": "+04.12146667",
    "Distance": "497.512437810945",
    "Mag": 4.5,
    "ColorIndex": -0.098
  },
  {
    "BayerFlamsteed": " 36    Cap",
    "ProperName": " ",
    "RA": "21.47869889",
    "Dec": "-21.80716659",
    "Distance": "55.005500550055",
    "Mag": 4.5,
    "ColorIndex": 0.889
  },
  {
    "BayerFlamsteed": " 23    Vul",
    "ProperName": " ",
    "RA": "20.2628215",
    "Dec": "+27.81422850",
    "Distance": "100.603621730382",
    "Mag": 4.5,
    "ColorIndex": 1.258
  },
  {
    "BayerFlamsteed": " 14Mu  PsA",
    "ProperName": " ",
    "RA": "22.13970922",
    "Dec": "-32.98839827",
    "Distance": "39.984006397441",
    "Mag": 4.5,
    "ColorIndex": 0.054
  },
  {
    "BayerFlamsteed": " 13Nu  Aqr",
    "ProperName": " ",
    "RA": "21.15988689",
    "Dec": "-11.37165474",
    "Distance": "50.1756146512795",
    "Mag": 4.5,
    "ColorIndex": 0.926
  },
  {
    "BayerFlamsteed": " 11    Lac",
    "ProperName": " ",
    "RA": "22.67521732",
    "Dec": "+44.27628068",
    "Distance": "92.5069380203515",
    "Mag": 4.5,
    "ColorIndex": 1.318
  },
  {
    "BayerFlamsteed": "  4Tau Boo",
    "ProperName": " ",
    "RA": "13.78778795",
    "Dec": "+17.45677436",
    "Distance": "15.5957579538366",
    "Mag": 4.5,
    "ColorIndex": 0.508
  },
  {
    "BayerFlamsteed": "   Gam Pic",
    "ProperName": " ",
    "RA": "5.83043799",
    "Dec": "-56.16648860",
    "Distance": "53.248136315229",
    "Mag": 4.5,
    "ColorIndex": 1.075
  },
  {
    "BayerFlamsteed": "105Ome2Aqr",
    "ProperName": " ",
    "RA": "23.71202357",
    "Dec": "-14.54474243",
    "Distance": "47.2589792060491",
    "Mag": 4.49,
    "ColorIndex": -0.032
  },
  {
    "BayerFlamsteed": " 78Mu 1Cyg",
    "ProperName": " ",
    "RA": "21.73566791",
    "Dec": "+28.74322228",
    "Distance": "22.4014336917563",
    "Mag": 4.49,
    "ColorIndex": 0.512
  },
  {
    "BayerFlamsteed": " 50Ups1Eri",
    "ProperName": " ",
    "RA": "4.55850819",
    "Dec": "-29.76583186",
    "Distance": "38.13882532418",
    "Mag": 4.49,
    "ColorIndex": 0.972
  },
  {
    "BayerFlamsteed": " 48    Cas",
    "ProperName": " ",
    "RA": "2.03265271",
    "Dec": "+70.90704605",
    "Distance": "35.8294518093873",
    "Mag": 4.49,
    "ColorIndex": 0.164
  },
  {
    "BayerFlamsteed": " 30    Gem",
    "ProperName": " ",
    "RA": "6.73313723",
    "Dec": "+13.22814319",
    "Distance": "84.6023688663282",
    "Mag": 4.49,
    "ColorIndex": 1.167
  },
  {
    "BayerFlamsteed": " 29Pi 1Boo",
    "ProperName": " ",
    "RA": "14.67876693",
    "Dec": "+16.41830130",
    "Distance": "97.2762645914397",
    "Mag": 4.49,
    "ColorIndex": -0.002
  },
  {
    "BayerFlamsteed": " 24    Cap",
    "ProperName": " ",
    "RA": "21.11880151",
    "Dec": "-25.00574796",
    "Distance": "160.25641025641",
    "Mag": 4.49,
    "ColorIndex": 1.604
  },
  {
    "BayerFlamsteed": " 21    LMi",
    "ProperName": " ",
    "RA": "10.12381254",
    "Dec": "+35.24469176",
    "Distance": "27.9485746226942",
    "Mag": 4.49,
    "ColorIndex": 0.19
  },
  {
    "BayerFlamsteed": " 18Lam Psc",
    "ProperName": " ",
    "RA": "23.70080049",
    "Dec": "+01.78041720",
    "Distance": "30.8832612723904",
    "Mag": 4.49,
    "ColorIndex": 0.2
  },
  {
    "BayerFlamsteed": " 13The Cyg",
    "ProperName": " ",
    "RA": "19.60737289",
    "Dec": "+50.22046347",
    "Distance": "18.5942729639271",
    "Mag": 4.49,
    "ColorIndex": 0.395
  },
  {
    "BayerFlamsteed": "   Eps Tuc",
    "ProperName": " ",
    "RA": "23.99858613",
    "Dec": "-65.57707774",
    "Distance": "114.810562571757",
    "Mag": 4.49,
    "ColorIndex": -0.075
  },
  {
    "BayerFlamsteed": " 86    Aqr",
    "ProperName": " ",
    "RA": "23.11133535",
    "Dec": "-23.74311680",
    "Distance": "57.7367205542725",
    "Mag": 4.48,
    "ColorIndex": 0.892
  },
  {
    "BayerFlamsteed": " 71    Tau",
    "ProperName": " ",
    "RA": "4.43907547",
    "Dec": "+15.61834620",
    "Distance": "47.9386385426654",
    "Mag": 4.48,
    "ColorIndex": 0.262
  },
  {
    "BayerFlamsteed": " 22Omi Cas",
    "ProperName": " ",
    "RA": "0.74541725",
    "Dec": "+48.28438262",
    "Distance": "277.777777777778",
    "Mag": 4.48,
    "ColorIndex": -0.069
  },
  {
    "BayerFlamsteed": " 18    Mon",
    "ProperName": " ",
    "RA": "6.79768245",
    "Dec": "+02.41218914",
    "Distance": "114.41647597254",
    "Mag": 4.48,
    "ColorIndex": 1.099
  },
  {
    "BayerFlamsteed": " 15Alp Sex",
    "ProperName": " ",
    "RA": "10.13230432",
    "Dec": "-00.37162786",
    "Distance": "88.1057268722467",
    "Mag": 4.48,
    "ColorIndex": -0.032
  },
  {
    "BayerFlamsteed": "  4Psi Oph",
    "ProperName": " ",
    "RA": "16.40172146",
    "Dec": "-20.03721137",
    "Distance": "54.5851528384279",
    "Mag": 4.48,
    "ColorIndex": 0.996
  },
  {
    "BayerFlamsteed": "  4Bet Psc",
    "ProperName": " ",
    "RA": "23.06461292",
    "Dec": "+03.82006998",
    "Distance": "151.057401812689",
    "Mag": 4.48,
    "ColorIndex": -0.115
  },
  {
    "BayerFlamsteed": "   Gam Ret",
    "ProperName": " ",
    "RA": "4.01494584",
    "Dec": "-62.15936923",
    "Distance": "150.375939849624",
    "Mag": 4.48,
    "ColorIndex": 1.5
  },
  {
    "BayerFlamsteed": "   Gam Cir",
    "ProperName": " ",
    "RA": "15.38962715",
    "Dec": "-59.32069839",
    "Distance": "156.25",
    "Mag": 4.48,
    "ColorIndex": 0.169
  },
  {
    "BayerFlamsteed": " 28Sig Boo",
    "ProperName": " ",
    "RA": "14.57796958",
    "Dec": "+29.74480735",
    "Distance": "15.4655119084442",
    "Mag": 4.47,
    "ColorIndex": 0.364
  },
  {
    "BayerFlamsteed": " 26    UMa",
    "ProperName": " ",
    "RA": "9.58041525",
    "Dec": "+52.05156754",
    "Distance": "81.9000819000819",
    "Mag": 4.47,
    "ColorIndex": 0.027
  },
  {
    "BayerFlamsteed": " 16    Lib",
    "ProperName": " ",
    "RA": "14.95307133",
    "Dec": "-04.34608634",
    "Distance": "27.9095729835334",
    "Mag": 4.47,
    "ColorIndex": 0.318
  },
  {
    "BayerFlamsteed": " 13    Mon",
    "ProperName": " ",
    "RA": "6.5483971",
    "Dec": "+07.33297921",
    "Distance": "462.962962962963",
    "Mag": 4.47,
    "ColorIndex": 0.023
  },
  {
    "BayerFlamsteed": " 10Pi 6Ori",
    "ProperName": " ",
    "RA": "4.97580635",
    "Dec": "+01.71403506",
    "Distance": "292.397660818713",
    "Mag": 4.47,
    "ColorIndex": 1.369
  },
  {
    "BayerFlamsteed": "  7Del Equ",
    "ProperName": " ",
    "RA": "21.24133058",
    "Dec": "+10.00771855",
    "Distance": "18.4808722971724",
    "Mag": 4.47,
    "ColorIndex": 0.529
  },
  {
    "BayerFlamsteed": "  1Tau1Eri",
    "ProperName": " ",
    "RA": "2.75166151",
    "Dec": "-18.57265077",
    "Distance": "13.9742873113471",
    "Mag": 4.47,
    "ColorIndex": 0.481
  },
  {
    "BayerFlamsteed": "  1Kap Leo",
    "ProperName": " ",
    "RA": "9.41091093",
    "Dec": "+26.18244091",
    "Distance": "65.4450261780105",
    "Mag": 4.47,
    "ColorIndex": 1.222
  },
  {
    "BayerFlamsteed": "   Lam Gru",
    "ProperName": " ",
    "RA": "22.10191767",
    "Dec": "-39.54304903",
    "Distance": "75.7575757575758",
    "Mag": 4.47,
    "ColorIndex": 1.349
  },
  {
    "BayerFlamsteed": "   Lam CMa",
    "ProperName": " ",
    "RA": "6.46950484",
    "Dec": "-32.58012947",
    "Distance": "124.53300124533",
    "Mag": 4.47,
    "ColorIndex": -0.169
  },
  {
    "BayerFlamsteed": " 32    Eri",
    "ProperName": " ",
    "RA": "3.90485739",
    "Dec": "-02.95473348",
    "Distance": "105.708245243129",
    "Mag": 4.46,
    "ColorIndex": 0.672
  },
  {
    "BayerFlamsteed": " 22Gam PsA",
    "ProperName": " ",
    "RA": "22.87543275",
    "Dec": "-32.87545019",
    "Distance": "68.1663258350375",
    "Mag": 4.46,
    "ColorIndex": -0.037
  },
  {
    "BayerFlamsteed": " 17Rho Ori",
    "ProperName": " ",
    "RA": "5.22152211",
    "Dec": "+02.86125346",
    "Distance": "105.374077976818",
    "Mag": 4.46,
    "ColorIndex": 1.166
  },
  {
    "BayerFlamsteed": " 15    UMa",
    "ProperName": " ",
    "RA": "9.1478849",
    "Dec": "+51.60472728",
    "Distance": "29.3083235638921",
    "Mag": 4.46,
    "ColorIndex": 0.288
  },
  {
    "BayerFlamsteed": " 11Bet Crt",
    "ProperName": " ",
    "RA": "11.19430174",
    "Dec": "-22.82560642",
    "Distance": "81.5660685154975",
    "Mag": 4.46,
    "ColorIndex": 0.025
  },
  {
    "BayerFlamsteed": "   Iot Cas",
    "ProperName": " ",
    "RA": "2.48444116",
    "Dec": "+67.40238385",
    "Distance": "43.4027777777778",
    "Mag": 4.46,
    "ColorIndex": 0.153
  },
  {
    "BayerFlamsteed": "   Eps Nor",
    "ProperName": " ",
    "RA": "16.45306883",
    "Dec": "-47.55473590",
    "Distance": "122.549019607843",
    "Mag": 4.46,
    "ColorIndex": -0.07
  },
  {
    "BayerFlamsteed": "106Nu  Psc",
    "ProperName": " ",
    "RA": "1.69052987",
    "Dec": "+05.48760445",
    "Distance": "112.866817155756",
    "Mag": 4.45,
    "ColorIndex": 1.347
  },
  {
    "BayerFlamsteed": " 74Phi Leo",
    "ProperName": " ",
    "RA": "11.27771224",
    "Dec": "-03.65151412",
    "Distance": "59.9161174355902",
    "Mag": 4.45,
    "ColorIndex": 0.21
  },
  {
    "BayerFlamsteed": " 70Xi  Ori",
    "ProperName": " ",
    "RA": "6.1989991",
    "Dec": "+14.20881425",
    "Distance": "194.552529182879",
    "Mag": 4.45,
    "ColorIndex": -0.18
  },
  {
    "BayerFlamsteed": " 60Tau Dra",
    "ProperName": " ",
    "RA": "19.25924825",
    "Dec": "+73.35521457",
    "Distance": "46.0193281178095",
    "Mag": 4.45,
    "ColorIndex": 1.257
  },
  {
    "BayerFlamsteed": " 38Mu  Aql",
    "ProperName": " ",
    "RA": "19.56811881",
    "Dec": "+07.37931918",
    "Distance": "33.8983050847458",
    "Mag": 4.45,
    "ColorIndex": 1.176
  },
  {
    "BayerFlamsteed": "  9Ome Oph",
    "ProperName": " ",
    "RA": "16.53560738",
    "Dec": "-21.46647783",
    "Distance": "53.5905680600214",
    "Mag": 4.45,
    "ColorIndex": 0.13
  },
  {
    "BayerFlamsteed": "  5Sig Hya",
    "ProperName": " ",
    "RA": "8.64595799",
    "Dec": "+03.34147477",
    "Distance": "108.108108108108",
    "Mag": 4.45,
    "ColorIndex": 1.216
  },
  {
    "BayerFlamsteed": "  3Iot Lep",
    "ProperName": " ",
    "RA": "5.20496871",
    "Dec": "-11.86914287",
    "Distance": "73.909830007391",
    "Mag": 4.45,
    "ColorIndex": -0.099
  },
  {
    "BayerFlamsteed": "   Del2Cha",
    "ProperName": " ",
    "RA": "10.76309326",
    "Dec": "-80.54020283",
    "Distance": "111.482720178372",
    "Mag": 4.45,
    "ColorIndex": -0.188
  },
  {
    "BayerFlamsteed": "   Bet For",
    "ProperName": " ",
    "RA": "2.8181554",
    "Dec": "-32.40628403",
    "Distance": "51.7866390471258",
    "Mag": 4.45,
    "ColorIndex": 0.981
  },
  {
    "BayerFlamsteed": " 63Del Psc",
    "ProperName": " ",
    "RA": "0.81135982",
    "Dec": "+07.58520186",
    "Distance": "93.5453695042095",
    "Mag": 4.44,
    "ColorIndex": 1.5
  },
  {
    "BayerFlamsteed": "  7    Cet",
    "ProperName": " ",
    "RA": "0.24400908",
    "Dec": "-18.93268628",
    "Distance": "151.057401812689",
    "Mag": 4.44,
    "ColorIndex": 1.64
  },
  {
    "BayerFlamsteed": "  6Alp Vul",
    "ProperName": " ",
    "RA": "19.4784475",
    "Dec": "+24.66516482",
    "Distance": "90.9090909090909",
    "Mag": 4.44,
    "ColorIndex": 1.502
  },
  {
    "BayerFlamsteed": "  2    Lyn",
    "ProperName": " ",
    "RA": "6.32705299",
    "Dec": "+59.01090518",
    "Distance": "45.7038391224863",
    "Mag": 4.44,
    "ColorIndex": 0.032
  },
  {
    "BayerFlamsteed": "   Sig Lup",
    "ProperName": " ",
    "RA": "14.54363429",
    "Dec": "-50.45712132",
    "Distance": "176.056338028169",
    "Mag": 4.44,
    "ColorIndex": -0.177
  },
  {
    "BayerFlamsteed": "   Eps Ret",
    "ProperName": " ",
    "RA": "4.27474542",
    "Dec": "-59.30174841",
    "Distance": "18.2348650619985",
    "Mag": 4.44,
    "ColorIndex": 1.078
  },
  {
    "BayerFlamsteed": "   Alp Cae",
    "ProperName": " ",
    "RA": "4.67606197",
    "Dec": "-41.86357034",
    "Distance": "20.1328769881216",
    "Mag": 4.44,
    "ColorIndex": 0.342
  },
  {
    "BayerFlamsteed": " 62    Sgr",
    "ProperName": " ",
    "RA": "20.04429424",
    "Dec": "-27.70987972",
    "Distance": "137.362637362637",
    "Mag": 4.43,
    "ColorIndex": 1.64
  },
  {
    "BayerFlamsteed": " 40Omi2Eri",
    "ProperName": " ",
    "RA": "4.25489948",
    "Dec": "-07.64455846",
    "Distance": "5.04439063761098",
    "Mag": 4.43,
    "ColorIndex": 0.82
  },
  {
    "BayerFlamsteed": " 39    Cyg",
    "ProperName": " ",
    "RA": "20.39766555",
    "Dec": "+32.19018607",
    "Distance": "78.3085356303837",
    "Mag": 4.43,
    "ColorIndex": 1.331
  },
  {
    "BayerFlamsteed": " 26Pi  Eri",
    "ProperName": " ",
    "RA": "3.76902844",
    "Dec": "-12.10173530",
    "Distance": "152.439024390244",
    "Mag": 4.43,
    "ColorIndex": 1.604
  },
  {
    "BayerFlamsteed": " 20Eta Lyr",
    "ProperName": " ",
    "RA": "19.22930243",
    "Dec": "+39.14596968",
    "Distance": "319.488817891374",
    "Mag": 4.43,
    "ColorIndex": -0.15
  },
  {
    "BayerFlamsteed": " 11Del Del",
    "ProperName": " ",
    "RA": "20.72431825",
    "Dec": "+15.07468224",
    "Distance": "62.3830318153462",
    "Mag": 4.43,
    "ColorIndex": 0.302
  },
  {
    "BayerFlamsteed": "  7    Cam",
    "ProperName": " ",
    "RA": "4.95478345",
    "Dec": "+53.75208289",
    "Distance": "115.207373271889",
    "Mag": 4.43,
    "ColorIndex": -0.017
  },
  {
    "BayerFlamsteed": "  3    Aqr",
    "ProperName": " ",
    "RA": "20.79562164",
    "Dec": "-05.02760303",
    "Distance": "136.425648021828",
    "Mag": 4.43,
    "ColorIndex": 1.639
  },
  {
    "BayerFlamsteed": " 68Ups Peg",
    "ProperName": " ",
    "RA": "23.4229615",
    "Dec": "+23.40401243",
    "Distance": "53.1067445565587",
    "Mag": 4.42,
    "ColorIndex": 0.617
  },
  {
    "BayerFlamsteed": " 68    Oph",
    "ProperName": " ",
    "RA": "18.02921931",
    "Dec": "+01.30510936",
    "Distance": "81.2347684809098",
    "Mag": 4.42,
    "ColorIndex": 0.046
  },
  {
    "BayerFlamsteed": " 67Nu  Ori",
    "ProperName": " ",
    "RA": "6.12620051",
    "Dec": "+14.76852318",
    "Distance": "163.934426229508",
    "Mag": 4.42,
    "ColorIndex": -0.164
  },
  {
    "BayerFlamsteed": " 60    Leo",
    "ProperName": " ",
    "RA": "11.0388281",
    "Dec": "+20.17974575",
    "Distance": "37.9218809252939",
    "Mag": 4.42,
    "ColorIndex": 0.053
  },
  {
    "BayerFlamsteed": " 58    Hya",
    "ProperName": " ",
    "RA": "14.83818519",
    "Dec": "-27.96021925",
    "Distance": "93.6329588014981",
    "Mag": 4.42,
    "ColorIndex": 1.366
  },
  {
    "BayerFlamsteed": " 27Lam Ser",
    "ProperName": " ",
    "RA": "15.77409608",
    "Dec": "+07.35323973",
    "Distance": "11.7536436295252",
    "Mag": 4.42,
    "ColorIndex": 0.604
  },
  {
    "BayerFlamsteed": " 27    CMa",
    "ProperName": " ",
    "RA": "7.23756011",
    "Dec": "-26.35251617",
    "Distance": "483.091787439614",
    "Mag": 4.42,
    "ColorIndex": -0.17
  },
  {
    "BayerFlamsteed": "  8Nu 3CMa",
    "ProperName": " ",
    "RA": "6.6315072",
    "Dec": "-18.23745713",
    "Distance": "142.247510668563",
    "Mag": 4.42,
    "ColorIndex": 1.137
  },
  {
    "BayerFlamsteed": "  3Bet Lac",
    "ProperName": " ",
    "RA": "22.39267678",
    "Dec": "+52.22949951",
    "Distance": "52.0562207183758",
    "Mag": 4.42,
    "ColorIndex": 1.015
  },
  {
    "BayerFlamsteed": " 94Nu  Her",
    "ProperName": " ",
    "RA": "17.97504159",
    "Dec": "+30.18926892",
    "Distance": "243.90243902439",
    "Mag": 4.41,
    "ColorIndex": 0.38
  },
  {
    "BayerFlamsteed": " 93Psi2Aqr",
    "ProperName": " ",
    "RA": "23.29838989",
    "Dec": "-09.18248990",
    "Distance": "98.7166831194472",
    "Mag": 4.41,
    "ColorIndex": -0.144
  },
  {
    "BayerFlamsteed": " 76Lam Her",
    "ProperName": " ",
    "RA": "17.512305",
    "Dec": "+26.11060450",
    "Distance": "112.612612612613",
    "Mag": 4.41,
    "ColorIndex": 1.434
  },
  {
    "BayerFlamsteed": " 66Ups Cyg",
    "ProperName": " ",
    "RA": "21.29862997",
    "Dec": "+34.89688120",
    "Distance": "276.243093922652",
    "Mag": 4.41,
    "ColorIndex": -0.103
  },
  {
    "BayerFlamsteed": " 46Tau Gem",
    "ProperName": " ",
    "RA": "7.18566433",
    "Dec": "+30.24528065",
    "Distance": "92.5069380203515",
    "Mag": 4.41,
    "ColorIndex": 1.261
  },
  {
    "BayerFlamsteed": " 33Pi  Cep",
    "ProperName": " ",
    "RA": "23.13162169",
    "Dec": "+75.38758150",
    "Distance": "67.4308833445718",
    "Mag": 4.41,
    "ColorIndex": 0.802
  },
  {
    "BayerFlamsteed": "   Gam Scl",
    "ProperName": " ",
    "RA": "23.31372966",
    "Dec": "-32.53183574",
    "Distance": "54.8245614035088",
    "Mag": 4.41,
    "ColorIndex": 1.109
  },
  {
    "BayerFlamsteed": " 38Eta And",
    "ProperName": " ",
    "RA": "0.95345215",
    "Dec": "+23.41775997",
    "Distance": "74.4047619047619",
    "Mag": 4.4,
    "ColorIndex": 0.94
  },
  {
    "BayerFlamsteed": " 16    Pup",
    "ProperName": " ",
    "RA": "8.15045651",
    "Dec": "-19.24500094",
    "Distance": "144.927536231884",
    "Mag": 4.4,
    "ColorIndex": -0.16
  },
  {
    "BayerFlamsteed": "   Omi Pup",
    "ProperName": " ",
    "RA": "7.80143747",
    "Dec": "-25.93718054",
    "Distance": "757.575757575758",
    "Mag": 4.4,
    "ColorIndex": -0.07
  },
  {
    "BayerFlamsteed": "   Kap Pav",
    "ProperName": " ",
    "RA": "18.94917796",
    "Dec": "-67.23353511",
    "Distance": "166.666666666667",
    "Mag": 4.4,
    "ColorIndex": 0.53
  },
  {
    "BayerFlamsteed": "   Del Ind",
    "ProperName": " ",
    "RA": "21.96528638",
    "Dec": "-54.99256660",
    "Distance": "56.657223796034",
    "Mag": 4.4,
    "ColorIndex": 0.297
  },
  {
    "BayerFlamsteed": "110    Vir",
    "ProperName": " ",
    "RA": "15.04835277",
    "Dec": "+02.09127129",
    "Distance": "56.2429696287964",
    "Mag": 4.39,
    "ColorIndex": 1.026
  },
  {
    "BayerFlamsteed": " 54Chi1Ori",
    "ProperName": " ",
    "RA": "5.9064122",
    "Dec": "+20.27641498",
    "Distance": "8.66325911808022",
    "Mag": 4.39,
    "ColorIndex": 0.594
  },
  {
    "BayerFlamsteed": " 40Xi  Oph",
    "ProperName": " ",
    "RA": "17.35005841",
    "Dec": "-21.11243499",
    "Distance": "17.3973556019485",
    "Mag": 4.39,
    "ColorIndex": 0.394
  },
  {
    "BayerFlamsteed": " 37Phi1Ori",
    "ProperName": " ",
    "RA": "5.58034384",
    "Dec": "+09.48958528",
    "Distance": "302.114803625378",
    "Mag": 4.39,
    "ColorIndex": -0.157
  },
  {
    "BayerFlamsteed": " 31    Leo",
    "ProperName": " ",
    "RA": "10.13175523",
    "Dec": "+09.99766389",
    "Distance": "84.1042893187552",
    "Mag": 4.39,
    "ColorIndex": 1.448
  },
  {
    "BayerFlamsteed": " 25Iot Oph",
    "ProperName": " ",
    "RA": "16.90013982",
    "Dec": "+10.16544339",
    "Distance": "71.6845878136201",
    "Mag": 4.39,
    "ColorIndex": -0.088
  },
  {
    "BayerFlamsteed": "  8Eps Mon",
    "ProperName": " ",
    "RA": "6.39613824",
    "Dec": "+04.59283881",
    "Distance": "39.3855848759354",
    "Mag": 4.39,
    "ColorIndex": 0.215
  },
  {
    "BayerFlamsteed": "  6Bet Sge",
    "ProperName": " ",
    "RA": "19.68414825",
    "Dec": "+17.47612356",
    "Distance": "143.061516452074",
    "Mag": 4.39,
    "ColorIndex": 1.041
  },
  {
    "BayerFlamsteed": "  5Alp Sge",
    "ProperName": " ",
    "RA": "19.66827292",
    "Dec": "+18.01393839",
    "Distance": "145.137880986938",
    "Mag": 4.39,
    "ColorIndex": 0.777
  },
  {
    "BayerFlamsteed": "   The Ind",
    "ProperName": " ",
    "RA": "21.33107883",
    "Dec": "-53.44926434",
    "Distance": "29.7796307325789",
    "Mag": 4.39,
    "ColorIndex": 0.191
  },
  {
    "BayerFlamsteed": "   Psi Phe",
    "ProperName": " ",
    "RA": "1.89411658",
    "Dec": "-46.30244631",
    "Distance": "98.5221674876847",
    "Mag": 4.39,
    "ColorIndex": 1.597
  },
  {
    "BayerFlamsteed": " 99    Aqr",
    "ProperName": " ",
    "RA": "23.43411593",
    "Dec": "-20.64186122",
    "Distance": "94.6073793755913",
    "Mag": 4.38,
    "ColorIndex": 1.46
  },
  {
    "BayerFlamsteed": " 51The Vir",
    "ProperName": " ",
    "RA": "13.16583667",
    "Dec": "-05.53892987",
    "Distance": "127.226463104326",
    "Mag": 4.38,
    "ColorIndex": -0.008
  },
  {
    "BayerFlamsteed": "  1Kap Cep",
    "ProperName": " ",
    "RA": "20.14814344",
    "Dec": "+77.71136178",
    "Distance": "100.300902708124",
    "Mag": 4.38,
    "ColorIndex": -0.046
  },
  {
    "BayerFlamsteed": "   Bet Scl",
    "ProperName": " ",
    "RA": "23.5494967",
    "Dec": "-37.81835895",
    "Distance": "54.7045951859956",
    "Mag": 4.38,
    "ColorIndex": -0.095
  },
  {
    "BayerFlamsteed": "102    Her",
    "ProperName": " ",
    "RA": "18.14597017",
    "Dec": "+20.81457203",
    "Distance": "467.289719626168",
    "Mag": 4.37,
    "ColorIndex": -0.164
  },
  {
    "BayerFlamsteed": " 30Tau CMa",
    "ProperName": " ",
    "RA": "7.31180218",
    "Dec": "-24.95438447",
    "Distance": "980.392156862745",
    "Mag": 4.37,
    "ColorIndex": -0.132
  },
  {
    "BayerFlamsteed": " 30    Psc",
    "ProperName": " ",
    "RA": "0.03266433",
    "Dec": "-06.01397169",
    "Distance": "127.226463104326",
    "Mag": 4.37,
    "ColorIndex": 1.631
  },
  {
    "BayerFlamsteed": "   The1Sgr",
    "ProperName": " ",
    "RA": "19.99560397",
    "Dec": "-35.27624433",
    "Distance": "189.393939393939",
    "Mag": 4.37,
    "ColorIndex": -0.15
  },
  {
    "BayerFlamsteed": "   Kap Col",
    "ProperName": " ",
    "RA": "6.27587093",
    "Dec": "-35.14073157",
    "Distance": "56.0852495793606",
    "Mag": 4.37,
    "ColorIndex": 0.978
  },
  {
    "BayerFlamsteed": " 65Xi 1Cet",
    "ProperName": " ",
    "RA": "2.21666971",
    "Dec": "+08.84675230",
    "Distance": "110.987791342952",
    "Mag": 4.36,
    "ColorIndex": 0.878
  },
  {
    "BayerFlamsteed": " 61Ome Eri",
    "ProperName": " ",
    "RA": "4.88157788",
    "Dec": "-05.45275591",
    "Distance": "69.4927032661571",
    "Mag": 4.36,
    "ColorIndex": 0.257
  },
  {
    "BayerFlamsteed": " 41Iot Aql",
    "ProperName": " ",
    "RA": "19.61202126",
    "Dec": "-01.28655047",
    "Distance": "94.2507068803016",
    "Mag": 4.36,
    "ColorIndex": -0.079
  },
  {
    "BayerFlamsteed": " 37    Tau",
    "ProperName": " ",
    "RA": "4.07823829",
    "Dec": "+22.08206790",
    "Distance": "55.4323725055432",
    "Mag": 4.36,
    "ColorIndex": 1.064
  },
  {
    "BayerFlamsteed": " 35Sig Per",
    "ProperName": " ",
    "RA": "3.50957839",
    "Dec": "+47.99517199",
    "Distance": "108.342361863489",
    "Mag": 4.36,
    "ColorIndex": 1.367
  },
  {
    "BayerFlamsteed": " 29Zet Mon",
    "ProperName": " ",
    "RA": "8.14323824",
    "Dec": "-02.98377649",
    "Distance": "568.181818181818",
    "Mag": 4.36,
    "ColorIndex": 0.97
  },
  {
    "BayerFlamsteed": " 20Iot CMa",
    "ProperName": " ",
    "RA": "6.93561842",
    "Dec": "-17.05424675",
    "Distance": "943.396226415094",
    "Mag": 4.36,
    "ColorIndex": -0.063
  },
  {
    "BayerFlamsteed": "  4Kap Lep",
    "ProperName": " ",
    "RA": "5.22052361",
    "Dec": "-12.94128833",
    "Distance": "171.526586620926",
    "Mag": 4.36,
    "ColorIndex": -0.094
  },
  {
    "BayerFlamsteed": "   Gam Col",
    "ProperName": " ",
    "RA": "5.95894774",
    "Dec": "-35.28330688",
    "Distance": "261.780104712042",
    "Mag": 4.36,
    "ColorIndex": -0.165
  },
  {
    "BayerFlamsteed": "   Eta Phe",
    "ProperName": " ",
    "RA": "0.72256778",
    "Dec": "-57.46309763",
    "Distance": "73.6919675755343",
    "Mag": 4.36,
    "ColorIndex": 0.024
  },
  {
    "BayerFlamsteed": "   Chi Cen",
    "ProperName": " ",
    "RA": "14.10077389",
    "Dec": "-41.17958035",
    "Distance": "136.798905608755",
    "Mag": 4.36,
    "ColorIndex": -0.198
  },
  {
    "BayerFlamsteed": "   Bet1Tuc",
    "ProperName": " ",
    "RA": "0.52571132",
    "Dec": "-62.95808549",
    "Distance": "42.8265524625268",
    "Mag": 4.36,
    "ColorIndex": -0.064
  },
  {
    "BayerFlamsteed": " 57Del Ari",
    "ProperName": " ",
    "RA": "3.19379712",
    "Dec": "+19.72669777",
    "Distance": "51.440329218107",
    "Mag": 4.35,
    "ColorIndex": 1.033
  },
  {
    "BayerFlamsteed": " 23Del UMi",
    "ProperName": " ",
    "RA": "17.53691588",
    "Dec": "+86.58632924",
    "Distance": "56.0224089635854",
    "Mag": 4.35,
    "ColorIndex": 0.021
  },
  {
    "BayerFlamsteed": " 21The Lyr",
    "ProperName": " ",
    "RA": "19.27280428",
    "Dec": "+38.13372778",
    "Distance": "235.849056603774",
    "Mag": 4.35,
    "ColorIndex": 1.258
  },
  {
    "BayerFlamsteed": " 15Gam Com",
    "ProperName": " ",
    "RA": "12.44897988",
    "Dec": "+28.26861975",
    "Distance": "52.1376433785193",
    "Mag": 4.35,
    "ColorIndex": 1.128
  },
  {
    "BayerFlamsteed": " 15    Lyn",
    "ProperName": " ",
    "RA": "6.9546107",
    "Dec": "+58.42305973",
    "Distance": "52.2466039707419",
    "Mag": 4.35,
    "ColorIndex": 0.85
  },
  {
    "BayerFlamsteed": " 13Rho Hya",
    "ProperName": " ",
    "RA": "8.8072169",
    "Dec": "+05.83788486",
    "Distance": "103.412616339193",
    "Mag": 4.35,
    "ColorIndex": -0.044
  },
  {
    "BayerFlamsteed": "  9Iot PsA",
    "ProperName": " ",
    "RA": "21.74910772",
    "Dec": "-33.02555306",
    "Distance": "62.7746390458255",
    "Mag": 4.35,
    "ColorIndex": -0.053
  },
  {
    "BayerFlamsteed": "  2Pi 2Ori",
    "ProperName": " ",
    "RA": "4.84353396",
    "Dec": "+08.90025258",
    "Distance": "59.3824228028504",
    "Mag": 4.35,
    "ColorIndex": 0.01
  },
  {
    "BayerFlamsteed": "  2    Lup",
    "ProperName": " ",
    "RA": "15.29717913",
    "Dec": "-30.14866233",
    "Distance": "94.4287063267233",
    "Mag": 4.35,
    "ColorIndex": 1.1
  },
  {
    "BayerFlamsteed": "   Xi  Pav",
    "ProperName": " ",
    "RA": "18.38711682",
    "Dec": "-61.49390506",
    "Distance": "128.865979381443",
    "Mag": 4.35,
    "ColorIndex": 1.462
  },
  {
    "BayerFlamsteed": "   Eps Vol",
    "ProperName": " ",
    "RA": "8.13217799",
    "Dec": "-68.61713647",
    "Distance": "196.850393700787",
    "Mag": 4.35,
    "ColorIndex": -0.113
  },
  {
    "BayerFlamsteed": "111    Her",
    "ProperName": " ",
    "RA": "18.78367287",
    "Dec": "+18.18122968",
    "Distance": "28.4333238555587",
    "Mag": 4.34,
    "ColorIndex": 0.148
  },
  {
    "BayerFlamsteed": " 49Sig Oph",
    "ProperName": " ",
    "RA": "17.441911",
    "Dec": "+04.14034239",
    "Distance": "359.712230215827",
    "Mag": 4.34,
    "ColorIndex": 1.48
  },
  {
    "BayerFlamsteed": " 33The Cas",
    "ProperName": " ",
    "RA": "1.18498074",
    "Dec": "+55.14994765",
    "Distance": "42.1407501053519",
    "Mag": 4.34,
    "ColorIndex": 0.17
  },
  {
    "BayerFlamsteed": " 30Eps And",
    "ProperName": " ",
    "RA": "0.64263868",
    "Dec": "+29.31236900",
    "Distance": "51.7063081695967",
    "Mag": 4.34,
    "ColorIndex": 0.871
  },
  {
    "BayerFlamsteed": " 29Pi  And",
    "ProperName": " ",
    "RA": "0.61467749",
    "Dec": "+33.71935227",
    "Distance": "201.207243460765",
    "Mag": 4.34,
    "ColorIndex": -0.123
  },
  {
    "BayerFlamsteed": "  9    Peg",
    "ProperName": " ",
    "RA": "21.74185847",
    "Dec": "+17.35004352",
    "Distance": "276.243093922652",
    "Mag": 4.34,
    "ColorIndex": 1.161
  },
  {
    "BayerFlamsteed": "  6Zet1Lyr",
    "ProperName": " ",
    "RA": "18.7462044",
    "Dec": "+37.60505025",
    "Distance": "47.1031559114461",
    "Mag": 4.34,
    "ColorIndex": 0.192
  },
  {
    "BayerFlamsteed": "  5    Lac",
    "ProperName": " ",
    "RA": "22.49217298",
    "Dec": "+47.70689488",
    "Distance": "357.142857142857",
    "Mag": 4.34,
    "ColorIndex": 1.679
  },
  {
    "BayerFlamsteed": "  4Xi 1CMa",
    "ProperName": " ",
    "RA": "6.53093562",
    "Dec": "-23.41843727",
    "Distance": "628.930817610063",
    "Mag": 4.34,
    "ColorIndex": -0.245
  },
  {
    "BayerFlamsteed": "   Ups2Cen",
    "ProperName": " ",
    "RA": "14.02874776",
    "Dec": "-45.60336989",
    "Distance": "137.362637362637",
    "Mag": 4.34,
    "ColorIndex": 0.598
  },
  {
    "BayerFlamsteed": "   The Cha",
    "ProperName": " ",
    "RA": "8.34413553",
    "Dec": "-77.48457640",
    "Distance": "47.1253534401508",
    "Mag": 4.34,
    "ColorIndex": 1.161
  },
  {
    "BayerFlamsteed": "   Ome Lup",
    "ProperName": " ",
    "RA": "15.63425619",
    "Dec": "-42.56748493",
    "Distance": "70.4721634954193",
    "Mag": 4.34,
    "ColorIndex": 1.412
  },
  {
    "BayerFlamsteed": "   Del Dor",
    "ProperName": " ",
    "RA": "5.74622751",
    "Dec": "-65.73554080",
    "Distance": "44.4839857651246",
    "Mag": 4.34,
    "ColorIndex": 0.217
  },
  {
    "BayerFlamsteed": " 36    Oph",
    "ProperName": " ",
    "RA": "17.25591313",
    "Dec": "-26.60004896",
    "Distance": "5.98515681110845",
    "Mag": 4.33,
    "ColorIndex": 0.855
  },
  {
    "BayerFlamsteed": "  4Gam CMi",
    "ProperName": " ",
    "RA": "7.46939707",
    "Dec": "+08.92550376",
    "Distance": "121.951219512195",
    "Mag": 4.33,
    "ColorIndex": 1.425
  },
  {
    "BayerFlamsteed": "  1Kap Lyr",
    "ProperName": " ",
    "RA": "18.33103366",
    "Dec": "+36.06444696",
    "Distance": "72.9394602479942",
    "Mag": 4.33,
    "ColorIndex": 1.162
  },
  {
    "BayerFlamsteed": "   Tau2Lup",
    "ProperName": " ",
    "RA": "14.43633376",
    "Dec": "-45.37926095",
    "Distance": "96.3391136801541",
    "Mag": 4.33,
    "ColorIndex": 0.434
  },
  {
    "BayerFlamsteed": "   Pi  Pav",
    "ProperName": " ",
    "RA": "18.14299745",
    "Dec": "-63.66804844",
    "Distance": "42.4628450106157",
    "Mag": 4.33,
    "ColorIndex": 0.228
  },
  {
    "BayerFlamsteed": "119    Tau",
    "ProperName": " ",
    "RA": "5.53687534",
    "Dec": "+18.59424525",
    "Distance": "588.235294117647",
    "Mag": 4.32,
    "ColorIndex": 2.06
  },
  {
    "BayerFlamsteed": " 54    Eri",
    "ProperName": " ",
    "RA": "4.67402609",
    "Dec": "-19.67125686",
    "Distance": "110.741971207087",
    "Mag": 4.32,
    "ColorIndex": 1.599
  },
  {
    "BayerFlamsteed": " 53Nu  Ser",
    "ProperName": " ",
    "RA": "17.34712098",
    "Dec": "-12.84688200",
    "Distance": "59.3119810201661",
    "Mag": 4.32,
    "ColorIndex": 0.037
  },
  {
    "BayerFlamsteed": " 44Kap Aur",
    "ProperName": " ",
    "RA": "6.25631571",
    "Dec": "+29.49871224",
    "Distance": "51.7866390471258",
    "Mag": 4.32,
    "ColorIndex": 1.021
  },
  {
    "BayerFlamsteed": " 42Alp Com",
    "ProperName": " ",
    "RA": "13.1665415",
    "Dec": "+17.52911621",
    "Distance": "14.3245953301819",
    "Mag": 4.32,
    "ColorIndex": 0.455
  },
  {
    "BayerFlamsteed": " 37Psi Per",
    "ProperName": " ",
    "RA": "3.60815558",
    "Dec": "+48.19270068",
    "Distance": "214.592274678112",
    "Mag": 4.32,
    "ColorIndex": -0.058
  },
  {
    "BayerFlamsteed": " 12    Hya",
    "ProperName": " ",
    "RA": "8.77292429",
    "Dec": "-13.54770494",
    "Distance": "69.5410292072323",
    "Mag": 4.32,
    "ColorIndex": 0.9
  },
  {
    "BayerFlamsteed": "  4Lam Leo",
    "ProperName": " ",
    "RA": "9.52867787",
    "Dec": "+22.96806545",
    "Distance": "103.199174406605",
    "Mag": 4.32,
    "ColorIndex": 1.541
  },
  {
    "BayerFlamsteed": "  3    Cen",
    "ProperName": " ",
    "RA": "13.86378478",
    "Dec": "-32.99401625",
    "Distance": "91.2408759124088",
    "Mag": 4.32,
    "ColorIndex": -0.146
  },
  {
    "BayerFlamsteed": "   The1Cru",
    "ProperName": " ",
    "RA": "12.05047198",
    "Dec": "-63.31294495",
    "Distance": "70.4721634954193",
    "Mag": 4.32,
    "ColorIndex": 0.28
  },
  {
    "BayerFlamsteed": "   Omi Lup",
    "ProperName": " ",
    "RA": "14.86064536",
    "Dec": "-43.57529383",
    "Distance": "125",
    "Mag": 4.32,
    "ColorIndex": -0.154
  },
  {
    "BayerFlamsteed": " 71    Aql",
    "ProperName": " ",
    "RA": "20.63896486",
    "Dec": "-01.10507951",
    "Distance": "117.647058823529",
    "Mag": 4.31,
    "ColorIndex": 0.949
  },
  {
    "BayerFlamsteed": " 51Mu 1Boo",
    "ProperName": " ",
    "RA": "15.4082045",
    "Dec": "+37.37696091",
    "Distance": "37.0919881305638",
    "Mag": 4.31,
    "ColorIndex": 0.309
  },
  {
    "BayerFlamsteed": " 10Ome2Sco",
    "ProperName": " ",
    "RA": "16.1234167",
    "Dec": "-20.86865410",
    "Distance": "81.1688311688312",
    "Mag": 4.31,
    "ColorIndex": 0.831
  },
  {
    "BayerFlamsteed": "   Del Oct",
    "ProperName": " ",
    "RA": "14.44881687",
    "Dec": "-83.66785308",
    "Distance": "85.4700854700855",
    "Mag": 4.31,
    "ColorIndex": 1.3
  },
  {
    "BayerFlamsteed": " 91Ups Leo",
    "ProperName": " ",
    "RA": "11.61581384",
    "Dec": "-00.82385424",
    "Distance": "54.6149645002731",
    "Mag": 4.3,
    "ColorIndex": 0.983
  },
  {
    "BayerFlamsteed": " 73Xi 2Cet",
    "ProperName": " ",
    "RA": "2.46931052",
    "Dec": "+08.46008870",
    "Distance": "53.9665407447383",
    "Mag": 4.3,
    "ColorIndex": -0.053
  },
  {
    "BayerFlamsteed": " 68Del3Tau",
    "ProperName": " ",
    "RA": "4.42481039",
    "Dec": "+17.92798917",
    "Distance": "45.3514739229025",
    "Mag": 4.3,
    "ColorIndex": 0.049
  },
  {
    "BayerFlamsteed": " 54    Leo",
    "ProperName": " ",
    "RA": "10.92690222",
    "Dec": "+24.74975451",
    "Distance": "88.5739592559787",
    "Mag": 4.3,
    "ColorIndex": 0.016
  },
  {
    "BayerFlamsteed": " 35Pi  Aur",
    "ProperName": " ",
    "RA": "5.99891705",
    "Dec": "+45.93675346",
    "Distance": "257.731958762887",
    "Mag": 4.3,
    "ColorIndex": 1.701
  },
  {
    "BayerFlamsteed": " 19    Tau",
    "ProperName": " ",
    "RA": "3.75346725",
    "Dec": "+24.46737879",
    "Distance": "114.285714285714",
    "Mag": 4.3,
    "ColorIndex": -0.11
  },
  {
    "BayerFlamsteed": "  8Eta Crv",
    "ProperName": " ",
    "RA": "12.53457909",
    "Dec": "-16.19586556",
    "Distance": "18.2083029861617",
    "Mag": 4.3,
    "ColorIndex": 0.388
  },
  {
    "BayerFlamsteed": "  7Eta Hya",
    "ProperName": " ",
    "RA": "8.72041287",
    "Dec": "+03.39866539",
    "Distance": "143.061516452074",
    "Mag": 4.3,
    "ColorIndex": -0.192
  },
  {
    "BayerFlamsteed": "  5Alp1Cap",
    "ProperName": " ",
    "RA": "20.29412669",
    "Dec": "-12.50821403",
    "Distance": "210.526315789474",
    "Mag": 4.3,
    "ColorIndex": 0.928
  },
  {
    "BayerFlamsteed": "   Alp Scl",
    "ProperName": " ",
    "RA": "0.97676274",
    "Dec": "-29.35746436",
    "Distance": "206.185567010309",
    "Mag": 4.3,
    "ColorIndex": -0.154
  },
  {
    "BayerFlamsteed": " 33Iot Aqr",
    "ProperName": " ",
    "RA": "22.10727926",
    "Dec": "-13.86954013",
    "Distance": "52.9100529100529",
    "Mag": 4.29,
    "ColorIndex": -0.075
  },
  {
    "BayerFlamsteed": " 17Iot And",
    "ProperName": " ",
    "RA": "23.63560531",
    "Dec": "+43.26807610",
    "Distance": "154.083204930663",
    "Mag": 4.29,
    "ColorIndex": -0.083
  },
  {
    "BayerFlamsteed": " 17Bet PsA",
    "ProperName": " ",
    "RA": "22.52508043",
    "Dec": "-32.34602798",
    "Distance": "45.475216007276",
    "Mag": 4.29,
    "ColorIndex": 0.011
  },
  {
    "BayerFlamsteed": " 16Zet UMi",
    "ProperName": " ",
    "RA": "15.73429554",
    "Dec": "+77.79449901",
    "Distance": "115.207373271889",
    "Mag": 4.29,
    "ColorIndex": 0.038
  },
  {
    "BayerFlamsteed": " 10    Tau",
    "ProperName": " ",
    "RA": "3.61458859",
    "Dec": "+00.40283316",
    "Distance": "13.7193030594046",
    "Mag": 4.29,
    "ColorIndex": 0.575
  },
  {
    "BayerFlamsteed": "  8Phi Oph",
    "ProperName": " ",
    "RA": "16.51899859",
    "Dec": "-16.61264015",
    "Distance": "64.3915003219575",
    "Mag": 4.29,
    "ColorIndex": 0.924
  },
  {
    "BayerFlamsteed": "  6Lam Lep",
    "ProperName": " ",
    "RA": "5.32625721",
    "Dec": "-13.17677698",
    "Distance": "330.03300330033",
    "Mag": 4.29,
    "ColorIndex": -0.235
  },
  {
    "BayerFlamsteed": "   Bet Hya",
    "ProperName": " ",
    "RA": "11.88182257",
    "Dec": "-33.90813014",
    "Distance": "111.982082866741",
    "Mag": 4.29,
    "ColorIndex": -0.1
  },
  {
    "BayerFlamsteed": " 69Ups Tau",
    "ProperName": " ",
    "RA": "4.43844298",
    "Dec": "+22.81369394",
    "Distance": "47.4608448030375",
    "Mag": 4.28,
    "ColorIndex": 0.263
  },
  {
    "BayerFlamsteed": " 45    Oph",
    "ProperName": " ",
    "RA": "17.45590667",
    "Dec": "-29.86669942",
    "Distance": "34.1763499658236",
    "Mag": 4.28,
    "ColorIndex": 0.402
  },
  {
    "BayerFlamsteed": " 33    Cyg",
    "ProperName": " ",
    "RA": "20.22327809",
    "Dec": "+56.56752223",
    "Distance": "46.7071461933676",
    "Mag": 4.28,
    "ColorIndex": 0.114
  },
  {
    "BayerFlamsteed": " 32Iot Cap",
    "ProperName": " ",
    "RA": "21.3707715",
    "Dec": "-16.83455521",
    "Distance": "66.0938532716457",
    "Mag": 4.28,
    "ColorIndex": 0.888
  },
  {
    "BayerFlamsteed": " 29Pi 2Peg",
    "ProperName": " ",
    "RA": "22.16645906",
    "Dec": "+33.17826747",
    "Distance": "77.1604938271605",
    "Mag": 4.28,
    "ColorIndex": 0.471
  },
  {
    "BayerFlamsteed": "   The Gru",
    "ProperName": " ",
    "RA": "23.1146579",
    "Dec": "-43.52032436",
    "Distance": "40.7996736026112",
    "Mag": 4.28,
    "ColorIndex": 0.423
  },
  {
    "BayerFlamsteed": "   Alp Ant",
    "ProperName": " ",
    "RA": "10.4525433",
    "Dec": "-31.06780228",
    "Distance": "112.359550561798",
    "Mag": 4.28,
    "ColorIndex": 1.429
  },
  {
    "BayerFlamsteed": " 94Tau Tau",
    "ProperName": " ",
    "RA": "4.7040843",
    "Dec": "+22.95697545",
    "Distance": "122.850122850123",
    "Mag": 4.27,
    "ColorIndex": -0.112
  },
  {
    "BayerFlamsteed": " 90    Tau",
    "ProperName": " ",
    "RA": "4.63594491",
    "Dec": "+12.51087423",
    "Distance": "45.8926112895824",
    "Mag": 4.27,
    "ColorIndex": 0.122
  },
  {
    "BayerFlamsteed": " 87Mu  Cet",
    "ProperName": " ",
    "RA": "2.7489926",
    "Dec": "+10.11421979",
    "Distance": "25.8331180573495",
    "Mag": 4.27,
    "ColorIndex": 0.311
  },
  {
    "BayerFlamsteed": " 71Eps Psc",
    "ProperName": " ",
    "RA": "1.04907107",
    "Dec": "+07.89007256",
    "Distance": "58.343057176196",
    "Mag": 4.27,
    "ColorIndex": 0.952
  },
  {
    "BayerFlamsteed": " 49Mu  Tau",
    "ProperName": " ",
    "RA": "4.25890144",
    "Dec": "+08.89240989",
    "Distance": "133.333333333333",
    "Mag": 4.27,
    "ColorIndex": -0.054
  },
  {
    "BayerFlamsteed": " 12Gam2Del",
    "ProperName": " ",
    "RA": "20.77764388",
    "Dec": "+16.12477326",
    "Distance": "31.1138767890479",
    "Mag": 4.27,
    "ColorIndex": 1.042
  },
  {
    "BayerFlamsteed": " 10The Psc",
    "ProperName": " ",
    "RA": "23.46615772",
    "Dec": "+06.37909727",
    "Distance": "48.6854917234664",
    "Mag": 4.27,
    "ColorIndex": 1.062
  },
  {
    "BayerFlamsteed": "   Xi 2Cen",
    "ProperName": " ",
    "RA": "13.11518417",
    "Dec": "-49.90621586",
    "Distance": "126.262626262626",
    "Mag": 4.27,
    "ColorIndex": -0.182
  },
  {
    "BayerFlamsteed": "   Mu  Lup",
    "ProperName": " ",
    "RA": "15.30890239",
    "Dec": "-47.87519201",
    "Distance": "89.126559714795",
    "Mag": 4.27,
    "ColorIndex": -0.086
  },
  {
    "BayerFlamsteed": "   Bet2Sgr",
    "ProperName": " ",
    "RA": "19.38696127",
    "Dec": "-44.79964788",
    "Distance": "42.5170068027211",
    "Mag": 4.27,
    "ColorIndex": 0.35
  },
  {
    "BayerFlamsteed": "110Omi Psc",
    "ProperName": " ",
    "RA": "1.75655241",
    "Dec": "+09.15764102",
    "Distance": "79.1765637371338",
    "Mag": 4.26,
    "ColorIndex": 0.942
  },
  {
    "BayerFlamsteed": " 95    Her",
    "ProperName": " ",
    "RA": "18.02511192",
    "Dec": "+21.59568814",
    "Distance": "144.300144300144",
    "Mag": 4.26,
    "ColorIndex": 0.406
  },
  {
    "BayerFlamsteed": " 65Alp Cnc",
    "ProperName": " ",
    "RA": "8.97477693",
    "Dec": "+11.85777198",
    "Distance": "53.2197977647685",
    "Mag": 4.26,
    "ColorIndex": 0.141
  },
  {
    "BayerFlamsteed": " 42Phi And",
    "ProperName": " ",
    "RA": "1.15836727",
    "Dec": "+47.24182410",
    "Distance": "225.733634311512",
    "Mag": 4.26,
    "ColorIndex": 0.012
  },
  {
    "BayerFlamsteed": " 19Tau5Eri",
    "ProperName": " ",
    "RA": "3.56312442",
    "Dec": "-21.63281597",
    "Distance": "90.7441016333938",
    "Mag": 4.26,
    "ColorIndex": -0.106
  },
  {
    "BayerFlamsteed": " 17Xi  Cep",
    "ProperName": " ",
    "RA": "22.06310052",
    "Dec": "+64.62775425",
    "Distance": "31.1817898347365",
    "Mag": 4.26,
    "ColorIndex": 0.379
  },
  {
    "BayerFlamsteed": "  9Alp Cam",
    "ProperName": " ",
    "RA": "4.90083628",
    "Dec": "+66.34266029",
    "Distance": "2127.65957446809",
    "Mag": 4.26,
    "ColorIndex": -0.008
  },
  {
    "BayerFlamsteed": "   Gam Dor",
    "ProperName": " ",
    "RA": "4.26708095",
    "Dec": "-51.48709578",
    "Distance": "20.3004466098254",
    "Mag": 4.26,
    "ColorIndex": 0.312
  },
  {
    "BayerFlamsteed": " 88    Tau",
    "ProperName": " ",
    "RA": "4.59423154",
    "Dec": "+10.16091706",
    "Distance": "46.1254612546125",
    "Mag": 4.25,
    "ColorIndex": 0.184
  },
  {
    "BayerFlamsteed": " 69Lam Eri",
    "ProperName": " ",
    "RA": "5.15243971",
    "Dec": "-08.75407607",
    "Distance": "537.634408602151",
    "Mag": 4.25,
    "ColorIndex": -0.187
  },
  {
    "BayerFlamsteed": " 58    Per",
    "ProperName": " ",
    "RA": "4.61150934",
    "Dec": "+41.26485467",
    "Distance": "196.850393700787",
    "Mag": 4.25,
    "ColorIndex": 1.171
  },
  {
    "BayerFlamsteed": " 47Lam Per",
    "ProperName": " ",
    "RA": "4.10973758",
    "Dec": "+50.35135022",
    "Distance": "106.269925611052",
    "Mag": 4.25,
    "ColorIndex": -0.011
  },
  {
    "BayerFlamsteed": " 31    Lyn",
    "ProperName": " ",
    "RA": "8.38059169",
    "Dec": "+43.18837233",
    "Distance": "119.189511323004",
    "Mag": 4.25,
    "ColorIndex": 1.55
  },
  {
    "BayerFlamsteed": " 10Nu  Cep",
    "ProperName": " ",
    "RA": "21.75748035",
    "Dec": "+61.12081043",
    "Distance": "1562.5",
    "Mag": 4.25,
    "ColorIndex": 0.474
  },
  {
    "BayerFlamsteed": "  5    UMi",
    "ProperName": " ",
    "RA": "14.45875625",
    "Dec": "+75.69593921",
    "Distance": "105.708245243129",
    "Mag": 4.25,
    "ColorIndex": 1.431
  },
  {
    "BayerFlamsteed": "   Kap Tuc",
    "ProperName": " ",
    "RA": "1.26263759",
    "Dec": "-68.87623708",
    "Distance": "20.4331834899877",
    "Mag": 4.25,
    "ColorIndex": 0.48
  },
  {
    "BayerFlamsteed": " 91Psi1Aqr",
    "ProperName": " ",
    "RA": "23.26479915",
    "Dec": "-09.08769554",
    "Distance": "45.5166135639508",
    "Mag": 4.24,
    "ColorIndex": 1.107
  },
  {
    "BayerFlamsteed": " 89Pi  Cet",
    "ProperName": " ",
    "RA": "2.73537623",
    "Dec": "-13.85867594",
    "Distance": "135.135135135135",
    "Mag": 4.24,
    "ColorIndex": -0.122
  },
  {
    "BayerFlamsteed": " 56Omi Ser",
    "ProperName": " ",
    "RA": "17.69025458",
    "Dec": "-12.87517268",
    "Distance": "51.5198351365276",
    "Mag": 4.24,
    "ColorIndex": 0.086
  },
  {
    "BayerFlamsteed": "  8Bet CVn",
    "ProperName": " ",
    "RA": "12.5625257",
    "Dec": "+41.35676779",
    "Distance": "8.37100284614097",
    "Mag": 4.24,
    "ColorIndex": 0.588
  },
  {
    "BayerFlamsteed": "   Kap Eri",
    "ProperName": " ",
    "RA": "2.44975138",
    "Dec": "-47.70382692",
    "Distance": "162.074554294976",
    "Mag": 4.24,
    "ColorIndex": -0.136
  },
  {
    "BayerFlamsteed": "   Bet Cha",
    "ProperName": " ",
    "RA": "12.30581775",
    "Dec": "-79.31226899",
    "Distance": "82.9875518672199",
    "Mag": 4.24,
    "ColorIndex": -0.123
  },
  {
    "BayerFlamsteed": " 93Tau Vir",
    "ProperName": " ",
    "RA": "14.02743976",
    "Dec": "+01.54458338",
    "Distance": "66.9344042838019",
    "Mag": 4.23,
    "ColorIndex": 0.121
  },
  {
    "BayerFlamsteed": " 81Pi 2Cyg",
    "ProperName": " ",
    "RA": "21.77989052",
    "Dec": "+49.30957480",
    "Distance": "354.609929078014",
    "Mag": 4.23,
    "ColorIndex": -0.12
  },
  {
    "BayerFlamsteed": " 75Sig Gem",
    "ProperName": " ",
    "RA": "7.72185703",
    "Dec": "+28.88407170",
    "Distance": "37.4812593703148",
    "Mag": 4.23,
    "ColorIndex": 1.118
  },
  {
    "BayerFlamsteed": " 43Bet Com",
    "ProperName": " ",
    "RA": "13.19803407",
    "Dec": "+27.87603769",
    "Distance": "9.15499404925387",
    "Mag": 4.23,
    "ColorIndex": 0.572
  },
  {
    "BayerFlamsteed": " 11Phi Her",
    "ProperName": " ",
    "RA": "16.14616647",
    "Dec": "+44.93481883",
    "Distance": "70.0770847932726",
    "Mag": 4.23,
    "ColorIndex": -0.045
  },
  {
    "BayerFlamsteed": "  1    Cen",
    "ProperName": " ",
    "RA": "13.76154628",
    "Dec": "-33.04336606",
    "Distance": "19.2641109612791",
    "Mag": 4.23,
    "ColorIndex": 0.39
  },
  {
    "BayerFlamsteed": "   Zet Tuc",
    "ProperName": " ",
    "RA": "0.33386505",
    "Dec": "-64.87762320",
    "Distance": "8.59254167382712",
    "Mag": 4.23,
    "ColorIndex": 0.576
  },
  {
    "BayerFlamsteed": "   Mu  Cep",
    "ProperName": " ",
    "RA": "21.72512637",
    "Dec": "+58.78005308",
    "Distance": "1612.90322580645",
    "Mag": 4.23,
    "ColorIndex": 2.242
  },
  {
    "BayerFlamsteed": "   Gam CrA",
    "ProperName": " ",
    "RA": "19.10695543",
    "Dec": "-37.06275714",
    "Distance": "17.8922884236894",
    "Mag": 4.23,
    "ColorIndex": 0.523
  },
  {
    "BayerFlamsteed": "   Bet Aps",
    "ProperName": " ",
    "RA": "16.71817212",
    "Dec": "-77.51657182",
    "Distance": "48.3325277912035",
    "Mag": 4.23,
    "ColorIndex": 1.06
  },
  {
    "BayerFlamsteed": " 90Phi Aqr",
    "ProperName": " ",
    "RA": "23.23870347",
    "Dec": "-06.04852680",
    "Distance": "68.1198910081744",
    "Mag": 4.22,
    "ColorIndex": 1.545
  },
  {
    "BayerFlamsteed": " 67Sig Cyg",
    "ProperName": " ",
    "RA": "21.29026459",
    "Dec": "+39.39469016",
    "Distance": "1388.88888888889",
    "Mag": 4.22,
    "ColorIndex": 0.098
  },
  {
    "BayerFlamsteed": " 52    Cyg",
    "ProperName": " ",
    "RA": "20.7610442",
    "Dec": "+30.71965680",
    "Distance": "63.1313131313131",
    "Mag": 4.22,
    "ColorIndex": 1.051
  },
  {
    "BayerFlamsteed": " 43Phi Dra",
    "ProperName": " ",
    "RA": "18.34595588",
    "Dec": "+71.33772734",
    "Distance": "88.6524822695036",
    "Mag": 4.22,
    "ColorIndex": -0.093
  },
  {
    "BayerFlamsteed": " 27Tau6Eri",
    "ProperName": " ",
    "RA": "3.78083028",
    "Dec": "-23.24843800",
    "Distance": "17.9243592041585",
    "Mag": 4.22,
    "ColorIndex": 0.434
  },
  {
    "BayerFlamsteed": " 16    Per",
    "ProperName": " ",
    "RA": "2.84303172",
    "Dec": "+38.31890838",
    "Distance": "39.1542678151919",
    "Mag": 4.22,
    "ColorIndex": 0.343
  },
  {
    "BayerFlamsteed": " 12Del2Lyr",
    "ProperName": " ",
    "RA": "18.90841352",
    "Dec": "+36.89860518",
    "Distance": "275.482093663912",
    "Mag": 4.22,
    "ColorIndex": 1.575
  },
  {
    "BayerFlamsteed": "  7Chi Oph",
    "ProperName": " ",
    "RA": "16.45039952",
    "Dec": "-18.45619698",
    "Distance": "149.925037481259",
    "Mag": 4.22,
    "ColorIndex": 0.217
  },
  {
    "BayerFlamsteed": "   The Lup",
    "ProperName": " ",
    "RA": "16.10987675",
    "Dec": "-36.80221297",
    "Distance": "125.944584382872",
    "Mag": 4.22,
    "ColorIndex": -0.184
  },
  {
    "BayerFlamsteed": "   Lam Pav",
    "ProperName": " ",
    "RA": "18.87028792",
    "Dec": "-62.18756062",
    "Distance": "555.555555555556",
    "Mag": 4.22,
    "ColorIndex": -0.15
  },
  {
    "BayerFlamsteed": "   Bet Sct",
    "ProperName": " ",
    "RA": "18.7862437",
    "Dec": "-04.74782871",
    "Distance": "211.416490486258",
    "Mag": 4.22,
    "ColorIndex": 1.087
  },
  {
    "BayerFlamsteed": " 65Kap1Tau",
    "ProperName": " ",
    "RA": "4.42280521",
    "Dec": "+22.29398098",
    "Distance": "47.0145745181006",
    "Mag": 4.21,
    "ColorIndex": 0.136
  },
  {
    "BayerFlamsteed": " 22Eps UMi",
    "ProperName": " ",
    "RA": "16.76615597",
    "Dec": "+82.03725071",
    "Distance": "106.269925611052",
    "Mag": 4.21,
    "ColorIndex": 0.897
  },
  {
    "BayerFlamsteed": "  2The Cep",
    "ProperName": " ",
    "RA": "20.49300758",
    "Dec": "+62.99413722",
    "Distance": "41.5973377703827",
    "Mag": 4.21,
    "ColorIndex": 0.199
  },
  {
    "BayerFlamsteed": "   Gam Pav",
    "ProperName": " ",
    "RA": "21.44069225",
    "Dec": "-65.36814438",
    "Distance": "9.21658986175115",
    "Mag": 4.21,
    "ColorIndex": 0.494
  },
  {
    "BayerFlamsteed": " 46Xi  Peg",
    "ProperName": " ",
    "RA": "22.77817819",
    "Dec": "+12.17408381",
    "Distance": "16.249593760156",
    "Mag": 4.2,
    "ColorIndex": 0.502
  },
  {
    "BayerFlamsteed": " 35Sig Her",
    "ProperName": " ",
    "RA": "16.5683859",
    "Dec": "+42.43689565",
    "Distance": "92.678405931418",
    "Mag": 4.2,
    "ColorIndex": -0.013
  },
  {
    "BayerFlamsteed": " 32    Ori",
    "ProperName": " ",
    "RA": "5.51306886",
    "Dec": "+05.94821792",
    "Distance": "88.4955752212389",
    "Mag": 4.2,
    "ColorIndex": -0.143
  },
  {
    "BayerFlamsteed": " 31Bet LMi",
    "ProperName": " ",
    "RA": "10.46474791",
    "Dec": "+36.70747818",
    "Distance": "44.762757385855",
    "Mag": 4.2,
    "ColorIndex": 0.908
  },
  {
    "BayerFlamsteed": " 23Del PsA",
    "ProperName": " ",
    "RA": "22.93247",
    "Dec": "-32.53970196",
    "Distance": "52.2466039707419",
    "Mag": 4.2,
    "ColorIndex": 0.952
  },
  {
    "BayerFlamsteed": " 11    Pup",
    "ProperName": " ",
    "RA": "7.94765507",
    "Dec": "-22.88014849",
    "Distance": "154.083204930663",
    "Mag": 4.2,
    "ColorIndex": 0.718
  },
  {
    "BayerFlamsteed": "110    Her",
    "ProperName": " ",
    "RA": "18.76103644",
    "Dec": "+20.54712356",
    "Distance": "19.0949016612564",
    "Mag": 4.19,
    "ColorIndex": 0.483
  },
  {
    "BayerFlamsteed": "  2    Cen",
    "ProperName": " ",
    "RA": "13.82409757",
    "Dec": "-34.45063035",
    "Distance": "54.3773790103317",
    "Mag": 4.19,
    "ColorIndex": 1.52
  },
  {
    "BayerFlamsteed": " 98Kap Vir",
    "ProperName": " ",
    "RA": "14.21492805",
    "Dec": "-10.27404400",
    "Distance": "68.5400959561343",
    "Mag": 4.18,
    "ColorIndex": 1.323
  },
  {
    "BayerFlamsteed": " 23Eps Cep",
    "ProperName": " ",
    "RA": "22.25046638",
    "Dec": "+57.04346522",
    "Distance": "25.7334019557386",
    "Mag": 4.18,
    "ColorIndex": 0.278
  },
  {
    "BayerFlamsteed": " 19Lam Boo",
    "ProperName": " ",
    "RA": "14.27310454",
    "Dec": "+46.08791894",
    "Distance": "29.7796307325789",
    "Mag": 4.18,
    "ColorIndex": 0.087
  },
  {
    "BayerFlamsteed": " 18Eps PsA",
    "ProperName": " ",
    "RA": "22.67759043",
    "Dec": "-27.04361480",
    "Distance": "228.310502283105",
    "Mag": 4.18,
    "ColorIndex": -0.105
  },
  {
    "BayerFlamsteed": " 43The Aqr",
    "ProperName": " ",
    "RA": "22.28054621",
    "Dec": "-07.78323706",
    "Distance": "58.6854460093897",
    "Mag": 4.17,
    "ColorIndex": 0.979
  },
  {
    "BayerFlamsteed": " 15Kap Cas",
    "ProperName": " ",
    "RA": "0.54999626",
    "Dec": "+62.93178781",
    "Distance": "1265.82278481013",
    "Mag": 4.17,
    "ColorIndex": 0.13
  },
  {
    "BayerFlamsteed": " 62Rho Gem",
    "ProperName": " ",
    "RA": "7.48516936",
    "Dec": "+31.78407932",
    "Distance": "18.4979652238254",
    "Mag": 4.16,
    "ColorIndex": 0.32
  },
  {
    "BayerFlamsteed": " 44    Oph",
    "ProperName": " ",
    "RA": "17.43950482",
    "Dec": "-24.17502346",
    "Distance": "25.6673511293634",
    "Mag": 4.16,
    "ColorIndex": 0.283
  },
  {
    "BayerFlamsteed": "  1    Gem",
    "ProperName": " ",
    "RA": "6.06867126",
    "Dec": "+23.26363207",
    "Distance": "46.2107208872458",
    "Mag": 4.16,
    "ColorIndex": 0.835
  },
  {
    "BayerFlamsteed": " 75Rho Her",
    "ProperName": " ",
    "RA": "17.39471468",
    "Dec": "+37.14592396",
    "Distance": "123.152709359606",
    "Mag": 4.15,
    "ColorIndex": -0.011
  },
  {
    "BayerFlamsteed": " 22Del Mon",
    "ProperName": " ",
    "RA": "7.19773899",
    "Dec": "-00.49278056",
    "Distance": "114.942528735632",
    "Mag": 4.15,
    "ColorIndex": -0.005
  },
  {
    "BayerFlamsteed": " 19Kap And",
    "ProperName": " ",
    "RA": "23.67345605",
    "Dec": "+44.33397776",
    "Distance": "52.0291363163372",
    "Mag": 4.15,
    "ColorIndex": -0.071
  },
  {
    "BayerFlamsteed": " 23    Tau",
    "ProperName": " ",
    "RA": "3.77210009",
    "Dec": "+23.94846207",
    "Distance": "110.132158590308",
    "Mag": 4.14,
    "ColorIndex": -0.051
  },
  {
    "BayerFlamsteed": " 13Eps CrB",
    "ProperName": " ",
    "RA": "15.95980608",
    "Dec": "+26.87802632",
    "Distance": "70.4225352112676",
    "Mag": 4.14,
    "ColorIndex": 1.231
  },
  {
    "BayerFlamsteed": " 10Kap Peg",
    "ProperName": " ",
    "RA": "21.7440845",
    "Dec": "+25.64500284",
    "Distance": "35.2858151023289",
    "Mag": 4.14,
    "ColorIndex": 0.425
  },
  {
    "BayerFlamsteed": "  5    Tau",
    "ProperName": " ",
    "RA": "3.51454638",
    "Dec": "+12.93668186",
    "Distance": "110.497237569061",
    "Mag": 4.14,
    "ColorIndex": 1.112
  },
  {
    "BayerFlamsteed": "  4The CrB",
    "ProperName": " ",
    "RA": "15.5488322",
    "Dec": "+31.35915517",
    "Distance": "95.3288846520496",
    "Mag": 4.14,
    "ColorIndex": -0.127
  },
  {
    "BayerFlamsteed": "  4Del Hya",
    "ProperName": " ",
    "RA": "8.62761315",
    "Dec": "+05.70379868",
    "Distance": "54.9148819330038",
    "Mag": 4.14,
    "ColorIndex": 0.003
  },
  {
    "BayerFlamsteed": "  1    Lac",
    "ProperName": " ",
    "RA": "22.26615838",
    "Dec": "+37.74873483",
    "Distance": "192.307692307692",
    "Mag": 4.14,
    "ColorIndex": 1.447
  },
  {
    "BayerFlamsteed": "   Eta Cru",
    "ProperName": " ",
    "RA": "12.11468134",
    "Dec": "-64.61363898",
    "Distance": "19.6889151407757",
    "Mag": 4.14,
    "ColorIndex": 0.353
  },
  {
    "BayerFlamsteed": " 46The Lib",
    "ProperName": " ",
    "RA": "15.89707695",
    "Dec": "-16.72962230",
    "Distance": "49.95004995005",
    "Mag": 4.13,
    "ColorIndex": 1.003
  },
  {
    "BayerFlamsteed": " 29    Ori",
    "ProperName": " ",
    "RA": "5.39912122",
    "Dec": "-07.80795569",
    "Distance": "53.4473543559594",
    "Mag": 4.13,
    "ColorIndex": 0.943
  },
  {
    "BayerFlamsteed": " 18Nu  Gem",
    "ProperName": " ",
    "RA": "6.48271957",
    "Dec": "+20.21216720",
    "Distance": "154.083204930663",
    "Mag": 4.13,
    "ColorIndex": -0.115
  },
  {
    "BayerFlamsteed": " 17Iot Psc",
    "ProperName": " ",
    "RA": "23.66578342",
    "Dec": "+05.62735374",
    "Distance": "13.7912012136257",
    "Mag": 4.13,
    "ColorIndex": 0.507
  },
  {
    "BayerFlamsteed": " 16Psi Cap",
    "ProperName": " ",
    "RA": "20.76826836",
    "Dec": "-25.27051682",
    "Distance": "14.6713615023474",
    "Mag": 4.13,
    "ColorIndex": 0.426
  },
  {
    "BayerFlamsteed": "   Bet Oct",
    "ProperName": " ",
    "RA": "22.76770092",
    "Dec": "-81.38161731",
    "Distance": "43.0477830391735",
    "Mag": 4.13,
    "ColorIndex": 0.208
  },
  {
    "BayerFlamsteed": " 61Mu  Ori",
    "ProperName": " ",
    "RA": "6.03971954",
    "Dec": "+09.64736756",
    "Distance": "46.5332712889716",
    "Mag": 4.12,
    "ColorIndex": 0.17
  },
  {
    "BayerFlamsteed": " 51Mu  Per",
    "ProperName": " ",
    "RA": "4.24829381",
    "Dec": "+48.40937312",
    "Distance": "221.729490022173",
    "Mag": 4.12,
    "ColorIndex": 0.935
  },
  {
    "BayerFlamsteed": " 18Ome Cap",
    "ProperName": " ",
    "RA": "20.86369338",
    "Dec": "-26.91912642",
    "Distance": "192.678227360308",
    "Mag": 4.12,
    "ColorIndex": 1.633
  },
  {
    "BayerFlamsteed": "  9Omi Vir",
    "ProperName": " ",
    "RA": "12.08685266",
    "Dec": "+08.73284563",
    "Distance": "52.4109014675052",
    "Mag": 4.12,
    "ColorIndex": 0.967
  },
  {
    "BayerFlamsteed": "   Iot Sgr",
    "ProperName": " ",
    "RA": "19.92102196",
    "Dec": "-41.86841350",
    "Distance": "58.0046403712297",
    "Mag": 4.12,
    "ColorIndex": 1.063
  },
  {
    "BayerFlamsteed": "   Eps Hyi",
    "ProperName": " ",
    "RA": "2.65978435",
    "Dec": "-68.26694760",
    "Distance": "47.0145745181006",
    "Mag": 4.12,
    "ColorIndex": -0.061
  },
  {
    "BayerFlamsteed": "   Del2Gru",
    "ProperName": " ",
    "RA": "22.49595707",
    "Dec": "-43.74922804",
    "Distance": "99.601593625498",
    "Mag": 4.12,
    "ColorIndex": 1.57
  },
  {
    "BayerFlamsteed": " 39Ups1Hya",
    "ProperName": " ",
    "RA": "9.85796735",
    "Dec": "-14.84654997",
    "Distance": "83.8926174496644",
    "Mag": 4.11,
    "ColorIndex": 0.918
  },
  {
    "BayerFlamsteed": " 23Gam CMa",
    "ProperName": " ",
    "RA": "7.06263699",
    "Dec": "-15.63325876",
    "Distance": "123.304562268804",
    "Mag": 4.11,
    "ColorIndex": -0.112
  },
  {
    "BayerFlamsteed": "   Zet Gru",
    "ProperName": " ",
    "RA": "23.01468731",
    "Dec": "-52.75410562",
    "Distance": "34.494653328734",
    "Mag": 4.11,
    "ColorIndex": 0.96
  },
  {
    "BayerFlamsteed": "   Iot Eri",
    "ProperName": " ",
    "RA": "2.6777588",
    "Dec": "-39.85530905",
    "Distance": "44.6030330062444",
    "Mag": 4.11,
    "ColorIndex": 1.006
  },
  {
    "BayerFlamsteed": "   Gam Cha",
    "ProperName": " ",
    "RA": "10.59117177",
    "Dec": "-78.60781379",
    "Distance": "126.742712294043",
    "Mag": 4.11,
    "ColorIndex": 1.58
  },
  {
    "BayerFlamsteed": "   Eps TrA",
    "ProperName": " ",
    "RA": "15.61199636",
    "Dec": "-66.31690469",
    "Distance": "66.2690523525514",
    "Mag": 4.11,
    "ColorIndex": 1.161
  },
  {
    "BayerFlamsteed": "   Alp CrA",
    "ProperName": " ",
    "RA": "19.15785508",
    "Dec": "-37.90423953",
    "Distance": "39.7614314115308",
    "Mag": 4.11,
    "ColorIndex": 0.042
  },
  {
    "BayerFlamsteed": " 50Ups And",
    "ProperName": " ",
    "RA": "1.61332694",
    "Dec": "+41.40638491",
    "Distance": "13.4680134680135",
    "Mag": 4.1,
    "ColorIndex": 0.536
  },
  {
    "BayerFlamsteed": " 13The Per",
    "ProperName": " ",
    "RA": "2.73657999",
    "Dec": "+49.22866639",
    "Distance": "11.2321689318207",
    "Mag": 4.1,
    "ColorIndex": 0.514
  },
  {
    "BayerFlamsteed": "   Zet Tel",
    "ProperName": " ",
    "RA": "18.48048221",
    "Dec": "-49.07003097",
    "Distance": "39.0625",
    "Mag": 4.1,
    "ColorIndex": 0.995
  },
  {
    "BayerFlamsteed": "   Bet CrA",
    "ProperName": " ",
    "RA": "19.16715345",
    "Dec": "-39.34070677",
    "Distance": "155.763239875389",
    "Mag": 4.1,
    "ColorIndex": 1.163
  },
  {
    "BayerFlamsteed": " 40Phi2Ori",
    "ProperName": " ",
    "RA": "5.61509185",
    "Dec": "+09.29141200",
    "Distance": "35.5871886120996",
    "Mag": 4.09,
    "ColorIndex": 0.951
  },
  {
    "BayerFlamsteed": " 35Kap Ser",
    "ProperName": " ",
    "RA": "15.81233572",
    "Dec": "+18.14177930",
    "Distance": "106.837606837607",
    "Mag": 4.09,
    "ColorIndex": 1.616
  },
  {
    "BayerFlamsteed": " 82Del Cet",
    "ProperName": " ",
    "RA": "2.65804119",
    "Dec": "+00.32851680",
    "Distance": "198.412698412698",
    "Mag": 4.08,
    "ColorIndex": -0.212
  },
  {
    "BayerFlamsteed": " 34Zet And",
    "ProperName": " ",
    "RA": "0.78899727",
    "Dec": "+24.26737703",
    "Distance": "55.6173526140156",
    "Mag": 4.08,
    "ColorIndex": 1.1
  },
  {
    "BayerFlamsteed": " 23The Cap",
    "ProperName": " ",
    "RA": "21.09910538",
    "Dec": "-17.23271095",
    "Distance": "48.5201358563804",
    "Mag": 4.08,
    "ColorIndex": -0.01
  },
  {
    "BayerFlamsteed": " 14The CMa",
    "ProperName": " ",
    "RA": "6.90318908",
    "Dec": "-12.03859273",
    "Distance": "77.2797527047913",
    "Mag": 4.08,
    "ColorIndex": 1.418
  },
  {
    "BayerFlamsteed": " 13    Lyr",
    "ProperName": " ",
    "RA": "18.92224587",
    "Dec": "+43.94589260",
    "Distance": "107.181136120043",
    "Mag": 4.08,
    "ColorIndex": 1.397
  },
  {
    "BayerFlamsteed": " 11Tau3Eri",
    "ProperName": " ",
    "RA": "3.03988697",
    "Dec": "-23.62433613",
    "Distance": "26.4200792602378",
    "Mag": 4.08,
    "ColorIndex": 0.163
  },
  {
    "BayerFlamsteed": "  7Alp Crt",
    "ProperName": " ",
    "RA": "10.9963191",
    "Dec": "-18.29909723",
    "Distance": "53.4473543559594",
    "Mag": 4.08,
    "ColorIndex": 1.079
  },
  {
    "BayerFlamsteed": "  1    Peg",
    "ProperName": " ",
    "RA": "21.36809274",
    "Dec": "+19.80435581",
    "Distance": "47.192071731949",
    "Mag": 4.08,
    "ColorIndex": 1.108
  },
  {
    "BayerFlamsteed": "   Del Hyi",
    "ProperName": " ",
    "RA": "2.36250639",
    "Dec": "-68.65942390",
    "Distance": "41.49377593361",
    "Mag": 4.08,
    "ColorIndex": 0.034
  },
  {
    "BayerFlamsteed": " 99Iot Vir",
    "ProperName": " ",
    "RA": "14.26691247",
    "Dec": "-05.99952622",
    "Distance": "21.3949507916132",
    "Mag": 4.07,
    "ColorIndex": 0.511
  },
  {
    "BayerFlamsteed": " 27Del Cep",
    "ProperName": " ",
    "RA": "22.48617998",
    "Dec": "+58.41518990",
    "Distance": "301.204819277108",
    "Mag": 4.07,
    "ColorIndex": 0.778
  },
  {
    "BayerFlamsteed": "   Lam Lup",
    "ProperName": " ",
    "RA": "15.1473971",
    "Dec": "-45.27979406",
    "Distance": "124.688279301746",
    "Mag": 4.07,
    "ColorIndex": -0.162
  },
  {
    "BayerFlamsteed": "   Bet Cir",
    "ProperName": " ",
    "RA": "15.29193339",
    "Dec": "-58.80087882",
    "Distance": "29.6296296296296",
    "Mag": 4.07,
    "ColorIndex": 0.088
  },
  {
    "BayerFlamsteed": " 69Ups Gem",
    "ProperName": " ",
    "RA": "7.59871452",
    "Dec": "+26.89600343",
    "Distance": "73.6919675755343",
    "Mag": 4.06,
    "ColorIndex": 1.54
  },
  {
    "BayerFlamsteed": " 15Gam Crt",
    "ProperName": " ",
    "RA": "11.41471755",
    "Dec": "-17.68401748",
    "Distance": "25.706940874036",
    "Mag": 4.06,
    "ColorIndex": 0.216
  },
  {
    "BayerFlamsteed": "  9Omi2Ori",
    "ProperName": " ",
    "RA": "4.93953363",
    "Dec": "+13.51457755",
    "Distance": "51.9210799584631",
    "Mag": 4.06,
    "ColorIndex": 1.158
  },
  {
    "BayerFlamsteed": "   Zet Cru",
    "ProperName": " ",
    "RA": "12.30730353",
    "Dec": "-64.00304555",
    "Distance": "110.741971207087",
    "Mag": 4.06,
    "ColorIndex": -0.168
  },
  {
    "BayerFlamsteed": "   Eps1Ara",
    "ProperName": " ",
    "RA": "16.99306851",
    "Dec": "-53.16049005",
    "Distance": "93.2835820895522",
    "Mag": 4.06,
    "ColorIndex": 1.452
  },
  {
    "BayerFlamsteed": "   Eps Mus",
    "ProperName": " ",
    "RA": "12.29295453",
    "Dec": "-67.96067161",
    "Distance": "92.5925925925926",
    "Mag": 4.06,
    "ColorIndex": 1.603
  },
  {
    "BayerFlamsteed": " 77Sig Leo",
    "ProperName": " ",
    "RA": "11.35229115",
    "Dec": "+06.02935289",
    "Distance": "65.6167979002625",
    "Mag": 4.05,
    "ColorIndex": -0.058
  },
  {
    "BayerFlamsteed": " 71Tau2Aqr",
    "ProperName": " ",
    "RA": "22.8265305",
    "Dec": "-13.59253756",
    "Distance": "116.550116550117",
    "Mag": 4.05,
    "ColorIndex": 1.57
  },
  {
    "BayerFlamsteed": "  5Ups Boo",
    "ProperName": " ",
    "RA": "13.82463829",
    "Dec": "+15.79780583",
    "Distance": "75.2445447705041",
    "Mag": 4.05,
    "ColorIndex": 1.52
  },
  {
    "BayerFlamsteed": "   Rho Lup",
    "ProperName": " ",
    "RA": "14.63145864",
    "Dec": "-49.42575765",
    "Distance": "95.1474785918173",
    "Mag": 4.05,
    "ColorIndex": -0.152
  },
  {
    "BayerFlamsteed": "   Psi Cen",
    "ProperName": " ",
    "RA": "14.34263313",
    "Dec": "-37.88526711",
    "Distance": "75.8150113722517",
    "Mag": 4.05,
    "ColorIndex": -0.03
  },
  {
    "BayerFlamsteed": "   Iot Per",
    "ProperName": " ",
    "RA": "3.1508009",
    "Dec": "+49.61350009",
    "Distance": "10.5340777414937",
    "Mag": 4.05,
    "ColorIndex": 0.595
  },
  {
    "BayerFlamsteed": "   Alp Cha",
    "ProperName": " ",
    "RA": "8.30868523",
    "Dec": "-76.91998251",
    "Distance": "19.4552529182879",
    "Mag": 4.05,
    "ColorIndex": 0.413
  },
  {
    "BayerFlamsteed": " 62Eta Aqr",
    "ProperName": " ",
    "RA": "22.58925803",
    "Dec": "-00.11736123",
    "Distance": "56.274620146314",
    "Mag": 4.04,
    "ColorIndex": -0.083
  },
  {
    "BayerFlamsteed": " 38Omi1Eri",
    "ProperName": " ",
    "RA": "4.19775922",
    "Dec": "-06.83777870",
    "Distance": "38.4911470361817",
    "Mag": 4.04,
    "ColorIndex": 0.327
  },
  {
    "BayerFlamsteed": " 23The Boo",
    "ProperName": " ",
    "RA": "14.42000537",
    "Dec": "+51.85171354",
    "Distance": "14.5708873670407",
    "Mag": 4.04,
    "ColorIndex": 0.497
  },
  {
    "BayerFlamsteed": "  3Nu  Vir",
    "ProperName": " ",
    "RA": "11.76432515",
    "Dec": "+06.52981394",
    "Distance": "95.9692898272553",
    "Mag": 4.04,
    "ColorIndex": 1.501
  },
  {
    "BayerFlamsteed": " 70    Oph",
    "ProperName": " ",
    "RA": "18.09089245",
    "Dec": "+02.50243928",
    "Distance": "5.08595259892178",
    "Mag": 4.03,
    "ColorIndex": 0.86
  },
  {
    "BayerFlamsteed": " 48Iot Cnc",
    "ProperName": " ",
    "RA": "8.7782873",
    "Dec": "+28.76000509",
    "Distance": "91.4076782449726",
    "Mag": 4.03,
    "ColorIndex": 1.007
  },
  {
    "BayerFlamsteed": " 28Ome Psc",
    "ProperName": " ",
    "RA": "23.98850066",
    "Dec": "+06.86359373",
    "Distance": "32.488628979857",
    "Mag": 4.03,
    "ColorIndex": 0.419
  },
  {
    "BayerFlamsteed": " 10Bet Cam",
    "ProperName": " ",
    "RA": "5.05697146",
    "Dec": "+60.44228144",
    "Distance": "305.810397553517",
    "Mag": 4.03,
    "ColorIndex": 0.921
  },
  {
    "BayerFlamsteed": "  9Gam Tri",
    "ProperName": " ",
    "RA": "2.28856547",
    "Dec": "+33.84732099",
    "Distance": "36.0620266858997",
    "Mag": 4.03,
    "ColorIndex": 0.019
  },
  {
    "BayerFlamsteed": "  2Eps Del",
    "ProperName": " ",
    "RA": "20.55354577",
    "Dec": "+11.30333217",
    "Distance": "110.01100110011",
    "Mag": 4.03,
    "ColorIndex": -0.123
  },
  {
    "BayerFlamsteed": "   Mu 1Cru",
    "ProperName": " ",
    "RA": "12.90990489",
    "Dec": "-57.17789116",
    "Distance": "115.740740740741",
    "Mag": 4.03,
    "ColorIndex": -0.18
  },
  {
    "BayerFlamsteed": " 13Eps Aql",
    "ProperName": " ",
    "RA": "18.99371922",
    "Dec": "+15.06847757",
    "Distance": "47.1253534401508",
    "Mag": 4.02,
    "ColorIndex": 1.082
  },
  {
    "BayerFlamsteed": " 12    Aql",
    "ProperName": " ",
    "RA": "19.02801149",
    "Dec": "-05.73901832",
    "Distance": "45.5580865603645",
    "Mag": 4.02,
    "ColorIndex": 1.079
  },
  {
    "BayerFlamsteed": "  1Alp Crv",
    "ProperName": " ",
    "RA": "12.14020907",
    "Dec": "-24.72877993",
    "Distance": "14.7688672278836",
    "Mag": 4.02,
    "ColorIndex": 0.334
  },
  {
    "BayerFlamsteed": "   Gam Pyx",
    "ProperName": " ",
    "RA": "8.84222536",
    "Dec": "-27.71005869",
    "Distance": "63.9795265515035",
    "Mag": 4.02,
    "ColorIndex": 1.272
  },
  {
    "BayerFlamsteed": " 57Mu  Eri",
    "ProperName": " ",
    "RA": "4.7583725",
    "Dec": "-03.25462465",
    "Distance": "163.132137030995",
    "Mag": 4.01,
    "ColorIndex": -0.148
  },
  {
    "BayerFlamsteed": " 43Zet Gem",
    "ProperName": " ",
    "RA": "7.06848205",
    "Dec": "+20.57029939",
    "Distance": "358.4229390681",
    "Mag": 4.01,
    "ColorIndex": 0.899
  },
  {
    "BayerFlamsteed": " 41    Cyg",
    "ProperName": " ",
    "RA": "20.48992526",
    "Dec": "+30.36855661",
    "Distance": "232.558139534884",
    "Mag": 4.01,
    "ColorIndex": 0.404
  },
  {
    "BayerFlamsteed": " 28Ome CMa",
    "ProperName": " ",
    "RA": "7.24685045",
    "Dec": "-26.77268601",
    "Distance": "283.28611898017",
    "Mag": 4.01,
    "ColorIndex": -0.15
  },
  {
    "BayerFlamsteed": " 13The Dra",
    "ProperName": " ",
    "RA": "16.03158437",
    "Dec": "+58.56443739",
    "Distance": "20.9248796819418",
    "Mag": 4.01,
    "ColorIndex": 0.528
  },
  {
    "BayerFlamsteed": "   Zet Pav",
    "ProperName": " ",
    "RA": "18.71725939",
    "Dec": "-71.42772867",
    "Distance": "64.3086816720257",
    "Mag": 4.01,
    "ColorIndex": 1.134
  },
  {
    "BayerFlamsteed": "   Phi Per",
    "ProperName": " ",
    "RA": "1.72767091",
    "Dec": "+50.68876550",
    "Distance": "219.78021978022",
    "Mag": 4.01,
    "ColorIndex": -0.098
  },
  {
    "BayerFlamsteed": "   Gam2Nor",
    "ProperName": " ",
    "RA": "16.33071322",
    "Dec": "-50.15537923",
    "Distance": "39.0930414386239",
    "Mag": 4.01,
    "ColorIndex": 1.08
  },
  {
    "BayerFlamsteed": " 78Iot Leo",
    "ProperName": " ",
    "RA": "11.39871355",
    "Dec": "+10.52969772",
    "Distance": "24.2365487154629",
    "Mag": 4,
    "ColorIndex": 0.423
  },
  {
    "BayerFlamsteed": " 14Nu  Sco",
    "ProperName": " ",
    "RA": "16.19992791",
    "Dec": "-19.46064684",
    "Distance": "133.868808567604",
    "Mag": 4,
    "ColorIndex": 0.076
  },
  {
    "BayerFlamsteed": "   Alp Vol",
    "ProperName": " ",
    "RA": "9.04077745",
    "Dec": "-66.39584369",
    "Distance": "38.109756097561",
    "Mag": 4,
    "ColorIndex": 0.145
  },
  {
    "BayerFlamsteed": " 80    UMa",
    "ProperName": " ",
    "RA": "13.42039322",
    "Dec": "+54.98799884",
    "Distance": "24.8818113958696",
    "Mag": 3.99,
    "ColorIndex": 0.169
  },
  {
    "BayerFlamsteed": " 59Ups Cet",
    "ProperName": " ",
    "RA": "2.00006244",
    "Dec": "-21.07777193",
    "Distance": "92.2509225092251",
    "Mag": 3.99,
    "ColorIndex": 1.554
  },
  {
    "BayerFlamsteed": "  5Gam Mon",
    "ProperName": " ",
    "RA": "6.24759346",
    "Dec": "-06.27472737",
    "Distance": "197.628458498024",
    "Mag": 3.99,
    "ColorIndex": 1.319
  },
  {
    "BayerFlamsteed": "   Gam Tuc",
    "ProperName": " ",
    "RA": "23.29050334",
    "Dec": "-58.23592762",
    "Distance": "22.0264317180617",
    "Mag": 3.99,
    "ColorIndex": 0.41
  },
  {
    "BayerFlamsteed": " 73Rho Cyg",
    "ProperName": " ",
    "RA": "21.56635357",
    "Dec": "+45.59206564",
    "Distance": "38.1679389312977",
    "Mag": 3.98,
    "ColorIndex": 0.885
  },
  {
    "BayerFlamsteed": " 46Xi  Per",
    "ProperName": " ",
    "RA": "3.98274992",
    "Dec": "+35.79102701",
    "Distance": "543.478260869565",
    "Mag": 3.98,
    "ColorIndex": 0.016
  },
  {
    "BayerFlamsteed": " 47Lam Peg",
    "ProperName": " ",
    "RA": "22.77551177",
    "Dec": "+23.56567939",
    "Distance": "121.065375302663",
    "Mag": 3.97,
    "ColorIndex": 1.07
  },
  {
    "BayerFlamsteed": " 43    Eri",
    "ProperName": " ",
    "RA": "4.40060148",
    "Dec": "-34.01698632",
    "Distance": "83.6820083682008",
    "Mag": 3.97,
    "ColorIndex": 1.468
  },
  {
    "BayerFlamsteed": " 32Nu  Aur",
    "ProperName": " ",
    "RA": "5.85816462",
    "Dec": "+39.14847936",
    "Distance": "65.9195781147001",
    "Mag": 3.97,
    "ColorIndex": 1.132
  },
  {
    "BayerFlamsteed": "  5Chi Lup",
    "ProperName": " ",
    "RA": "15.84931715",
    "Dec": "-33.62710488",
    "Distance": "63.0517023959647",
    "Mag": 3.97,
    "ColorIndex": -0.045
  },
  {
    "BayerFlamsteed": "   Rho Cen",
    "ProperName": " ",
    "RA": "12.19420872",
    "Dec": "-52.36841559",
    "Distance": "104.931794333683",
    "Mag": 3.97,
    "ColorIndex": -0.156
  },
  {
    "BayerFlamsteed": "   Eps Pav",
    "ProperName": " ",
    "RA": "20.00983148",
    "Dec": "-72.91018443",
    "Distance": "32.5414904002603",
    "Mag": 3.97,
    "ColorIndex": -0.032
  },
  {
    "BayerFlamsteed": "   Del1Gru",
    "ProperName": " ",
    "RA": "22.48782057",
    "Dec": "-43.49555433",
    "Distance": "90.6618313689937",
    "Mag": 3.97,
    "ColorIndex": 1.022
  },
  {
    "BayerFlamsteed": "   Del Vol",
    "ProperName": " ",
    "RA": "7.28050856",
    "Dec": "-67.95717248",
    "Distance": "202.429149797571",
    "Mag": 3.97,
    "ColorIndex": 0.76
  },
  {
    "BayerFlamsteed": "   Bet Pyx",
    "ProperName": " ",
    "RA": "8.66837126",
    "Dec": "-35.30830091",
    "Distance": "119.047619047619",
    "Mag": 3.97,
    "ColorIndex": 0.936
  },
  {
    "BayerFlamsteed": " 98    Aqr",
    "ProperName": " ",
    "RA": "23.38286152",
    "Dec": "-20.10034505",
    "Distance": "49.6524329692155",
    "Mag": 3.96,
    "ColorIndex": 1.082
  },
  {
    "BayerFlamsteed": " 48    Per",
    "ProperName": " ",
    "RA": "4.14435368",
    "Dec": "+47.71259359",
    "Distance": "169.779286926995",
    "Mag": 3.96,
    "ColorIndex": -0.025
  },
  {
    "BayerFlamsteed": " 32    Cyg",
    "ProperName": " ",
    "RA": "20.25786618",
    "Dec": "+47.71420530",
    "Distance": "340.136054421769",
    "Mag": 3.96,
    "ColorIndex": 1.451
  },
  {
    "BayerFlamsteed": "   Eta Col",
    "ProperName": " ",
    "RA": "5.98577514",
    "Dec": "-42.81510761",
    "Distance": "162.866449511401",
    "Mag": 3.96,
    "ColorIndex": 1.146
  },
  {
    "BayerFlamsteed": "   Bet1Sgr",
    "ProperName": " ",
    "RA": "19.37730181",
    "Dec": "-44.45891013",
    "Distance": "116.009280742459",
    "Mag": 3.96,
    "ColorIndex": -0.085
  },
  {
    "BayerFlamsteed": "   Alp Sgr",
    "ProperName": " ",
    "RA": "19.3980976",
    "Dec": "-40.61564629",
    "Distance": "52.0833333333333",
    "Mag": 3.96,
    "ColorIndex": -0.105
  },
  {
    "BayerFlamsteed": " 50    Cas",
    "ProperName": " ",
    "RA": "2.05727509",
    "Dec": "+72.42123962",
    "Distance": "49.7017892644135",
    "Mag": 3.95,
    "ColorIndex": -0.002
  },
  {
    "BayerFlamsteed": "  7Nu 2CMa",
    "ProperName": " ",
    "RA": "6.61138858",
    "Dec": "-19.25570928",
    "Distance": "19.8373338623289",
    "Mag": 3.95,
    "ColorIndex": 1.037
  },
  {
    "BayerFlamsteed": " 58Nu  Cyg",
    "ProperName": " ",
    "RA": "20.95289212",
    "Dec": "+41.16719384",
    "Distance": "109.051254089422",
    "Mag": 3.94,
    "ColorIndex": 0.027
  },
  {
    "BayerFlamsteed": " 47Del Cnc",
    "ProperName": " ",
    "RA": "8.7447528",
    "Dec": "+18.15486399",
    "Distance": "41.7188151856487",
    "Mag": 3.94,
    "ColorIndex": 1.083
  },
  {
    "BayerFlamsteed": " 26Alp Mon",
    "ProperName": " ",
    "RA": "7.68746574",
    "Dec": "-09.55108315",
    "Distance": "44.2282176028306",
    "Mag": 3.94,
    "ColorIndex": 1.022
  },
  {
    "BayerFlamsteed": "  3    Pup",
    "ProperName": " ",
    "RA": "7.73013114",
    "Dec": "-28.95483520",
    "Distance": "10000000",
    "Mag": 3.94,
    "ColorIndex": 0.16
  },
  {
    "BayerFlamsteed": "   Zet Phe",
    "ProperName": " ",
    "RA": "1.13973891",
    "Dec": "-55.24583235",
    "Distance": "85.7632933104631",
    "Mag": 3.94,
    "ColorIndex": -0.12
  },
  {
    "BayerFlamsteed": " 67    Oph",
    "ProperName": " ",
    "RA": "18.01075431",
    "Dec": "+02.93158759",
    "Distance": "434.782608695652",
    "Mag": 3.93,
    "ColorIndex": 0.029
  },
  {
    "BayerFlamsteed": " 48Nu  Eri",
    "ProperName": " ",
    "RA": "4.60531682",
    "Dec": "-03.35244800",
    "Distance": "179.856115107914",
    "Mag": 3.93,
    "ColorIndex": -0.21
  },
  {
    "BayerFlamsteed": " 18Tau Per",
    "ProperName": " ",
    "RA": "2.90429513",
    "Dec": "+52.76248991",
    "Distance": "76.0456273764259",
    "Mag": 3.93,
    "ColorIndex": 0.758
  },
  {
    "BayerFlamsteed": "  9Ome1Sco",
    "ProperName": " ",
    "RA": "16.11345346",
    "Dec": "-20.66913479",
    "Distance": "129.87012987013",
    "Mag": 3.93,
    "ColorIndex": -0.046
  },
  {
    "BayerFlamsteed": "   Zet Vol",
    "ProperName": " ",
    "RA": "7.69699889",
    "Dec": "-72.60613528",
    "Distance": "41.0509031198686",
    "Mag": 3.93,
    "ColorIndex": 1.033
  },
  {
    "BayerFlamsteed": "   Kap Phe",
    "ProperName": " ",
    "RA": "0.43669894",
    "Dec": "-43.67990933",
    "Distance": "23.5238767348859",
    "Mag": 3.93,
    "ColorIndex": 0.175
  },
  {
    "BayerFlamsteed": "   Del Phe",
    "ProperName": " ",
    "RA": "1.5208282",
    "Dec": "-49.07307701",
    "Distance": "45.1467268623025",
    "Mag": 3.93,
    "ColorIndex": 0.972
  },
  {
    "BayerFlamsteed": " 58Eps Her",
    "ProperName": " ",
    "RA": "17.00483505",
    "Dec": "+30.92633926",
    "Distance": "49.9001996007984",
    "Mag": 3.92,
    "ColorIndex": -0.018
  },
  {
    "BayerFlamsteed": " 44Rho1Sgr",
    "ProperName": " ",
    "RA": "19.36121535",
    "Dec": "-17.84725155",
    "Distance": "37.3273609555804",
    "Mag": 3.92,
    "ColorIndex": 0.228
  },
  {
    "BayerFlamsteed": "  8Alp Equ",
    "ProperName": " ",
    "RA": "21.26372131",
    "Dec": "+05.24807390",
    "Distance": "57.1102227298686",
    "Mag": 3.92,
    "ColorIndex": 0.549
  },
  {
    "BayerFlamsteed": " 38Nu  Tau",
    "ProperName": " ",
    "RA": "4.05260466",
    "Dec": "+05.98930909",
    "Distance": "39.6196513470681",
    "Mag": 3.91,
    "ColorIndex": 0.032
  },
  {
    "BayerFlamsteed": " 38Gam Lib",
    "ProperName": " ",
    "RA": "15.59209426",
    "Dec": "-14.78955365",
    "Distance": "46.6853408029879",
    "Mag": 3.91,
    "ColorIndex": 1.007
  },
  {
    "BayerFlamsteed": " 22Tau Her",
    "ProperName": " ",
    "RA": "16.3290133",
    "Dec": "+46.31327084",
    "Distance": "96.4320154291225",
    "Mag": 3.91,
    "ColorIndex": -0.151
  },
  {
    "BayerFlamsteed": "   Sig Cen",
    "ProperName": " ",
    "RA": "12.46733655",
    "Dec": "-50.23060480",
    "Distance": "135.869565217391",
    "Mag": 3.91,
    "ColorIndex": -0.192
  },
  {
    "BayerFlamsteed": "   Pi  Lup",
    "ProperName": " ",
    "RA": "15.08530713",
    "Dec": "-47.05119386",
    "Distance": "152.439024390244",
    "Mag": 3.91,
    "ColorIndex": -0.144
  },
  {
    "BayerFlamsteed": " 35Iot Hya",
    "ProperName": " ",
    "RA": "9.66425943",
    "Dec": "-01.14265722",
    "Distance": "84.530853761623",
    "Mag": 3.9,
    "ColorIndex": 1.313
  },
  {
    "BayerFlamsteed": "   Pi  Cen",
    "ProperName": " ",
    "RA": "11.35012284",
    "Dec": "-54.49101395",
    "Distance": "98.5221674876847",
    "Mag": 3.9,
    "ColorIndex": -0.157
  },
  {
    "BayerFlamsteed": " 22The Hya",
    "ProperName": " ",
    "RA": "9.23938701",
    "Dec": "+02.31502422",
    "Distance": "39.4632991318074",
    "Mag": 3.89,
    "ColorIndex": -0.06
  },
  {
    "BayerFlamsteed": " 21Eta Cyg",
    "ProperName": " ",
    "RA": "19.93844337",
    "Dec": "+35.08349079",
    "Distance": "42.7350427350427",
    "Mag": 3.89,
    "ColorIndex": 1.019
  },
  {
    "BayerFlamsteed": " 16Omi1CMa",
    "ProperName": " ",
    "RA": "6.90220967",
    "Dec": "-24.18422296",
    "Distance": "606.060606060606",
    "Mag": 3.89,
    "ColorIndex": 1.74
  },
  {
    "BayerFlamsteed": " 15Eta Vir",
    "ProperName": " ",
    "RA": "12.33177539",
    "Dec": "-00.66674709",
    "Distance": "76.5696784073507",
    "Mag": 3.89,
    "ColorIndex": 0.026
  },
  {
    "BayerFlamsteed": "  3Eta Eri",
    "ProperName": " ",
    "RA": "2.94044549",
    "Dec": "-08.89760976",
    "Distance": "40.8329930583912",
    "Mag": 3.89,
    "ColorIndex": 1.088
  },
  {
    "BayerFlamsteed": " 24Mu  Leo",
    "ProperName": " ",
    "RA": "9.87943268",
    "Dec": "+26.00708498",
    "Distance": "40.7830342577488",
    "Mag": 3.88,
    "ColorIndex": 1.222
  },
  {
    "BayerFlamsteed": "  5Gam2Ari",
    "ProperName": " ",
    "RA": "1.892157",
    "Dec": "+19.29409264",
    "Distance": "62.65664160401",
    "Mag": 3.88,
    "ColorIndex": -0.047
  },
  {
    "BayerFlamsteed": "   Kap1Lup",
    "ProperName": " ",
    "RA": "15.19893362",
    "Dec": "-48.73770212",
    "Distance": "55.8971492453885",
    "Mag": 3.88,
    "ColorIndex": -0.029
  },
  {
    "BayerFlamsteed": "   Iot Gru",
    "ProperName": " ",
    "RA": "23.17261887",
    "Dec": "-45.24664747",
    "Distance": "56.7214974475326",
    "Mag": 3.88,
    "ColorIndex": 0.998
  },
  {
    "BayerFlamsteed": "   Eps Phe",
    "ProperName": " ",
    "RA": "0.15681663",
    "Dec": "-45.74698836",
    "Distance": "42.9553264604811",
    "Mag": 3.88,
    "ColorIndex": 1.013
  },
  {
    "BayerFlamsteed": "107Mu  Vir",
    "ProperName": " ",
    "RA": "14.71765618",
    "Dec": "-05.65742910",
    "Distance": "18.677624206201",
    "Mag": 3.87,
    "ColorIndex": 0.385
  },
  {
    "BayerFlamsteed": " 55Eta Aql",
    "ProperName": " ",
    "RA": "19.8745455",
    "Dec": "+01.00567827",
    "Distance": "359.712230215827",
    "Mag": 3.87,
    "ColorIndex": 0.63
  },
  {
    "BayerFlamsteed": " 20    Tau",
    "ProperName": " ",
    "RA": "3.76377586",
    "Dec": "+24.36785796",
    "Distance": "110.37527593819",
    "Mag": 3.87,
    "ColorIndex": -0.063
  },
  {
    "BayerFlamsteed": "  5Rho Sco",
    "ProperName": " ",
    "RA": "15.94807962",
    "Dec": "-29.21401221",
    "Distance": "125.470514429109",
    "Mag": 3.87,
    "ColorIndex": -0.199
  },
  {
    "BayerFlamsteed": "   Ups1Cen",
    "ProperName": " ",
    "RA": "13.97799204",
    "Dec": "-44.80353140",
    "Distance": "128.040973111396",
    "Mag": 3.87,
    "ColorIndex": -0.208
  },
  {
    "BayerFlamsteed": " 91The Her",
    "ProperName": " ",
    "RA": "17.93754959",
    "Dec": "+37.25052158",
    "Distance": "205.338809034908",
    "Mag": 3.86,
    "ColorIndex": 1.35
  },
  {
    "BayerFlamsteed": " 53    Eri",
    "ProperName": " ",
    "RA": "4.63635303",
    "Dec": "-14.30358700",
    "Distance": "33.5120643431635",
    "Mag": 3.86,
    "ColorIndex": 1.082
  },
  {
    "BayerFlamsteed": " 48Gam Aqr",
    "ProperName": " ",
    "RA": "22.36091665",
    "Dec": "-01.38735315",
    "Distance": "48.3792936623125",
    "Mag": 3.86,
    "ColorIndex": -0.057
  },
  {
    "BayerFlamsteed": " 37Mu  And",
    "ProperName": " ",
    "RA": "0.94586046",
    "Dec": "+38.49925513",
    "Distance": "41.7885499373172",
    "Mag": 3.86,
    "ColorIndex": 0.13
  },
  {
    "BayerFlamsteed": "   Gam Aps",
    "ProperName": " ",
    "RA": "16.55762893",
    "Dec": "-78.89695917",
    "Distance": "48.9236790606654",
    "Mag": 3.86,
    "ColorIndex": 0.923
  },
  {
    "BayerFlamsteed": "   Eps Col",
    "ProperName": " ",
    "RA": "5.5202043",
    "Dec": "-35.47043592",
    "Distance": "84.9617672047579",
    "Mag": 3.86,
    "ColorIndex": 1.13
  },
  {
    "BayerFlamsteed": "   Del TrA",
    "ProperName": " ",
    "RA": "16.25729615",
    "Dec": "-63.68564897",
    "Distance": "190.47619047619",
    "Mag": 3.86,
    "ColorIndex": 1.105
  },
  {
    "BayerFlamsteed": "109    Her",
    "ProperName": " ",
    "RA": "18.39493521",
    "Dec": "+21.77034249",
    "Distance": "39.3700787401575",
    "Mag": 3.85,
    "ColorIndex": 1.168
  },
  {
    "BayerFlamsteed": " 41Gam Ser",
    "ProperName": " ",
    "RA": "15.94083173",
    "Dec": "+15.66473327",
    "Distance": "11.1209964412811",
    "Mag": 3.85,
    "ColorIndex": 0.478
  },
  {
    "BayerFlamsteed": "  5Kap Dra",
    "ProperName": " ",
    "RA": "12.55806736",
    "Dec": "+69.78820992",
    "Distance": "152.671755725191",
    "Mag": 3.85,
    "ColorIndex": -0.116
  },
  {
    "BayerFlamsteed": "   Tau Cen",
    "ProperName": " ",
    "RA": "12.62842439",
    "Dec": "-48.54128816",
    "Distance": "40.371417036738",
    "Mag": 3.85,
    "ColorIndex": 0.049
  },
  {
    "BayerFlamsteed": "   Del Col",
    "ProperName": " ",
    "RA": "6.36856809",
    "Dec": "-33.43627251",
    "Distance": "72.7272727272727",
    "Mag": 3.85,
    "ColorIndex": 0.858
  },
  {
    "BayerFlamsteed": "   Bet Pic",
    "ProperName": " ",
    "RA": "5.7880787",
    "Dec": "-51.06671329",
    "Distance": "19.2789666473877",
    "Mag": 3.85,
    "ColorIndex": 0.171
  },
  {
    "BayerFlamsteed": "   Alp Sct",
    "ProperName": " ",
    "RA": "18.58678829",
    "Dec": "-08.24330819",
    "Distance": "53.4188034188034",
    "Mag": 3.85,
    "ColorIndex": 1.317
  },
  {
    "BayerFlamsteed": "   Alp Hor",
    "ProperName": " ",
    "RA": "4.23335592",
    "Dec": "-42.29387294",
    "Distance": "35.9066427289048",
    "Mag": 3.85,
    "ColorIndex": 1.085
  },
  {
    "BayerFlamsteed": "103Omi Her",
    "ProperName": " ",
    "RA": "18.12570854",
    "Dec": "+28.76247025",
    "Distance": "106.496272630458",
    "Mag": 3.84,
    "ColorIndex": -0.018
  },
  {
    "BayerFlamsteed": " 77The1Tau",
    "ProperName": " ",
    "RA": "4.4762312",
    "Dec": "+15.96221721",
    "Distance": "48.4027105517909",
    "Mag": 3.84,
    "ColorIndex": 0.952
  },
  {
    "BayerFlamsteed": " 63Eps Dra",
    "ProperName": " ",
    "RA": "19.8028371",
    "Dec": "+70.26783533",
    "Distance": "44.6428571428571",
    "Mag": 3.84,
    "ColorIndex": 0.888
  },
  {
    "BayerFlamsteed": " 47Rho Leo",
    "ProperName": " ",
    "RA": "10.5468542",
    "Dec": "+09.30659431",
    "Distance": "1754.38596491228",
    "Mag": 3.84,
    "ColorIndex": -0.148
  },
  {
    "BayerFlamsteed": " 38Omi Per",
    "ProperName": " ",
    "RA": "3.73864623",
    "Dec": "+32.28827325",
    "Distance": "452.488687782805",
    "Mag": 3.84,
    "ColorIndex": 0.022
  },
  {
    "BayerFlamsteed": " 13Mu  Sgr",
    "ProperName": " ",
    "RA": "18.2293913",
    "Dec": "-21.05883031",
    "Distance": "9090.90909090909",
    "Mag": 3.84,
    "ColorIndex": 0.195
  },
  {
    "BayerFlamsteed": "   Gam Mus",
    "ProperName": " ",
    "RA": "12.54114193",
    "Dec": "-72.13297590",
    "Distance": "99.304865938431",
    "Mag": 3.84,
    "ColorIndex": -0.157
  },
  {
    "BayerFlamsteed": "   Bet Ret",
    "ProperName": " ",
    "RA": "3.73654302",
    "Dec": "-64.80709398",
    "Distance": "30.571690614491",
    "Mag": 3.84,
    "ColorIndex": 1.133
  },
  {
    "BayerFlamsteed": " 42Mu  Hya",
    "ProperName": " ",
    "RA": "10.43486251",
    "Dec": "-16.83609584",
    "Distance": "76.103500761035",
    "Mag": 3.83,
    "ColorIndex": 1.456
  },
  {
    "BayerFlamsteed": "   Phi Cen",
    "ProperName": " ",
    "RA": "13.97119018",
    "Dec": "-42.10070526",
    "Distance": "142.65335235378",
    "Mag": 3.83,
    "ColorIndex": -0.224
  },
  {
    "BayerFlamsteed": "   Alp Aps",
    "ProperName": " ",
    "RA": "14.79770171",
    "Dec": "-79.04471242",
    "Distance": "126.103404791929",
    "Mag": 3.83,
    "ColorIndex": 1.433
  },
  {
    "BayerFlamsteed": "113Alp Psc",
    "ProperName": " ",
    "RA": "2.03411128",
    "Dec": "+02.76376048",
    "Distance": "42.6439232409382",
    "Mag": 3.82,
    "ColorIndex": 0.024
  },
  {
    "BayerFlamsteed": " 85Iot Her",
    "ProperName": " ",
    "RA": "17.65774789",
    "Dec": "+46.00632216",
    "Distance": "151.975683890577",
    "Mag": 3.82,
    "ColorIndex": -0.179
  },
  {
    "BayerFlamsteed": " 38    Lyn",
    "ProperName": " ",
    "RA": "9.31407426",
    "Dec": "+36.80289763",
    "Distance": "37.3831775700935",
    "Mag": 3.82,
    "ColorIndex": 0.066
  },
  {
    "BayerFlamsteed": " 10Lam Oph",
    "ProperName": " ",
    "RA": "16.51523367",
    "Dec": "+01.98410056",
    "Distance": "50.9424350483953",
    "Mag": 3.82,
    "ColorIndex": 0.022
  },
  {
    "BayerFlamsteed": "  1Lam Dra",
    "ProperName": " ",
    "RA": "11.52341346",
    "Dec": "+69.33112161",
    "Distance": "102.459016393443",
    "Mag": 3.82,
    "ColorIndex": 1.613
  },
  {
    "BayerFlamsteed": " 52Ups2Eri",
    "ProperName": " ",
    "RA": "4.59251991",
    "Dec": "-30.56231049",
    "Distance": "64.0204865556978",
    "Mag": 3.81,
    "ColorIndex": 0.957
  },
  {
    "BayerFlamsteed": " 16Lam And",
    "ProperName": " ",
    "RA": "23.62602991",
    "Dec": "+46.45917621",
    "Distance": "25.8131130614352",
    "Mag": 3.81,
    "ColorIndex": 0.984
  },
  {
    "BayerFlamsteed": "  8Gam CrB",
    "ProperName": " ",
    "RA": "15.71239951",
    "Dec": "+26.29551419",
    "Distance": "44.4839857651246",
    "Mag": 3.81,
    "ColorIndex": 0.02
  },
  {
    "BayerFlamsteed": " 31    Cyg",
    "ProperName": " ",
    "RA": "20.22719518",
    "Dec": "+46.74132417",
    "Distance": "414.9377593361",
    "Mag": 3.8,
    "ColorIndex": 1.27
  },
  {
    "BayerFlamsteed": " 13Del Ser",
    "ProperName": " ",
    "RA": "15.58005284",
    "Dec": "+10.53885916",
    "Distance": "64.3500643500644",
    "Mag": 3.8,
    "ColorIndex": 0.268
  },
  {
    "BayerFlamsteed": "  1Kap Cyg",
    "ProperName": " ",
    "RA": "19.28503052",
    "Dec": "+53.36816064",
    "Distance": "37.7643504531722",
    "Mag": 3.8,
    "ColorIndex": 0.95
  },
  {
    "BayerFlamsteed": "   Alp For",
    "ProperName": " ",
    "RA": "3.20118888",
    "Dec": "-28.98910623",
    "Distance": "14.1123341800734",
    "Mag": 3.8,
    "ColorIndex": 0.543
  },
  {
    "BayerFlamsteed": " 46    LMi",
    "ProperName": " ",
    "RA": "10.88851107",
    "Dec": "+34.21556641",
    "Distance": "29.940119760479",
    "Mag": 3.79,
    "ColorIndex": 1.04
  },
  {
    "BayerFlamsteed": " 27Kap Per",
    "ProperName": " ",
    "RA": "3.1582303",
    "Dec": "+44.85788896",
    "Distance": "34.4234079173838",
    "Mag": 3.79,
    "ColorIndex": 0.98
  },
  {
    "BayerFlamsteed": " 60Iot Gem",
    "ProperName": " ",
    "RA": "7.42879891",
    "Dec": "+27.79828561",
    "Distance": "38.6100386100386",
    "Mag": 3.78,
    "ColorIndex": 1.024
  },
  {
    "BayerFlamsteed": " 30Zet Boo",
    "ProperName": " ",
    "RA": "14.68581121",
    "Dec": "+13.72833113",
    "Distance": "55.3403431101273",
    "Mag": 3.78,
    "ColorIndex": 0.044
  },
  {
    "BayerFlamsteed": " 29Ups UMa",
    "ProperName": " ",
    "RA": "9.84991436",
    "Dec": "+59.03910437",
    "Distance": "35.2733686067019",
    "Mag": 3.78,
    "ColorIndex": 0.291
  },
  {
    "BayerFlamsteed": "  2Eps Aqr",
    "ProperName": " ",
    "RA": "20.79459238",
    "Dec": "-09.49568988",
    "Distance": "70.3729767769177",
    "Mag": 3.78,
    "ColorIndex": 0
  },
  {
    "BayerFlamsteed": "   Gam2Vol",
    "ProperName": " ",
    "RA": "7.14578458",
    "Dec": "-70.49919435",
    "Distance": "43.4404865334492",
    "Mag": 3.78,
    "ColorIndex": 1.006
  },
  {
    "BayerFlamsteed": " 61Del1Tau",
    "ProperName": " ",
    "RA": "4.38222981",
    "Dec": "+17.54258430",
    "Distance": "46.9704086425552",
    "Mag": 3.77,
    "ColorIndex": 0.983
  },
  {
    "BayerFlamsteed": " 48Sig Ori",
    "ProperName": " ",
    "RA": "5.64576814",
    "Dec": "-02.60006791",
    "Distance": "352.112676056338",
    "Mag": 3.77,
    "ColorIndex": -0.19
  },
  {
    "BayerFlamsteed": " 41Nu  Per",
    "ProperName": " ",
    "RA": "3.75323428",
    "Dec": "+42.57854437",
    "Distance": "170.648464163823",
    "Mag": 3.77,
    "ColorIndex": 0.425
  },
  {
    "BayerFlamsteed": " 34Zet Cap",
    "ProperName": " ",
    "RA": "21.44445214",
    "Dec": "-22.41137838",
    "Distance": "122.100122100122",
    "Mag": 3.77,
    "ColorIndex": 1.002
  },
  {
    "BayerFlamsteed": " 24Iot Peg",
    "ProperName": " ",
    "RA": "22.11679848",
    "Dec": "+25.34504610",
    "Distance": "11.7564072419469",
    "Mag": 3.77,
    "ColorIndex": 0.435
  },
  {
    "BayerFlamsteed": " 15Eta Per",
    "ProperName": " ",
    "RA": "2.84494243",
    "Dec": "+55.89552955",
    "Distance": "408.163265306122",
    "Mag": 3.77,
    "ColorIndex": 1.69
  },
  {
    "BayerFlamsteed": "  9Alp Del",
    "ProperName": " ",
    "RA": "20.66062626",
    "Dec": "+15.91205270",
    "Distance": "73.8007380073801",
    "Mag": 3.77,
    "ColorIndex": -0.057
  },
  {
    "BayerFlamsteed": "   Eta Ara",
    "ProperName": " ",
    "RA": "16.82975317",
    "Dec": "-59.04131648",
    "Distance": "96.0614793467819",
    "Mag": 3.77,
    "ColorIndex": 1.562
  },
  {
    "BayerFlamsteed": "   Bet Vol",
    "ProperName": " ",
    "RA": "8.42895729",
    "Dec": "-66.13652042",
    "Distance": "33.101621979477",
    "Mag": 3.77,
    "ColorIndex": 1.132
  },
  {
    "BayerFlamsteed": " 39Omi Sgr",
    "ProperName": " ",
    "RA": "19.07803717",
    "Dec": "-21.74135451",
    "Distance": "42.571306939123",
    "Mag": 3.76,
    "ColorIndex": 1.012
  },
  {
    "BayerFlamsteed": " 15Del Lep",
    "ProperName": " ",
    "RA": "5.85532022",
    "Dec": "-20.87751376",
    "Distance": "34.4234079173838",
    "Mag": 3.76,
    "ColorIndex": 0.984
  },
  {
    "BayerFlamsteed": " 11Bet Mon",
    "ProperName": " ",
    "RA": "6.48029836",
    "Dec": "-07.03305042",
    "Distance": "211.864406779661",
    "Mag": 3.76,
    "ColorIndex": -0.113
  },
  {
    "BayerFlamsteed": " 10Iot2Cyg",
    "ProperName": " ",
    "RA": "19.49509425",
    "Dec": "+51.72946747",
    "Distance": "37.5516334960571",
    "Mag": 3.76,
    "ColorIndex": 0.148
  },
  {
    "BayerFlamsteed": "  7Alp Lac",
    "ProperName": " ",
    "RA": "22.52149326",
    "Dec": "+50.28244976",
    "Distance": "31.3873195229127",
    "Mag": 3.76,
    "ColorIndex": 0.031
  },
  {
    "BayerFlamsteed": "   Bet Dor",
    "ProperName": " ",
    "RA": "5.5604212",
    "Dec": "-62.48985585",
    "Distance": "318.471337579618",
    "Mag": 3.76,
    "ColorIndex": 0.64
  },
  {
    "BayerFlamsteed": " 62Gam Oph",
    "ProperName": " ",
    "RA": "17.79821501",
    "Dec": "+02.70745875",
    "Distance": "29.0528762347472",
    "Mag": 3.75,
    "ColorIndex": 0.043
  },
  {
    "BayerFlamsteed": " 65Tau Cyg",
    "ProperName": " ",
    "RA": "21.24648517",
    "Dec": "+38.04432043",
    "Distance": "20.9205020920502",
    "Mag": 3.74,
    "ColorIndex": 0.393
  },
  {
    "BayerFlamsteed": " 55Zet Cet",
    "ProperName": " ",
    "RA": "1.85766961",
    "Dec": "-10.33494526",
    "Distance": "79.428117553614",
    "Mag": 3.74,
    "ColorIndex": 1.136
  },
  {
    "BayerFlamsteed": " 20Gam Her",
    "ProperName": " ",
    "RA": "16.36534546",
    "Dec": "+19.15302185",
    "Distance": "59.9161174355902",
    "Mag": 3.74,
    "ColorIndex": 0.299
  },
  {
    "BayerFlamsteed": "109    Vir",
    "ProperName": " ",
    "RA": "14.77083106",
    "Dec": "+01.89293830",
    "Distance": "39.4477317554241",
    "Mag": 3.73,
    "ColorIndex": -0.005
  },
  {
    "BayerFlamsteed": " 73Lam Aqr",
    "ProperName": " ",
    "RA": "22.87690679",
    "Dec": "-07.57967878",
    "Distance": "120.048019207683",
    "Mag": 3.73,
    "ColorIndex": 1.626
  },
  {
    "BayerFlamsteed": " 32Xi  Dra",
    "ProperName": " ",
    "RA": "17.8921193",
    "Dec": "+56.87245216",
    "Distance": "34.1763499658236",
    "Mag": 3.73,
    "ColorIndex": 1.177
  },
  {
    "BayerFlamsteed": "  2Xi  Tau",
    "ProperName": " ",
    "RA": "3.45281136",
    "Dec": "+09.73277240",
    "Distance": "68.1198910081744",
    "Mag": 3.73,
    "ColorIndex": -0.082
  },
  {
    "BayerFlamsteed": "   Nu  Oct",
    "ProperName": " ",
    "RA": "21.69124253",
    "Dec": "-77.38946215",
    "Distance": "21.1774671749259",
    "Mag": 3.73,
    "ColorIndex": 1.008
  },
  {
    "BayerFlamsteed": " 62Xi  Cyg",
    "ProperName": " ",
    "RA": "21.08218216",
    "Dec": "+43.92785122",
    "Distance": "361.01083032491",
    "Mag": 3.72,
    "ColorIndex": 1.609
  },
  {
    "BayerFlamsteed": " 33Del Aur",
    "ProperName": " ",
    "RA": "5.99209731",
    "Dec": "+54.28498197",
    "Distance": "43.0663221360896",
    "Mag": 3.72,
    "ColorIndex": 1.01
  },
  {
    "BayerFlamsteed": " 18Eps Eri",
    "ProperName": " ",
    "RA": "3.549006",
    "Dec": "-09.45830584",
    "Distance": "3.21802091713596",
    "Mag": 3.72,
    "ColorIndex": 0.881
  },
  {
    "BayerFlamsteed": " 17    Tau",
    "ProperName": " ",
    "RA": "3.7479232",
    "Dec": "+24.11344840",
    "Distance": "113.636363636364",
    "Mag": 3.72,
    "ColorIndex": -0.105
  },
  {
    "BayerFlamsteed": " 72    Oph",
    "ProperName": " ",
    "RA": "18.12250574",
    "Dec": "+09.56365345",
    "Distance": "25.3807106598985",
    "Mag": 3.71,
    "ColorIndex": 0.159
  },
  {
    "BayerFlamsteed": " 60Bet Aql",
    "ProperName": " ",
    "RA": "19.92187948",
    "Dec": "+06.40793334",
    "Distance": "13.7080191912269",
    "Mag": 3.71,
    "ColorIndex": 0.855
  },
  {
    "BayerFlamsteed": " 37Eps Ser",
    "ProperName": " ",
    "RA": "15.84691422",
    "Dec": "+04.47757980",
    "Distance": "21.5563699073076",
    "Mag": 3.71,
    "ColorIndex": 0.147
  },
  {
    "BayerFlamsteed": " 16Eta Lep",
    "ProperName": " ",
    "RA": "5.94008841",
    "Dec": "-14.16803805",
    "Distance": "15.044380923725",
    "Mag": 3.71,
    "ColorIndex": 0.337
  },
  {
    "BayerFlamsteed": "  8Pi 5Ori",
    "ProperName": " ",
    "RA": "4.90419323",
    "Dec": "+02.44067149",
    "Distance": "411.522633744856",
    "Mag": 3.71,
    "ColorIndex": -0.179
  },
  {
    "BayerFlamsteed": " 92Xi  Her",
    "ProperName": " ",
    "RA": "17.96273073",
    "Dec": "+29.24792527",
    "Distance": "41.4593698175788",
    "Mag": 3.7,
    "ColorIndex": 0.935
  },
  {
    "BayerFlamsteed": " 16Tau4Eri",
    "ProperName": " ",
    "RA": "3.32526935",
    "Dec": "-21.75794210",
    "Distance": "79.1765637371338",
    "Mag": 3.7,
    "ColorIndex": 1.614
  },
  {
    "BayerFlamsteed": "  6Gam Psc",
    "ProperName": " ",
    "RA": "23.28597045",
    "Dec": "+03.28224524",
    "Distance": "40.1284109149278",
    "Mag": 3.7,
    "ColorIndex": 0.916
  },
  {
    "BayerFlamsteed": " 63Chi UMa",
    "ProperName": " ",
    "RA": "11.76753725",
    "Dec": "+47.77933701",
    "Distance": "60.0961538461538",
    "Mag": 3.69,
    "ColorIndex": 1.181
  },
  {
    "BayerFlamsteed": " 40Gam Cap",
    "ProperName": " ",
    "RA": "21.66815062",
    "Dec": "-16.66225343",
    "Distance": "42.5894378194208",
    "Mag": 3.69,
    "ColorIndex": 0.32
  },
  {
    "BayerFlamsteed": " 17Zet Cas",
    "ProperName": " ",
    "RA": "0.61618528",
    "Dec": "+53.89693161",
    "Distance": "183.150183150183",
    "Mag": 3.69,
    "ColorIndex": -0.196
  },
  {
    "BayerFlamsteed": "  8Zet Aur",
    "ProperName": " ",
    "RA": "5.04130001",
    "Dec": "+41.07588953",
    "Distance": "241.545893719807",
    "Mag": 3.69,
    "ColorIndex": 1.154
  },
  {
    "BayerFlamsteed": "   Chi Eri",
    "ProperName": " ",
    "RA": "1.93245347",
    "Dec": "-51.60958673",
    "Distance": "17.4855744011191",
    "Mag": 3.69,
    "ColorIndex": 0.844
  },
  {
    "BayerFlamsteed": " 88    Aqr",
    "ProperName": " ",
    "RA": "23.15743391",
    "Dec": "-21.17248555",
    "Distance": "71.6332378223496",
    "Mag": 3.68,
    "ColorIndex": 1.202
  },
  {
    "BayerFlamsteed": "  7Del Sge",
    "ProperName": " ",
    "RA": "19.78979589",
    "Dec": "+18.53425912",
    "Distance": "137.362637362637",
    "Mag": 3.68,
    "ColorIndex": 1.313
  },
  {
    "BayerFlamsteed": "  3Pi 4Ori",
    "ProperName": " ",
    "RA": "4.853435",
    "Dec": "+05.60510146",
    "Distance": "386.100386100386",
    "Mag": 3.68,
    "ColorIndex": -0.157
  },
  {
    "BayerFlamsteed": "   Alp Pyx",
    "ProperName": " ",
    "RA": "8.72654096",
    "Dec": "-33.18641133",
    "Distance": "259.067357512953",
    "Mag": 3.68,
    "ColorIndex": -0.18
  },
  {
    "BayerFlamsteed": " 11Alp Dra",
    "ProperName": " ",
    "RA": "14.07317389",
    "Dec": "+64.37580873",
    "Distance": "94.6969696969697",
    "Mag": 3.67,
    "ColorIndex": -0.049
  },
  {
    "BayerFlamsteed": "   Bet Ind",
    "ProperName": " ",
    "RA": "20.91349433",
    "Dec": "-58.45409470",
    "Distance": "184.842883548983",
    "Mag": 3.67,
    "ColorIndex": 1.25
  },
  {
    "BayerFlamsteed": " 40Tau Lib",
    "ProperName": " ",
    "RA": "15.64427343",
    "Dec": "-29.77768935",
    "Distance": "136.425648021828",
    "Mag": 3.66,
    "ColorIndex": -0.177
  },
  {
    "BayerFlamsteed": "  3Bet CrB",
    "ProperName": " ",
    "RA": "15.46384775",
    "Dec": "+29.10549164",
    "Distance": "34.965034965035",
    "Mag": 3.66,
    "ColorIndex": 0.319
  },
  {
    "BayerFlamsteed": " 55Zet1Aqr",
    "ProperName": " ",
    "RA": "22.48050015",
    "Dec": "-00.02006304",
    "Distance": "31.7158261972724",
    "Mag": 3.65,
    "ColorIndex": 0.406
  },
  {
    "BayerFlamsteed": " 54Gam Tau",
    "ProperName": " ",
    "RA": "4.32987052",
    "Dec": "+15.62770031",
    "Distance": "47.2366556447803",
    "Mag": 3.65,
    "ColorIndex": 0.981
  },
  {
    "BayerFlamsteed": " 28Bet Ser",
    "ProperName": " ",
    "RA": "15.76978191",
    "Dec": "+15.42192602",
    "Distance": "46.9263256687001",
    "Mag": 3.65,
    "ColorIndex": 0.073
  },
  {
    "BayerFlamsteed": " 23    UMa",
    "ProperName": " ",
    "RA": "9.52543601",
    "Dec": "+63.06179545",
    "Distance": "23.1481481481481",
    "Mag": 3.65,
    "ColorIndex": 0.36
  },
  {
    "BayerFlamsteed": "   The Ara",
    "ProperName": " ",
    "RA": "18.11052188",
    "Dec": "-50.09145462",
    "Distance": "310.55900621118",
    "Mag": 3.65,
    "ColorIndex": -0.101
  },
  {
    "BayerFlamsteed": "  6Bet Del",
    "ProperName": " ",
    "RA": "20.62579715",
    "Dec": "+14.59520289",
    "Distance": "29.8596595998806",
    "Mag": 3.64,
    "ColorIndex": 0.425
  },
  {
    "BayerFlamsteed": "   Lam Mus",
    "ProperName": " ",
    "RA": "11.76015759",
    "Dec": "-66.72884344",
    "Distance": "39.33910306845",
    "Mag": 3.63,
    "ColorIndex": 0.16
  },
  {
    "BayerFlamsteed": " 99Eta Psc",
    "ProperName": " ",
    "RA": "1.52472051",
    "Dec": "+15.34583101",
    "Distance": "90.1713255184851",
    "Mag": 3.62,
    "ColorIndex": 0.974
  },
  {
    "BayerFlamsteed": " 27    Tau",
    "ProperName": " ",
    "RA": "3.81936977",
    "Dec": "+24.05352412",
    "Distance": "116.686114352392",
    "Mag": 3.62,
    "ColorIndex": -0.07
  },
  {
    "BayerFlamsteed": "  1Omi And",
    "ProperName": " ",
    "RA": "23.03201291",
    "Dec": "+42.32597866",
    "Distance": "212.314225053079",
    "Mag": 3.62,
    "ColorIndex": -0.099
  },
  {
    "BayerFlamsteed": "   Zet2Sco",
    "ProperName": " ",
    "RA": "16.90975143",
    "Dec": "-42.36075916",
    "Distance": "46.1467466543609",
    "Mag": 3.62,
    "ColorIndex": 1.393
  },
  {
    "BayerFlamsteed": " 41Lam Hya",
    "ProperName": " ",
    "RA": "10.17649919",
    "Dec": "-12.35383921",
    "Distance": "35.1617440225035",
    "Mag": 3.61,
    "ColorIndex": 1.007
  },
  {
    "BayerFlamsteed": " 41    Ari",
    "ProperName": " ",
    "RA": "2.8330526",
    "Dec": "+27.26079044",
    "Distance": "48.8997555012225",
    "Mag": 3.61,
    "ColorIndex": -0.1
  },
  {
    "BayerFlamsteed": "  1Omi Tau",
    "ProperName": " ",
    "RA": "3.413566",
    "Dec": "+09.02906504",
    "Distance": "64.8508430609598",
    "Mag": 3.61,
    "ColorIndex": 0.887
  },
  {
    "BayerFlamsteed": "   Eta Pav",
    "ProperName": " ",
    "RA": "17.76222289",
    "Dec": "-64.72373450",
    "Distance": "113.765642775882",
    "Mag": 3.61,
    "ColorIndex": 1.161
  },
  {
    "BayerFlamsteed": "   Del Mus",
    "ProperName": " ",
    "RA": "13.03771597",
    "Dec": "-71.54879865",
    "Distance": "27.8473962684489",
    "Mag": 3.61,
    "ColorIndex": 1.19
  },
  {
    "BayerFlamsteed": " 45The Cet",
    "ProperName": " ",
    "RA": "1.40040311",
    "Dec": "-08.18275372",
    "Distance": "35.1123595505618",
    "Mag": 3.6,
    "ColorIndex": 1.065
  },
  {
    "BayerFlamsteed": " 39Ups Lib",
    "ProperName": " ",
    "RA": "15.61707183",
    "Dec": "-28.13507099",
    "Distance": "59.6658711217184",
    "Mag": 3.6,
    "ColorIndex": 1.361
  },
  {
    "BayerFlamsteed": " 34The Gem",
    "ProperName": " ",
    "RA": "6.87981668",
    "Dec": "+33.96136985",
    "Distance": "60.2772754671489",
    "Mag": 3.6,
    "ColorIndex": 0.102
  },
  {
    "BayerFlamsteed": "   Psi Vel",
    "ProperName": " ",
    "RA": "9.51169804",
    "Dec": "-40.46688763",
    "Distance": "18.5563184264242",
    "Mag": 3.6,
    "ColorIndex": 0.371
  },
  {
    "BayerFlamsteed": "   Del Ara",
    "ProperName": " ",
    "RA": "17.51832693",
    "Dec": "-60.68360667",
    "Distance": "57.4052812858783",
    "Mag": 3.6,
    "ColorIndex": -0.104
  },
  {
    "BayerFlamsteed": " 51    And",
    "ProperName": " ",
    "RA": "1.6331951",
    "Dec": "+48.62848641",
    "Distance": "53.3049040511727",
    "Mag": 3.59,
    "ColorIndex": 1.275
  },
  {
    "BayerFlamsteed": " 20Tau Ori",
    "ProperName": " ",
    "RA": "5.29344415",
    "Dec": "-06.84438616",
    "Distance": "170.068027210884",
    "Mag": 3.59,
    "ColorIndex": -0.115
  },
  {
    "BayerFlamsteed": " 13Gam Lep",
    "ProperName": " ",
    "RA": "5.74110416",
    "Dec": "-22.44748663",
    "Distance": "8.96941429724639",
    "Mag": 3.59,
    "ColorIndex": 0.481
  },
  {
    "BayerFlamsteed": "  5Bet Vir",
    "ProperName": " ",
    "RA": "11.8448017",
    "Dec": "+01.76537705",
    "Distance": "10.9003706126008",
    "Mag": 3.59,
    "ColorIndex": 0.518
  },
  {
    "BayerFlamsteed": "   Eps Cru",
    "ProperName": " ",
    "RA": "12.35605869",
    "Dec": "-60.40136988",
    "Distance": "69.9300699300699",
    "Mag": 3.59,
    "ColorIndex": 1.389
  },
  {
    "BayerFlamsteed": " 54Lam Gem",
    "ProperName": " ",
    "RA": "7.30155744",
    "Dec": "+16.54047526",
    "Distance": "28.9100896212778",
    "Mag": 3.58,
    "ColorIndex": 0.106
  },
  {
    "BayerFlamsteed": "  6Alp2Cap",
    "ProperName": " ",
    "RA": "20.30089401",
    "Dec": "-12.54485877",
    "Distance": "33.3222259246918",
    "Mag": 3.58,
    "ColorIndex": 0.883
  },
  {
    "BayerFlamsteed": " 77Kap Gem",
    "ProperName": " ",
    "RA": "7.74079682",
    "Dec": "+24.39812929",
    "Distance": "43.994720633524",
    "Mag": 3.57,
    "ColorIndex": 0.932
  },
  {
    "BayerFlamsteed": " 25Rho Boo",
    "ProperName": " ",
    "RA": "14.53051606",
    "Dec": "+30.37114497",
    "Distance": "45.6204379562044",
    "Mag": 3.57,
    "ColorIndex": 1.298
  },
  {
    "BayerFlamsteed": " 12Kap UMa",
    "ProperName": " ",
    "RA": "9.06043299",
    "Dec": "+47.15665934",
    "Distance": "129.70168612192",
    "Mag": 3.57,
    "ColorIndex": 0.007
  },
  {
    "BayerFlamsteed": "   Phi1Lup",
    "ProperName": " ",
    "RA": "15.36345457",
    "Dec": "-36.26116729",
    "Distance": "100.1001001001",
    "Mag": 3.57,
    "ColorIndex": 1.534
  },
  {
    "BayerFlamsteed": " 12Del Crt",
    "ProperName": " ",
    "RA": "11.3223674",
    "Dec": "-14.77904358",
    "Distance": "59.7014925373134",
    "Mag": 3.56,
    "ColorIndex": 1.112
  },
  {
    "BayerFlamsteed": "  8Iot Cet",
    "ProperName": " ",
    "RA": "0.32380084",
    "Dec": "-08.82382948",
    "Distance": "88.809946714032",
    "Mag": 3.56,
    "ColorIndex": 1.214
  },
  {
    "BayerFlamsteed": "   Phi Eri",
    "ProperName": " ",
    "RA": "2.27513896",
    "Dec": "-51.51211145",
    "Distance": "47.4833808167142",
    "Mag": 3.56,
    "ColorIndex": -0.12
  },
  {
    "BayerFlamsteed": "   Mu 2Sco",
    "ProperName": " ",
    "RA": "16.87226486",
    "Dec": "-38.01747781",
    "Distance": "158.478605388273",
    "Mag": 3.56,
    "ColorIndex": -0.21
  },
  {
    "BayerFlamsteed": " 44Chi Dra",
    "ProperName": " ",
    "RA": "18.35064971",
    "Dec": "+72.73369763",
    "Distance": "8.05736846345983",
    "Mag": 3.55,
    "ColorIndex": 0.489
  },
  {
    "BayerFlamsteed": " 41Ups4Eri",
    "ProperName": " ",
    "RA": "4.29822737",
    "Dec": "-33.79833145",
    "Distance": "54.7345374931582",
    "Mag": 3.55,
    "ColorIndex": -0.108
  },
  {
    "BayerFlamsteed": " 14Zet Lep",
    "ProperName": " ",
    "RA": "5.78259715",
    "Dec": "-14.82194717",
    "Distance": "21.519259737465",
    "Mag": 3.55,
    "ColorIndex": 0.104
  },
  {
    "BayerFlamsteed": "   Iot Lup",
    "ProperName": " ",
    "RA": "14.32339798",
    "Dec": "-46.05808391",
    "Distance": "107.874865156419",
    "Mag": 3.55,
    "ColorIndex": -0.184
  },
  {
    "BayerFlamsteed": "   Del Pav",
    "ProperName": " ",
    "RA": "20.14496114",
    "Dec": "-66.17932101",
    "Distance": "6.10761619739816",
    "Mag": 3.55,
    "ColorIndex": 0.751
  },
  {
    "BayerFlamsteed": " 55Xi  Ser",
    "ProperName": " ",
    "RA": "17.62645145",
    "Dec": "-15.39840835",
    "Distance": "32.3310701584222",
    "Mag": 3.54,
    "ColorIndex": 0.262
  },
  {
    "BayerFlamsteed": " 32Mu  Ser",
    "ProperName": " ",
    "RA": "15.82701825",
    "Dec": "-03.43014112",
    "Distance": "47.7554918815664",
    "Mag": 3.54,
    "ColorIndex": -0.036
  },
  {
    "BayerFlamsteed": "   Xi  Hya",
    "ProperName": " ",
    "RA": "11.55007195",
    "Dec": "-31.85752405",
    "Distance": "39.6353547364249",
    "Mag": 3.54,
    "ColorIndex": 0.947
  },
  {
    "BayerFlamsteed": " 74Eps Tau",
    "ProperName": " ",
    "RA": "4.47692591",
    "Dec": "+19.18052092",
    "Distance": "47.5285171102662",
    "Mag": 3.53,
    "ColorIndex": 1.014
  },
  {
    "BayerFlamsteed": " 17Bet Cnc",
    "ProperName": " ",
    "RA": "8.2752634",
    "Dec": "+09.18566295",
    "Distance": "89.0471950133571",
    "Mag": 3.53,
    "ColorIndex": 1.481
  },
  {
    "BayerFlamsteed": " 37Xi 2Sgr",
    "ProperName": " ",
    "RA": "18.96216103",
    "Dec": "-21.10662433",
    "Distance": "114.155251141553",
    "Mag": 3.52,
    "ColorIndex": 1.151
  },
  {
    "BayerFlamsteed": " 26The Peg",
    "ProperName": " ",
    "RA": "22.16994993",
    "Dec": "+06.19778900",
    "Distance": "29.6120817293456",
    "Mag": 3.52,
    "ColorIndex": 0.086
  },
  {
    "BayerFlamsteed": " 23Del Eri",
    "ProperName": " ",
    "RA": "3.72082113",
    "Dec": "-09.76519868",
    "Distance": "9.04322662325918",
    "Mag": 3.52,
    "ColorIndex": 0.915
  },
  {
    "BayerFlamsteed": " 14Omi Leo",
    "ProperName": " ",
    "RA": "9.68586607",
    "Dec": "+09.89239902",
    "Distance": "41.4593698175788",
    "Mag": 3.52,
    "ColorIndex": 0.516
  },
  {
    "BayerFlamsteed": " 10Bet Lyr",
    "ProperName": " ",
    "RA": "18.83466497",
    "Dec": "+33.36267788",
    "Distance": "270.27027027027",
    "Mag": 3.52,
    "ColorIndex": 0.003
  },
  {
    "BayerFlamsteed": "   Phi Vel",
    "ProperName": " ",
    "RA": "9.94770968",
    "Dec": "-54.56779730",
    "Distance": "591.715976331361",
    "Mag": 3.52,
    "ColorIndex": -0.067
  },
  {
    "BayerFlamsteed": " 48Mu  Peg",
    "ProperName": " ",
    "RA": "22.8333612",
    "Dec": "+24.60168486",
    "Distance": "35.778175313059",
    "Mag": 3.51,
    "ColorIndex": 0.933
  },
  {
    "BayerFlamsteed": " 12Gam Sge",
    "ProperName": " ",
    "RA": "19.97927434",
    "Dec": "+19.49209287",
    "Distance": "84.0336134453782",
    "Mag": 3.51,
    "ColorIndex": 1.571
  },
  {
    "BayerFlamsteed": " 55Del Gem",
    "ProperName": " ",
    "RA": "7.33538592",
    "Dec": "+21.98233941",
    "Distance": "18.034265103697",
    "Mag": 3.5,
    "ColorIndex": 0.374
  },
  {
    "BayerFlamsteed": " 32Iot Cep",
    "ProperName": " ",
    "RA": "22.82803115",
    "Dec": "+66.20071089",
    "Distance": "35.3731871241599",
    "Mag": 3.5,
    "ColorIndex": 1.053
  },
  {
    "BayerFlamsteed": " 13Kap CMa",
    "ProperName": " ",
    "RA": "6.83068485",
    "Dec": "-32.50848752",
    "Distance": "242.130750605327",
    "Mag": 3.5,
    "ColorIndex": -0.116
  },
  {
    "BayerFlamsteed": " 54Nu  UMa",
    "ProperName": " ",
    "RA": "11.30798759",
    "Dec": "+33.09423881",
    "Distance": "129.198966408269",
    "Mag": 3.49,
    "ColorIndex": 1.4
  },
  {
    "BayerFlamsteed": " 52Tau Cet",
    "ProperName": " ",
    "RA": "1.73475762",
    "Dec": "-15.93955597",
    "Distance": "3.6473720684247",
    "Mag": 3.49,
    "ColorIndex": 0.727
  },
  {
    "BayerFlamsteed": " 42Bet Boo",
    "ProperName": " ",
    "RA": "15.03244253",
    "Dec": "+40.39063671",
    "Distance": "67.0690811535882",
    "Mag": 3.49,
    "ColorIndex": 0.956
  },
  {
    "BayerFlamsteed": " 22Sig CMa",
    "ProperName": " ",
    "RA": "7.02865325",
    "Dec": "-27.93484165",
    "Distance": "373.134328358209",
    "Mag": 3.49,
    "ColorIndex": 1.729
  },
  {
    "BayerFlamsteed": "   Eps Gru",
    "ProperName": " ",
    "RA": "22.80922142",
    "Dec": "-51.31670354",
    "Distance": "39.7456279809221",
    "Mag": 3.49,
    "ColorIndex": 0.083
  },
  {
    "BayerFlamsteed": "   Alp Tel",
    "ProperName": " ",
    "RA": "18.44956396",
    "Dec": "-45.96832919",
    "Distance": "76.4525993883792",
    "Mag": 3.49,
    "ColorIndex": -0.179
  },
  {
    "BayerFlamsteed": " 44Eta Her",
    "ProperName": " ",
    "RA": "16.71492737",
    "Dec": "+38.92246103",
    "Distance": "34.3524562006183",
    "Mag": 3.48,
    "ColorIndex": 0.916
  },
  {
    "BayerFlamsteed": " 30Eta Leo",
    "ProperName": " ",
    "RA": "10.12220929",
    "Dec": "+16.76266572",
    "Distance": "653.59477124183",
    "Mag": 3.48,
    "ColorIndex": -0.031
  },
  {
    "BayerFlamsteed": " 86Gam Cet",
    "ProperName": " ",
    "RA": "2.72170126",
    "Dec": "+03.23617162",
    "Distance": "25.1382604323781",
    "Mag": 3.47,
    "ColorIndex": 0.093
  },
  {
    "BayerFlamsteed": "   Mu  Cen",
    "ProperName": " ",
    "RA": "13.82694662",
    "Dec": "-42.47368506",
    "Distance": "161.550888529887",
    "Mag": 3.47,
    "ColorIndex": -0.17
  },
  {
    "BayerFlamsteed": " 49Del Boo",
    "ProperName": " ",
    "RA": "15.25836216",
    "Dec": "+33.31510222",
    "Distance": "35.7909806728704",
    "Mag": 3.46,
    "ColorIndex": 0.961
  },
  {
    "BayerFlamsteed": " 31Eta Cet",
    "ProperName": " ",
    "RA": "1.14312879",
    "Dec": "-10.18192800",
    "Distance": "36.0620266858997",
    "Mag": 3.46,
    "ColorIndex": 1.161
  },
  {
    "BayerFlamsteed": " 24Eta Cas",
    "ProperName": " ",
    "RA": "0.8180835",
    "Dec": "+57.81654770",
    "Distance": "5.95273528186202",
    "Mag": 3.46,
    "ColorIndex": 0.587
  },
  {
    "BayerFlamsteed": "   Chi Car",
    "ProperName": " ",
    "RA": "7.94631715",
    "Dec": "-52.98240062",
    "Distance": "118.623962040332",
    "Mag": 3.46,
    "ColorIndex": -0.177
  },
  {
    "BayerFlamsteed": " 33Lam UMa",
    "ProperName": " ",
    "RA": "10.2849797",
    "Dec": "+42.91446855",
    "Distance": "41.2031314379893",
    "Mag": 3.45,
    "ColorIndex": 0.029
  },
  {
    "BayerFlamsteed": " 36Zet Leo",
    "ProperName": " ",
    "RA": "10.27816787",
    "Dec": "+23.41732840",
    "Distance": "79.6178343949044",
    "Mag": 3.43,
    "ColorIndex": 0.307
  },
  {
    "BayerFlamsteed": " 16Lam Aql",
    "ProperName": " ",
    "RA": "19.10415275",
    "Dec": "-04.88233456",
    "Distance": "38.3877159309021",
    "Mag": 3.43,
    "ColorIndex": -0.096
  },
  {
    "BayerFlamsteed": " 86Mu  Her",
    "ProperName": " ",
    "RA": "17.77436636",
    "Dec": "+27.72249917",
    "Distance": "8.39983200335993",
    "Mag": 3.42,
    "ColorIndex": 0.75
  },
  {
    "BayerFlamsteed": "  2Alp Tri",
    "ProperName": " ",
    "RA": "1.88469439",
    "Dec": "+29.57939727",
    "Distance": "19.657951641439",
    "Mag": 3.42,
    "ColorIndex": 0.488
  },
  {
    "BayerFlamsteed": "   Eta Lup",
    "ProperName": " ",
    "RA": "16.00203902",
    "Dec": "-38.39664079",
    "Distance": "151.285930408472",
    "Mag": 3.42,
    "ColorIndex": -0.206
  },
  {
    "BayerFlamsteed": "   Bet Pav",
    "ProperName": " ",
    "RA": "20.74932102",
    "Dec": "-66.20323826",
    "Distance": "42.1762969211303",
    "Mag": 3.42,
    "ColorIndex": 0.163
  },
  {
    "BayerFlamsteed": " 42Zet Peg",
    "ProperName": " ",
    "RA": "22.69102078",
    "Dec": "+10.83139111",
    "Distance": "63.9386189258312",
    "Mag": 3.41,
    "ColorIndex": -0.086
  },
  {
    "BayerFlamsteed": " 35Lam Tau",
    "ProperName": " ",
    "RA": "4.01133906",
    "Dec": "+12.49037571",
    "Distance": "113.507377979569",
    "Mag": 3.41,
    "ColorIndex": -0.099
  },
  {
    "BayerFlamsteed": "  3Eta Cep",
    "ProperName": " ",
    "RA": "20.75479684",
    "Dec": "+61.83679404",
    "Distance": "14.3410296859314",
    "Mag": 3.41,
    "ColorIndex": 0.912
  },
  {
    "BayerFlamsteed": "   Zet Lup",
    "ProperName": " ",
    "RA": "15.20477865",
    "Dec": "-52.09907465",
    "Distance": "35.6379187455453",
    "Mag": 3.41,
    "ColorIndex": 0.918
  },
  {
    "BayerFlamsteed": "   Nu  Cen",
    "ProperName": " ",
    "RA": "13.82508261",
    "Dec": "-41.68765971",
    "Distance": "145.560407569141",
    "Mag": 3.41,
    "ColorIndex": -0.225
  },
  {
    "BayerFlamsteed": "   Gam Phe",
    "ProperName": " ",
    "RA": "1.47276157",
    "Dec": "-43.31772906",
    "Distance": "71.7360114777618",
    "Mag": 3.41,
    "ColorIndex": 1.542
  },
  {
    "BayerFlamsteed": " 78The2Tau",
    "ProperName": " ",
    "RA": "4.47768747",
    "Dec": "+15.87094680",
    "Distance": "45.6829602558246",
    "Mag": 3.4,
    "ColorIndex": 0.179
  },
  {
    "BayerFlamsteed": " 43Del Vir",
    "ProperName": " ",
    "RA": "12.92680091",
    "Dec": "+03.39759862",
    "Distance": "62.0732464307883",
    "Mag": 3.39,
    "ColorIndex": 1.571
  },
  {
    "BayerFlamsteed": " 39Lam Ori",
    "ProperName": " ",
    "RA": "5.58563269",
    "Dec": "+09.93416294",
    "Distance": "323.624595469256",
    "Mag": 3.39,
    "ColorIndex": -0.16
  },
  {
    "BayerFlamsteed": " 21Zet Cep",
    "ProperName": " ",
    "RA": "22.18090608",
    "Dec": "+58.20124992",
    "Distance": "222.71714922049",
    "Mag": 3.39,
    "ColorIndex": 1.558
  },
  {
    "BayerFlamsteed": " 79Zet Vir",
    "ProperName": " ",
    "RA": "13.5782652",
    "Dec": "-00.59593821",
    "Distance": "22.4466891133558",
    "Mag": 3.38,
    "ColorIndex": 0.114
  },
  {
    "BayerFlamsteed": " 11Eps Hya",
    "ProperName": " ",
    "RA": "8.77962395",
    "Dec": "+06.41890691",
    "Distance": "41.4421881475342",
    "Mag": 3.38,
    "ColorIndex": 0.685
  },
  {
    "BayerFlamsteed": "   Eps Lup",
    "ProperName": " ",
    "RA": "15.37802431",
    "Dec": "-44.68957314",
    "Distance": "154.559505409583",
    "Mag": 3.37,
    "ColorIndex": -0.191
  },
  {
    "BayerFlamsteed": " 30Del Aql",
    "ProperName": " ",
    "RA": "19.42493129",
    "Dec": "+03.11457923",
    "Distance": "15.3727901614143",
    "Mag": 3.36,
    "ColorIndex": 0.319
  },
  {
    "BayerFlamsteed": " 45Eps Cas",
    "ProperName": " ",
    "RA": "1.90657873",
    "Dec": "+63.67014686",
    "Distance": "135.50135501355",
    "Mag": 3.35,
    "ColorIndex": -0.15
  },
  {
    "BayerFlamsteed": " 31Xi  Gem",
    "ProperName": " ",
    "RA": "6.75484265",
    "Dec": "+12.89605513",
    "Distance": "17.5377060680463",
    "Mag": 3.35,
    "ColorIndex": 0.443
  },
  {
    "BayerFlamsteed": " 28Eta Ori",
    "ProperName": " ",
    "RA": "5.40794918",
    "Dec": "-02.39713844",
    "Distance": "276.243093922652",
    "Mag": 3.35,
    "ColorIndex": -0.24
  },
  {
    "BayerFlamsteed": "  1Omi UMa",
    "ProperName": " ",
    "RA": "8.50445282",
    "Dec": "+60.71843110",
    "Distance": "56.3063063063063",
    "Mag": 3.35,
    "ColorIndex": 0.856
  },
  {
    "BayerFlamsteed": "  7Xi  Pup",
    "ProperName": " ",
    "RA": "7.82157187",
    "Dec": "-24.85978401",
    "Distance": "413.223140495868",
    "Mag": 3.34,
    "ColorIndex": 1.218
  },
  {
    "BayerFlamsteed": " 70The Leo",
    "ProperName": " ",
    "RA": "11.23734469",
    "Dec": "+15.42976310",
    "Distance": "54.4662309368192",
    "Mag": 3.33,
    "ColorIndex": -0.003
  },
  {
    "BayerFlamsteed": "   Alp Ret",
    "ProperName": " ",
    "RA": "4.24039753",
    "Dec": "-62.47397888",
    "Distance": "50.05005005005",
    "Mag": 3.33,
    "ColorIndex": 0.915
  },
  {
    "BayerFlamsteed": " 69Del UMa",
    "ProperName": " ",
    "RA": "12.25706919",
    "Dec": "+57.03259792",
    "Distance": "24.9687890137328",
    "Mag": 3.32,
    "ColorIndex": 0.077
  },
  {
    "BayerFlamsteed": " 64Nu  Oph",
    "ProperName": " ",
    "RA": "17.9837771",
    "Dec": "-09.77334973",
    "Distance": "46.8384074941452",
    "Mag": 3.32,
    "ColorIndex": 0.987
  },
  {
    "BayerFlamsteed": " 40Tau Sgr",
    "ProperName": " ",
    "RA": "19.11567841",
    "Dec": "-27.66981416",
    "Distance": "36.9139904023625",
    "Mag": 3.32,
    "ColorIndex": 1.169
  },
  {
    "BayerFlamsteed": " 25Rho Per",
    "ProperName": " ",
    "RA": "3.08624916",
    "Dec": "+38.84053298",
    "Distance": "99.7008973080758",
    "Mag": 3.32,
    "ColorIndex": 1.528
  },
  {
    "BayerFlamsteed": "   Eta Sco",
    "ProperName": " ",
    "RA": "17.20254886",
    "Dec": "-43.23849039",
    "Distance": "21.9490781387182",
    "Mag": 3.32,
    "ColorIndex": 0.441
  },
  {
    "BayerFlamsteed": "   Bet Phe",
    "ProperName": " ",
    "RA": "1.10141847",
    "Dec": "-46.71849042",
    "Distance": "60.7533414337789",
    "Mag": 3.32,
    "ColorIndex": 0.885
  },
  {
    "BayerFlamsteed": "  7Eta Gem",
    "ProperName": " ",
    "RA": "6.2479713",
    "Dec": "+22.50682376",
    "Distance": "107.066381156317",
    "Mag": 3.31,
    "ColorIndex": 1.6
  },
  {
    "BayerFlamsteed": "   Gam Ara",
    "ProperName": " ",
    "RA": "17.42323884",
    "Dec": "-56.37768824",
    "Distance": "348.432055749129",
    "Mag": 3.31,
    "ColorIndex": -0.15
  },
  {
    "BayerFlamsteed": "   Alp Dor",
    "ProperName": " ",
    "RA": "4.56658845",
    "Dec": "-55.04500559",
    "Distance": "53.8793103448276",
    "Mag": 3.3,
    "ColorIndex": -0.079
  },
  {
    "BayerFlamsteed": " 12Iot Dra",
    "ProperName": " ",
    "RA": "15.41549558",
    "Dec": "+58.96602354",
    "Distance": "31.328320802005",
    "Mag": 3.29,
    "ColorIndex": 1.166
  },
  {
    "BayerFlamsteed": "  5Mu  Lep",
    "ProperName": " ",
    "RA": "5.2155203",
    "Dec": "-16.20542901",
    "Distance": "56.5291124929339",
    "Mag": 3.29,
    "ColorIndex": -0.11
  },
  {
    "BayerFlamsteed": "   Ome Car",
    "ProperName": " ",
    "RA": "10.22896636",
    "Dec": "-70.03792169",
    "Distance": "113.507377979569",
    "Mag": 3.29,
    "ColorIndex": -0.074
  },
  {
    "BayerFlamsteed": " 76Del Aqr",
    "ProperName": " ",
    "RA": "22.91084423",
    "Dec": "-15.82075994",
    "Distance": "48.9236790606654",
    "Mag": 3.27,
    "ColorIndex": 0.066
  },
  {
    "BayerFlamsteed": " 42The Oph",
    "ProperName": " ",
    "RA": "17.36682891",
    "Dec": "-24.99948797",
    "Distance": "172.711571675302",
    "Mag": 3.27,
    "ColorIndex": -0.186
  },
  {
    "BayerFlamsteed": " 31Del And",
    "ProperName": " ",
    "RA": "0.65544371",
    "Dec": "+30.86122579",
    "Distance": "31.0655483069276",
    "Mag": 3.27,
    "ColorIndex": 1.268
  },
  {
    "BayerFlamsteed": "   Gam Hyi",
    "ProperName": " ",
    "RA": "3.78728653",
    "Dec": "-74.23924251",
    "Distance": "65.6598818122127",
    "Mag": 3.26,
    "ColorIndex": 1.59
  },
  {
    "BayerFlamsteed": " 49Pi  Hya",
    "ProperName": " ",
    "RA": "14.10618582",
    "Dec": "-26.68201883",
    "Distance": "31.0848616723656",
    "Mag": 3.25,
    "ColorIndex": 1.091
  },
  {
    "BayerFlamsteed": " 20Sig Lib",
    "ProperName": " ",
    "RA": "15.06785052",
    "Dec": "-25.28185602",
    "Distance": "89.5255147717099",
    "Mag": 3.25,
    "ColorIndex": 1.674
  },
  {
    "BayerFlamsteed": " 14Gam Lyr",
    "ProperName": " ",
    "RA": "18.98239571",
    "Dec": "+32.68955312",
    "Distance": "194.552529182879",
    "Mag": 3.25,
    "ColorIndex": -0.049
  },
  {
    "BayerFlamsteed": "   Sig Pup",
    "ProperName": " ",
    "RA": "7.48718842",
    "Dec": "-43.30189129",
    "Distance": "56.369785794814",
    "Mag": 3.25,
    "ColorIndex": 1.509
  },
  {
    "BayerFlamsteed": " 65The Aql",
    "ProperName": " ",
    "RA": "20.18840688",
    "Dec": "-00.82147569",
    "Distance": "88.0281690140845",
    "Mag": 3.24,
    "ColorIndex": -0.066
  },
  {
    "BayerFlamsteed": "   Alp Pic",
    "ProperName": " ",
    "RA": "6.80320475",
    "Dec": "-61.94197988",
    "Distance": "30.3398058252427",
    "Mag": 3.24,
    "ColorIndex": 0.225
  },
  {
    "BayerFlamsteed": " 58Eta Ser",
    "ProperName": " ",
    "RA": "18.35525571",
    "Dec": "-02.89712200",
    "Distance": "18.9358076121947",
    "Mag": 3.23,
    "ColorIndex": 0.941
  },
  {
    "BayerFlamsteed": "  8Bet Cep",
    "ProperName": " ",
    "RA": "21.47765973",
    "Dec": "+70.56069481",
    "Distance": "182.481751824818",
    "Mag": 3.23,
    "ColorIndex": -0.201
  },
  {
    "BayerFlamsteed": "  2Eps Oph",
    "ProperName": " ",
    "RA": "16.30534466",
    "Dec": "-04.69260809",
    "Distance": "32.95978905735",
    "Mag": 3.23,
    "ColorIndex": 0.966
  },
  {
    "BayerFlamsteed": "   Del Lup",
    "ProperName": " ",
    "RA": "15.35620455",
    "Dec": "-40.64745946",
    "Distance": "156.494522691706",
    "Mag": 3.22,
    "ColorIndex": -0.227
  },
  {
    "BayerFlamsteed": " 64Zet Cyg",
    "ProperName": " ",
    "RA": "21.21560598",
    "Dec": "+30.22708128",
    "Distance": "46.2534690101758",
    "Mag": 3.21,
    "ColorIndex": 0.99
  },
  {
    "BayerFlamsteed": " 35Gam Cep",
    "ProperName": " ",
    "RA": "23.65582834",
    "Dec": "+77.63196681",
    "Distance": "13.7931034482759",
    "Mag": 3.21,
    "ColorIndex": 1.031
  },
  {
    "BayerFlamsteed": " 27Kap Oph",
    "ProperName": " ",
    "RA": "16.96118627",
    "Dec": "+09.37505626",
    "Distance": "26.3227165043432",
    "Mag": 3.19,
    "ColorIndex": 1.16
  },
  {
    "BayerFlamsteed": "  2Eps Lep",
    "ProperName": " ",
    "RA": "5.09101447",
    "Dec": "-22.37085673",
    "Distance": "69.4927032661571",
    "Mag": 3.19,
    "ColorIndex": 1.46
  },
  {
    "BayerFlamsteed": "  1Pi 3Ori",
    "ProperName": " ",
    "RA": "4.83059395",
    "Dec": "+06.96124744",
    "Distance": "8.02568218298555",
    "Mag": 3.19,
    "ColorIndex": 0.484
  },
  {
    "BayerFlamsteed": " 10Eta Aur",
    "ProperName": " ",
    "RA": "5.10857473",
    "Dec": "+41.23464074",
    "Distance": "67.2494956287828",
    "Mag": 3.18,
    "ColorIndex": -0.148
  },
  {
    "BayerFlamsteed": "   Alp Cir",
    "ProperName": " ",
    "RA": "14.70852362",
    "Dec": "-64.97456957",
    "Distance": "16.4015089388224",
    "Mag": 3.18,
    "ColorIndex": 0.256
  },
  {
    "BayerFlamsteed": " 27Phi Sgr",
    "ProperName": " ",
    "RA": "18.76093138",
    "Dec": "-26.99077940",
    "Distance": "70.7213578500707",
    "Mag": 3.17,
    "ColorIndex": -0.107
  },
  {
    "BayerFlamsteed": " 25The UMa",
    "ProperName": " ",
    "RA": "9.54786812",
    "Dec": "+51.67860208",
    "Distance": "13.4861766689144",
    "Mag": 3.17,
    "ColorIndex": 0.475
  },
  {
    "BayerFlamsteed": " 22Zet Dra",
    "ProperName": " ",
    "RA": "17.1464514",
    "Dec": "+65.71463676",
    "Distance": "104.166666666667",
    "Mag": 3.17,
    "ColorIndex": -0.12
  },
  {
    "BayerFlamsteed": "   Nu  Pup",
    "ProperName": " ",
    "RA": "6.62935324",
    "Dec": "-43.19592394",
    "Distance": "129.70168612192",
    "Mag": 3.17,
    "ColorIndex": -0.103
  },
  {
    "BayerFlamsteed": " 67Pi  Her",
    "ProperName": " ",
    "RA": "17.25079285",
    "Dec": "+36.80915527",
    "Distance": "112.485939257593",
    "Mag": 3.16,
    "ColorIndex": 1.437
  },
  {
    "BayerFlamsteed": " 40Alp Lyn",
    "ProperName": " ",
    "RA": "9.35096077",
    "Dec": "+34.39252592",
    "Distance": "68.073519400953",
    "Mag": 3.14,
    "ColorIndex": 1.55
  },
  {
    "BayerFlamsteed": "   Kap Cen",
    "ProperName": " ",
    "RA": "14.9860275",
    "Dec": "-42.10414199",
    "Distance": "165.289256198347",
    "Mag": 3.13,
    "ColorIndex": -0.208
  },
  {
    "BayerFlamsteed": " 65Del Her",
    "ProperName": " ",
    "RA": "17.25053449",
    "Dec": "+24.83958739",
    "Distance": "24.0673886883273",
    "Mag": 3.12,
    "ColorIndex": 0.08
  },
  {
    "BayerFlamsteed": "  9Iot UMa",
    "ProperName": " ",
    "RA": "8.98689965",
    "Dec": "+48.04234956",
    "Distance": "14.6370023419204",
    "Mag": 3.12,
    "ColorIndex": 0.223
  },
  {
    "BayerFlamsteed": "   Zet Ara",
    "ProperName": " ",
    "RA": "16.97700854",
    "Dec": "-55.99005508",
    "Distance": "176.056338028169",
    "Mag": 3.12,
    "ColorIndex": 1.552
  },
  {
    "BayerFlamsteed": "   Bet Col",
    "ProperName": " ",
    "RA": "5.84932022",
    "Dec": "-35.76929225",
    "Distance": "26.3574064312072",
    "Mag": 3.12,
    "ColorIndex": 1.146
  },
  {
    "BayerFlamsteed": " 16Zet Hya",
    "ProperName": " ",
    "RA": "8.92324579",
    "Dec": "+05.94552770",
    "Distance": "46.2107208872458",
    "Mag": 3.11,
    "ColorIndex": 0.978
  },
  {
    "BayerFlamsteed": "   Nu  Hya",
    "ProperName": " ",
    "RA": "10.82706446",
    "Dec": "-16.19413208",
    "Distance": "42.4808836023789",
    "Mag": 3.11,
    "ColorIndex": 1.232
  },
  {
    "BayerFlamsteed": "   Lam Cen",
    "ProperName": " ",
    "RA": "11.59636896",
    "Dec": "-63.01982488",
    "Distance": "125.628140703518",
    "Mag": 3.11,
    "ColorIndex": -0.044
  },
  {
    "BayerFlamsteed": "   Alp Ind",
    "ProperName": " ",
    "RA": "20.62610824",
    "Dec": "-47.29166239",
    "Distance": "31.0462589257994",
    "Mag": 3.11,
    "ColorIndex": 0.998
  },
  {
    "BayerFlamsteed": "   Eta Sgr",
    "ProperName": " ",
    "RA": "18.29381366",
    "Dec": "-36.76128103",
    "Distance": "45.7247370827618",
    "Mag": 3.1,
    "ColorIndex": 1.582
  },
  {
    "BayerFlamsteed": " 57Del Dra",
    "ProperName": " ",
    "RA": "19.20920972",
    "Dec": "+67.66131695",
    "Distance": "30.7314074984634",
    "Mag": 3.07,
    "ColorIndex": 0.99
  },
  {
    "BayerFlamsteed": " 34Mu  UMa",
    "ProperName": " ",
    "RA": "10.37216756",
    "Dec": "+41.49943350",
    "Distance": "76.27765064836",
    "Mag": 3.06,
    "ColorIndex": 1.603
  },
  {
    "BayerFlamsteed": " 27Eps Gem",
    "ProperName": " ",
    "RA": "6.73220272",
    "Dec": "+25.13115531",
    "Distance": "277.008310249307",
    "Mag": 3.06,
    "ColorIndex": 1.377
  },
  {
    "BayerFlamsteed": "  9Bet Cap",
    "ProperName": " ",
    "RA": "20.35017956",
    "Dec": "-14.78140119",
    "Distance": "105.485232067511",
    "Mag": 3.05,
    "ColorIndex": 0.79
  },
  {
    "BayerFlamsteed": "  6Bet1Cyg",
    "ProperName": " ",
    "RA": "19.51202368",
    "Dec": "+27.95969480",
    "Distance": "118.203309692671",
    "Mag": 3.05,
    "ColorIndex": 1.088
  },
  {
    "BayerFlamsteed": " 27Gam Boo",
    "ProperName": " ",
    "RA": "14.53465494",
    "Dec": "+38.30788348",
    "Distance": "26.1164794985636",
    "Mag": 3.04,
    "ColorIndex": 0.191
  },
  {
    "BayerFlamsteed": "   Bet Mus",
    "ProperName": " ",
    "RA": "12.77135267",
    "Dec": "-68.10809405",
    "Distance": "95.4198473282443",
    "Mag": 3.04,
    "ColorIndex": -0.178
  },
  {
    "BayerFlamsteed": "  7Eps Aur",
    "ProperName": " ",
    "RA": "5.032815",
    "Dec": "+43.82331397",
    "Distance": "625",
    "Mag": 3.03,
    "ColorIndex": 0.537
  },
  {
    "BayerFlamsteed": " 24Omi2CMa",
    "ProperName": " ",
    "RA": "7.05040932",
    "Dec": "-23.83330131",
    "Distance": "787.40157480315",
    "Mag": 3.02,
    "ColorIndex": -0.077
  },
  {
    "BayerFlamsteed": "  2Eps Crv",
    "ProperName": " ",
    "RA": "12.16875718",
    "Dec": "-22.61979211",
    "Distance": "93.0232558139535",
    "Mag": 3.02,
    "ColorIndex": 1.326
  },
  {
    "BayerFlamsteed": "  1Zet CMa",
    "ProperName": " ",
    "RA": "6.33855198",
    "Dec": "-30.06337656",
    "Distance": "103.092783505155",
    "Mag": 3.02,
    "ColorIndex": -0.16
  },
  {
    "BayerFlamsteed": " 39Del Per",
    "ProperName": " ",
    "RA": "3.71541169",
    "Dec": "+47.78765330",
    "Distance": "161.812297734628",
    "Mag": 3.01,
    "ColorIndex": -0.125
  },
  {
    "BayerFlamsteed": " 52Psi UMa",
    "ProperName": " ",
    "RA": "11.16107206",
    "Dec": "+44.49855337",
    "Distance": "45.024763619991",
    "Mag": 3,
    "ColorIndex": 1.144
  },
  {
    "BayerFlamsteed": " 13Gam UMi",
    "ProperName": " ",
    "RA": "15.34548589",
    "Dec": "+71.83397308",
    "Distance": "147.275405007364",
    "Mag": 3,
    "ColorIndex": 0.058
  },
  {
    "BayerFlamsteed": "  4Bet Tri",
    "ProperName": " ",
    "RA": "2.15903358",
    "Dec": "+34.98739204",
    "Distance": "38.109756097561",
    "Mag": 3,
    "ColorIndex": 0.14
  },
  {
    "BayerFlamsteed": "   Mu 1Sco",
    "ProperName": " ",
    "RA": "16.86451079",
    "Dec": "-38.04732717",
    "Distance": "251.889168765743",
    "Mag": 3,
    "ColorIndex": -0.2
  },
  {
    "BayerFlamsteed": "   Gam Gru",
    "ProperName": " ",
    "RA": "21.8987928",
    "Dec": "-37.36482290",
    "Distance": "62.2277535780958",
    "Mag": 3,
    "ColorIndex": -0.084
  },
  {
    "BayerFlamsteed": " 46Gam Hya",
    "ProperName": " ",
    "RA": "13.31534816",
    "Dec": "-23.17141246",
    "Distance": "40.5022276225192",
    "Mag": 2.99,
    "ColorIndex": 0.92
  },
  {
    "BayerFlamsteed": " 17Zet Aql",
    "ProperName": " ",
    "RA": "19.09017012",
    "Dec": "+13.86370983",
    "Distance": "25.5232261357836",
    "Mag": 2.99,
    "ColorIndex": 0.014
  },
  {
    "BayerFlamsteed": "   Iot1Sco",
    "ProperName": " ",
    "RA": "17.79307809",
    "Dec": "-40.12698197",
    "Distance": "549.450549450549",
    "Mag": 2.99,
    "ColorIndex": 0.509
  },
  {
    "BayerFlamsteed": " 10Gam2Sgr",
    "ProperName": " ",
    "RA": "18.09681239",
    "Dec": "-30.42365007",
    "Distance": "29.4637595757219",
    "Mag": 2.98,
    "ColorIndex": 0.981
  },
  {
    "BayerFlamsteed": "123Zet Tau",
    "ProperName": " ",
    "RA": "5.62741229",
    "Dec": "+21.14259299",
    "Distance": "127.877237851662",
    "Mag": 2.97,
    "ColorIndex": -0.148
  },
  {
    "BayerFlamsteed": " 34Gam Eri",
    "ProperName": " ",
    "RA": "3.96714724",
    "Dec": "-13.50824471",
    "Distance": "67.7966101694915",
    "Mag": 2.97,
    "ColorIndex": 1.588
  },
  {
    "BayerFlamsteed": " 17Eps Leo",
    "ProperName": " ",
    "RA": "9.76419511",
    "Dec": "+23.77427792",
    "Distance": "76.8639508070715",
    "Mag": 2.97,
    "ColorIndex": 0.808
  },
  {
    "BayerFlamsteed": " 34Alp Aqr",
    "ProperName": " ",
    "RA": "22.09639591",
    "Dec": "-00.31982656",
    "Distance": "232.558139534884",
    "Mag": 2.95,
    "ColorIndex": 0.969
  },
  {
    "BayerFlamsteed": "  7Del Crv",
    "ProperName": " ",
    "RA": "12.4977731",
    "Dec": "-16.51509397",
    "Distance": "26.9469145782808",
    "Mag": 2.94,
    "ColorIndex": -0.012
  },
  {
    "BayerFlamsteed": "   Tau Pup",
    "ProperName": " ",
    "RA": "6.83226023",
    "Dec": "-50.61439973",
    "Distance": "56.0224089635854",
    "Mag": 2.94,
    "ColorIndex": 1.207
  },
  {
    "BayerFlamsteed": " 44Eta Peg",
    "ProperName": " ",
    "RA": "22.71670238",
    "Dec": "+30.22130866",
    "Distance": "65.8761528326746",
    "Mag": 2.93,
    "ColorIndex": 0.852
  },
  {
    "BayerFlamsteed": "   Ups Car",
    "ProperName": " ",
    "RA": "9.78503822",
    "Dec": "-65.07201888",
    "Distance": "497.512437810945",
    "Mag": 2.92,
    "ColorIndex": 0.273
  },
  {
    "BayerFlamsteed": " 23Gam Per",
    "ProperName": " ",
    "RA": "3.07994173",
    "Dec": "+53.50645031",
    "Distance": "78.6163522012579",
    "Mag": 2.91,
    "ColorIndex": 0.716
  },
  {
    "BayerFlamsteed": " 45Eps Per",
    "ProperName": " ",
    "RA": "3.96422809",
    "Dec": "+40.01027315",
    "Distance": "165.016501650165",
    "Mag": 2.9,
    "ColorIndex": -0.199
  },
  {
    "BayerFlamsteed": " 22Bet Aqr",
    "ProperName": " ",
    "RA": "21.52597796",
    "Dec": "-05.57115593",
    "Distance": "187.617260787993",
    "Mag": 2.9,
    "ColorIndex": 0.828
  },
  {
    "BayerFlamsteed": " 20Sig Sco",
    "ProperName": " ",
    "RA": "16.35314514",
    "Dec": "-25.59275259",
    "Distance": "225.225225225225",
    "Mag": 2.9,
    "ColorIndex": 0.299
  },
  {
    "BayerFlamsteed": " 12Alp2CVn",
    "ProperName": " ",
    "RA": "12.9338447",
    "Dec": "+38.31824617",
    "Distance": "33.7837837837838",
    "Mag": 2.89,
    "ColorIndex": -0.115
  },
  {
    "BayerFlamsteed": "  6Pi  Sco",
    "ProperName": " ",
    "RA": "15.98086685",
    "Dec": "-26.11404280",
    "Distance": "140.845070422535",
    "Mag": 2.89,
    "ColorIndex": -0.18
  },
  {
    "BayerFlamsteed": "  3Bet CMi",
    "ProperName": " ",
    "RA": "7.45252008",
    "Dec": "+08.28940893",
    "Distance": "52.1920668058455",
    "Mag": 2.89,
    "ColorIndex": -0.097
  },
  {
    "BayerFlamsteed": " 41Pi  Sgr",
    "ProperName": " ",
    "RA": "19.1627316",
    "Dec": "-21.02352534",
    "Distance": "134.952766531714",
    "Mag": 2.88,
    "ColorIndex": 0.377
  },
  {
    "BayerFlamsteed": "   The1Eri",
    "ProperName": " ",
    "RA": "2.97103212",
    "Dec": "-40.30473491",
    "Distance": "49.4559841740851",
    "Mag": 2.88,
    "ColorIndex": 0.128
  },
  {
    "BayerFlamsteed": " 13Mu  Gem",
    "ProperName": " ",
    "RA": "6.3826642",
    "Dec": "+22.51385027",
    "Distance": "71.0732054015636",
    "Mag": 2.87,
    "ColorIndex": 1.621
  },
  {
    "BayerFlamsteed": "   Gam TrA",
    "ProperName": " ",
    "RA": "15.31519133",
    "Dec": "-68.67946723",
    "Distance": "56.0224089635854",
    "Mag": 2.87,
    "ColorIndex": 0.014
  },
  {
    "BayerFlamsteed": "   Alp Tuc",
    "ProperName": " ",
    "RA": "22.30838283",
    "Dec": "-60.25949486",
    "Distance": "60.9013398294762",
    "Mag": 2.87,
    "ColorIndex": 1.39
  },
  {
    "BayerFlamsteed": " 18Del Cyg",
    "ProperName": " ",
    "RA": "19.74956725",
    "Dec": "+45.13069195",
    "Distance": "52.4383848977452",
    "Mag": 2.86,
    "ColorIndex": -0.002
  },
  {
    "BayerFlamsteed": "   Alp Hyi",
    "ProperName": " ",
    "RA": "1.97940884",
    "Dec": "-61.56992444",
    "Distance": "21.8627022299956",
    "Mag": 2.86,
    "ColorIndex": 0.29
  },
  {
    "BayerFlamsteed": " 49Del Cap",
    "ProperName": " ",
    "RA": "21.78396813",
    "Dec": "-16.12656595",
    "Distance": "11.8231260345235",
    "Mag": 2.85,
    "ColorIndex": 0.18
  },
  {
    "BayerFlamsteed": " 47Eps Vir",
    "ProperName": "Vindemiatrix",
    "RA": "13.03632237",
    "Dec": "+10.95910186",
    "Distance": "31.3479623824451",
    "Mag": 2.85,
    "ColorIndex": 0.934
  },
  {
    "BayerFlamsteed": " 25Eta Tau",
    "ProperName": "Alcyone",
    "RA": "3.79140671",
    "Dec": "+24.10524193",
    "Distance": "112.739571589628",
    "Mag": 2.85,
    "ColorIndex": -0.086
  },
  {
    "BayerFlamsteed": " 44Zet Per",
    "ProperName": " ",
    "RA": "3.90219957",
    "Dec": "+31.88365776",
    "Distance": "301.204819277108",
    "Mag": 2.84,
    "ColorIndex": 0.271
  },
  {
    "BayerFlamsteed": "   Bet Ara",
    "ProperName": " ",
    "RA": "17.42166588",
    "Dec": "-55.52982397",
    "Distance": "184.842883548983",
    "Mag": 2.84,
    "ColorIndex": 1.479
  },
  {
    "BayerFlamsteed": "   Alp Ara",
    "ProperName": " ",
    "RA": "17.53070044",
    "Dec": "-49.87598159",
    "Distance": "74.2942050520059",
    "Mag": 2.84,
    "ColorIndex": -0.136
  },
  {
    "BayerFlamsteed": " 88Gam Peg",
    "ProperName": "Algenib",
    "RA": "0.22059721",
    "Dec": "+15.18361593",
    "Distance": "102.145045965271",
    "Mag": 2.83,
    "ColorIndex": -0.19
  },
  {
    "BayerFlamsteed": " 15Rho Pup",
    "ProperName": " ",
    "RA": "8.12575059",
    "Dec": "-24.30443677",
    "Distance": "19.2344681669552",
    "Mag": 2.83,
    "ColorIndex": 0.458
  },
  {
    "BayerFlamsteed": "   Bet TrA",
    "ProperName": " ",
    "RA": "15.91911334",
    "Dec": "-63.42974973",
    "Distance": "12.3092072870507",
    "Mag": 2.83,
    "ColorIndex": 0.315
  },
  {
    "BayerFlamsteed": " 23Tau Sco",
    "ProperName": " ",
    "RA": "16.59804428",
    "Dec": "-28.21596156",
    "Distance": "131.752305665349",
    "Mag": 2.82,
    "ColorIndex": -0.206
  },
  {
    "BayerFlamsteed": " 22Lam Sgr",
    "ProperName": " ",
    "RA": "18.46618597",
    "Dec": "-25.42124732",
    "Distance": "23.696682464455",
    "Mag": 2.82,
    "ColorIndex": 1.025
  },
  {
    "BayerFlamsteed": "   Bet Hyi",
    "ProperName": " ",
    "RA": "0.42755612",
    "Dec": "-77.25503511",
    "Distance": "7.47495888772612",
    "Mag": 2.82,
    "ColorIndex": 0.618
  },
  {
    "BayerFlamsteed": " 40Zet Her",
    "ProperName": " ",
    "RA": "16.68818808",
    "Dec": "+31.60188695",
    "Distance": "10.7956385620209",
    "Mag": 2.81,
    "ColorIndex": 0.65
  },
  {
    "BayerFlamsteed": "  9Bet Lep",
    "ProperName": "Nihal",
    "RA": "5.47075731",
    "Dec": "-20.75923214",
    "Distance": "48.8042947779405",
    "Mag": 2.81,
    "ColorIndex": 0.807
  },
  {
    "BayerFlamsteed": "   Gam Lup",
    "ProperName": " ",
    "RA": "15.58568343",
    "Dec": "-41.16669497",
    "Distance": "173.913043478261",
    "Mag": 2.8,
    "ColorIndex": -0.216
  },
  {
    "BayerFlamsteed": " 23Bet Dra",
    "ProperName": " ",
    "RA": "17.5072158",
    "Dec": "+52.30135901",
    "Distance": "110.864745011086",
    "Mag": 2.79,
    "ColorIndex": 0.954
  },
  {
    "BayerFlamsteed": "   Del Cru",
    "ProperName": " ",
    "RA": "12.25243248",
    "Dec": "-58.74890179",
    "Distance": "111.607142857143",
    "Mag": 2.79,
    "ColorIndex": -0.193
  },
  {
    "BayerFlamsteed": " 67Bet Eri",
    "ProperName": " ",
    "RA": "5.13084276",
    "Dec": "-05.08626282",
    "Distance": "27.2405339144647",
    "Mag": 2.78,
    "ColorIndex": 0.161
  },
  {
    "BayerFlamsteed": " 64Alp1Her",
    "ProperName": "Rasalgethi",
    "RA": "17.24412845",
    "Dec": "+14.39025314",
    "Distance": "117.233294255569",
    "Mag": 2.78,
    "ColorIndex": 1.164
  },
  {
    "BayerFlamsteed": " 27Bet Her",
    "ProperName": " ",
    "RA": "16.50368379",
    "Dec": "+21.48964850",
    "Distance": "45.3103760761214",
    "Mag": 2.78,
    "ColorIndex": 0.947
  },
  {
    "BayerFlamsteed": " 60Bet Oph",
    "ProperName": " ",
    "RA": "17.72454914",
    "Dec": "+04.56691684",
    "Distance": "25.1382604323781",
    "Mag": 2.76,
    "ColorIndex": 1.168
  },
  {
    "BayerFlamsteed": " 44Iot Ori",
    "ProperName": " ",
    "RA": "5.59055031",
    "Dec": "-05.90989984",
    "Distance": "406.50406504065",
    "Mag": 2.75,
    "ColorIndex": -0.21
  },
  {
    "BayerFlamsteed": "  9Alp2Lib",
    "ProperName": " ",
    "RA": "14.84799369",
    "Dec": "-16.04161047",
    "Distance": "23.6686390532544",
    "Mag": 2.75,
    "ColorIndex": 0.147
  },
  {
    "BayerFlamsteed": "   Iot Cen",
    "ProperName": " ",
    "RA": "13.34335154",
    "Dec": "-36.71208109",
    "Distance": "17.9726815240834",
    "Mag": 2.75,
    "ColorIndex": 0.068
  },
  {
    "BayerFlamsteed": " 29Gam Vir",
    "ProperName": " ",
    "RA": "12.69444503",
    "Dec": "-01.44952231",
    "Distance": "11.8301194842068",
    "Mag": 2.74,
    "ColorIndex": 0.368
  },
  {
    "BayerFlamsteed": "   The Car",
    "ProperName": " ",
    "RA": "10.71595186",
    "Dec": "-64.39447937",
    "Distance": "134.589502018843",
    "Mag": 2.74,
    "ColorIndex": -0.22
  },
  {
    "BayerFlamsteed": " 14Eta Dra",
    "ProperName": " ",
    "RA": "16.39986301",
    "Dec": "+61.51407536",
    "Distance": "26.8961807423346",
    "Mag": 2.73,
    "ColorIndex": 0.91
  },
  {
    "BayerFlamsteed": "  1Del Oph",
    "ProperName": " ",
    "RA": "16.23910173",
    "Dec": "-03.69397562",
    "Distance": "52.1920668058455",
    "Mag": 2.73,
    "ColorIndex": 1.584
  },
  {
    "BayerFlamsteed": " 50Gam Aql",
    "ProperName": "Tarazed",
    "RA": "19.77099171",
    "Dec": "+10.61326869",
    "Distance": "141.242937853107",
    "Mag": 2.72,
    "ColorIndex": 1.507
  },
  {
    "BayerFlamsteed": " 19Del Sgr",
    "ProperName": " ",
    "RA": "18.34989489",
    "Dec": "-29.82803914",
    "Distance": "93.7207122774133",
    "Mag": 2.72,
    "ColorIndex": 1.38
  },
  {
    "BayerFlamsteed": "   Pi  Pup",
    "ProperName": " ",
    "RA": "7.28571226",
    "Dec": "-37.09748689",
    "Distance": "335.570469798658",
    "Mag": 2.71,
    "ColorIndex": 1.616
  },
  {
    "BayerFlamsteed": " 34Ups Sco",
    "ProperName": " ",
    "RA": "17.512733",
    "Dec": "-37.29574016",
    "Distance": "158.982511923688",
    "Mag": 2.7,
    "ColorIndex": -0.179
  },
  {
    "BayerFlamsteed": "  3Iot Aur",
    "ProperName": " ",
    "RA": "4.94989339",
    "Dec": "+33.16613537",
    "Distance": "156.985871271586",
    "Mag": 2.69,
    "ColorIndex": 1.49
  },
  {
    "BayerFlamsteed": "   Mu  Vel",
    "ProperName": " ",
    "RA": "10.77947836",
    "Dec": "-49.42012517",
    "Distance": "35.486160397445",
    "Mag": 2.69,
    "ColorIndex": 0.901
  },
  {
    "BayerFlamsteed": "   Alp Mus",
    "ProperName": " ",
    "RA": "12.61974547",
    "Dec": "-69.13553358",
    "Distance": "93.7207122774133",
    "Mag": 2.69,
    "ColorIndex": -0.176
  },
  {
    "BayerFlamsteed": "  8Eta Boo",
    "ProperName": " ",
    "RA": "13.91142116",
    "Dec": "+18.39858742",
    "Distance": "11.3417262107293",
    "Mag": 2.68,
    "ColorIndex": 0.58
  },
  {
    "BayerFlamsteed": "   Bet Lup",
    "ProperName": " ",
    "RA": "14.97554279",
    "Dec": "-43.13386699",
    "Distance": "160.513643659711",
    "Mag": 2.68,
    "ColorIndex": -0.184
  },
  {
    "BayerFlamsteed": " 37Del Cas",
    "ProperName": " ",
    "RA": "1.43016751",
    "Dec": "+60.23540347",
    "Distance": "30.4785126485827",
    "Mag": 2.66,
    "ColorIndex": 0.16
  },
  {
    "BayerFlamsteed": " 37The Aur",
    "ProperName": " ",
    "RA": "5.99534393",
    "Dec": "+37.21276409",
    "Distance": "53.1067445565587",
    "Mag": 2.65,
    "ColorIndex": -0.083
  },
  {
    "BayerFlamsteed": "  9Bet Crv",
    "ProperName": " ",
    "RA": "12.57312057",
    "Dec": "-23.39662306",
    "Distance": "42.8449014567267",
    "Mag": 2.65,
    "ColorIndex": 0.893
  },
  {
    "BayerFlamsteed": "   Alp Col",
    "ProperName": " ",
    "RA": "5.66081665",
    "Dec": "-34.07404941",
    "Distance": "82.2368421052632",
    "Mag": 2.65,
    "ColorIndex": -0.12
  },
  {
    "BayerFlamsteed": "  6Bet Ari",
    "ProperName": " ",
    "RA": "1.91065251",
    "Dec": "+20.80829949",
    "Distance": "18.2681768359518",
    "Mag": 2.64,
    "ColorIndex": 0.165
  },
  {
    "BayerFlamsteed": " 24Alp Ser",
    "ProperName": "Unukalhai",
    "RA": "15.7377766",
    "Dec": "+06.42551971",
    "Distance": "22.4517287831163",
    "Mag": 2.63,
    "ColorIndex": 1.167
  },
  {
    "BayerFlamsteed": " 27Bet Lib",
    "ProperName": " ",
    "RA": "15.28346439",
    "Dec": "-09.38286694",
    "Distance": "49.0677134445535",
    "Mag": 2.61,
    "ColorIndex": -0.071
  },
  {
    "BayerFlamsteed": " 38Zet Sgr",
    "ProperName": " ",
    "RA": "19.04353428",
    "Dec": "-29.88011429",
    "Distance": "27.3149412728763",
    "Mag": 2.6,
    "ColorIndex": 0.062
  },
  {
    "BayerFlamsteed": " 11Alp Lep",
    "ProperName": "Arneb",
    "RA": "5.54550386",
    "Dec": "-17.82229227",
    "Distance": "393.700787401575",
    "Mag": 2.58,
    "ColorIndex": 0.211
  },
  {
    "BayerFlamsteed": "  4Gam Crv",
    "ProperName": " ",
    "RA": "12.26346329",
    "Dec": "-17.54198370",
    "Distance": "50.5561172901921",
    "Mag": 2.58,
    "ColorIndex": -0.107
  },
  {
    "BayerFlamsteed": "   Del Cen",
    "ProperName": " ",
    "RA": "12.13931767",
    "Dec": "-50.72240999",
    "Distance": "121.212121212121",
    "Mag": 2.58,
    "ColorIndex": -0.128
  },
  {
    "BayerFlamsteed": " 68Del Leo",
    "ProperName": " ",
    "RA": "11.23511447",
    "Dec": "+20.52403384",
    "Distance": "17.6928520877565",
    "Mag": 2.56,
    "ColorIndex": 0.128
  },
  {
    "BayerFlamsteed": "  8Bet1Sco",
    "ProperName": " ",
    "RA": "16.0906208",
    "Dec": "-19.80539286",
    "Distance": "162.60162601626",
    "Mag": 2.56,
    "ColorIndex": -0.065
  },
  {
    "BayerFlamsteed": "   Zet Cen",
    "ProperName": " ",
    "RA": "13.92567635",
    "Dec": "-47.28826634",
    "Distance": "117.924528301887",
    "Mag": 2.55,
    "ColorIndex": -0.176
  },
  {
    "BayerFlamsteed": " 92Alp Cet",
    "ProperName": "Menkar",
    "RA": "3.03799418",
    "Dec": "+04.08992539",
    "Distance": "67.476383265857",
    "Mag": 2.54,
    "ColorIndex": 1.63
  },
  {
    "BayerFlamsteed": " 13Zet Oph",
    "ProperName": " ",
    "RA": "16.61931389",
    "Dec": "-10.56715180",
    "Distance": "140.449438202247",
    "Mag": 2.54,
    "ColorIndex": 0.038
  },
  {
    "BayerFlamsteed": " 54Alp Peg",
    "ProperName": "Markab",
    "RA": "23.07933801",
    "Dec": "+15.20536786",
    "Distance": "42.8082191780822",
    "Mag": 2.49,
    "ColorIndex": -0.002
  },
  {
    "BayerFlamsteed": " 53Eps Cyg",
    "ProperName": " ",
    "RA": "20.77012006",
    "Dec": "+33.96945334",
    "Distance": "22.0945647370747",
    "Mag": 2.48,
    "ColorIndex": 1.021
  },
  {
    "BayerFlamsteed": "   Kap Vel",
    "ProperName": " ",
    "RA": "9.36856367",
    "Dec": "-55.01069531",
    "Distance": "165.289256198347",
    "Mag": 2.47,
    "ColorIndex": -0.141
  },
  {
    "BayerFlamsteed": " 31Eta CMa",
    "ProperName": " ",
    "RA": "7.40158473",
    "Dec": "-29.30311979",
    "Distance": "980.392156862745",
    "Mag": 2.45,
    "ColorIndex": -0.083
  },
  {
    "BayerFlamsteed": "  5Alp Cep",
    "ProperName": "Alderamin",
    "RA": "21.30960598",
    "Dec": "+62.58545529",
    "Distance": "14.9611011370437",
    "Mag": 2.45,
    "ColorIndex": 0.257
  },
  {
    "BayerFlamsteed": " 53Bet Peg",
    "ProperName": "Scheat",
    "RA": "23.06287038",
    "Dec": "+28.08245462",
    "Distance": "61.0873549175321",
    "Mag": 2.44,
    "ColorIndex": 1.655
  },
  {
    "BayerFlamsteed": " 35Eta Oph",
    "ProperName": " ",
    "RA": "17.17296177",
    "Dec": "-15.72514757",
    "Distance": "25.7931390250193",
    "Mag": 2.43,
    "ColorIndex": 0.059
  },
  {
    "BayerFlamsteed": " 64Gam UMa",
    "ProperName": "Phad",
    "RA": "11.89715035",
    "Dec": "+53.69473296",
    "Distance": "25.6476019492177",
    "Mag": 2.41,
    "ColorIndex": 0.044
  },
  {
    "BayerFlamsteed": "   Alp Phe",
    "ProperName": "Ankaa",
    "RA": "0.43801871",
    "Dec": "-42.30512197",
    "Distance": "23.7304224015187",
    "Mag": 2.4,
    "ColorIndex": 1.083
  },
  {
    "BayerFlamsteed": "   Kap Sco",
    "ProperName": " ",
    "RA": "17.70813327",
    "Dec": "-39.02992092",
    "Distance": "142.247510668563",
    "Mag": 2.39,
    "ColorIndex": -0.171
  },
  {
    "BayerFlamsteed": "  8Eps Peg",
    "ProperName": "Enif",
    "RA": "21.73642787",
    "Dec": "+09.87500791",
    "Distance": "206.185567010309",
    "Mag": 2.38,
    "ColorIndex": 1.52
  },
  {
    "BayerFlamsteed": " 36Eps Boo",
    "ProperName": "Izar",
    "RA": "14.74979191",
    "Dec": "+27.07417383",
    "Distance": "64.3086816720257",
    "Mag": 2.35,
    "ColorIndex": 0.966
  },
  {
    "BayerFlamsteed": " 48Bet UMa",
    "ProperName": "Merak",
    "RA": "11.0306641",
    "Dec": "+56.38234478",
    "Distance": "24.3486729973216",
    "Mag": 2.34,
    "ColorIndex": 0.033
  },
  {
    "BayerFlamsteed": "   Eta Cen",
    "ProperName": " ",
    "RA": "14.5917921",
    "Dec": "-42.15774562",
    "Distance": "94.6073793755913",
    "Mag": 2.33,
    "ColorIndex": -0.157
  },
  {
    "BayerFlamsteed": "   Alp Lup",
    "ProperName": " ",
    "RA": "14.69882606",
    "Dec": "-47.38814127",
    "Distance": "168.067226890756",
    "Mag": 2.3,
    "ColorIndex": -0.154
  },
  {
    "BayerFlamsteed": " 26Eps Sco",
    "ProperName": " ",
    "RA": "16.83617915",
    "Dec": "-34.29260982",
    "Distance": "20.0601805416249",
    "Mag": 2.29,
    "ColorIndex": 1.144
  },
  {
    "BayerFlamsteed": "  7Del Sco",
    "ProperName": " ",
    "RA": "16.00555881",
    "Dec": "-22.62162024",
    "Distance": "123.152709359606",
    "Mag": 2.29,
    "ColorIndex": -0.117
  },
  {
    "BayerFlamsteed": "   Eps Cen",
    "ProperName": " ",
    "RA": "13.66479797",
    "Dec": "-53.46636269",
    "Distance": "115.207373271889",
    "Mag": 2.29,
    "ColorIndex": -0.171
  },
  {
    "BayerFlamsteed": " 11Bet Cas",
    "ProperName": "Caph",
    "RA": "0.15280269",
    "Dec": "+59.15021814",
    "Distance": "16.69727834363",
    "Mag": 2.28,
    "ColorIndex": 0.38
  },
  {
    "BayerFlamsteed": " 34Del Ori",
    "ProperName": " ",
    "RA": "5.53344437",
    "Dec": "-00.29909340",
    "Distance": "280.898876404494",
    "Mag": 2.25,
    "ColorIndex": -0.175
  },
  {
    "BayerFlamsteed": " 33Gam Dra",
    "ProperName": "Etamin",
    "RA": "17.94343829",
    "Dec": "+51.48895101",
    "Distance": "45.2488687782805",
    "Mag": 2.24,
    "ColorIndex": 1.521
  },
  {
    "BayerFlamsteed": " 18Alp Cas",
    "ProperName": "Shedir",
    "RA": "0.67510756",
    "Dec": "+56.53740928",
    "Distance": "70.0770847932726",
    "Mag": 2.24,
    "ColorIndex": 1.17
  },
  {
    "BayerFlamsteed": " 79Zet UMa",
    "ProperName": "Mizar",
    "RA": "13.39872773",
    "Dec": "+54.92541525",
    "Distance": "23.9635753654445",
    "Mag": 2.23,
    "ColorIndex": 0.057
  },
  {
    "BayerFlamsteed": " 37Gam Cyg",
    "ProperName": " ",
    "RA": "20.37047223",
    "Dec": "+40.25668150",
    "Distance": "467.289719626168",
    "Mag": 2.23,
    "ColorIndex": 0.673
  },
  {
    "BayerFlamsteed": "   Lam Vel",
    "ProperName": " ",
    "RA": "9.13327141",
    "Dec": "-43.43262406",
    "Distance": "175.746924428822",
    "Mag": 2.23,
    "ColorIndex": 1.665
  },
  {
    "BayerFlamsteed": "  5Alp CrB",
    "ProperName": "Alphekka",
    "RA": "15.57810819",
    "Dec": "+26.71491041",
    "Distance": "22.9095074455899",
    "Mag": 2.22,
    "ColorIndex": 0.032
  },
  {
    "BayerFlamsteed": "   Zet Pup",
    "ProperName": " ",
    "RA": "8.0597417",
    "Dec": "-40.00318846",
    "Distance": "429.184549356223",
    "Mag": 2.21,
    "ColorIndex": -0.269
  },
  {
    "BayerFlamsteed": "   Iot Car",
    "ProperName": " ",
    "RA": "9.28484122",
    "Dec": "-59.27526115",
    "Distance": "212.314225053079",
    "Mag": 2.21,
    "ColorIndex": 0.189
  },
  {
    "BayerFlamsteed": "   Gam Cen",
    "ProperName": " ",
    "RA": "12.69200138",
    "Dec": "-48.95988553",
    "Distance": "39.984006397441",
    "Mag": 2.2,
    "ColorIndex": -0.023
  },
  {
    "BayerFlamsteed": " 27Gam Cas",
    "ProperName": " ",
    "RA": "0.9451392",
    "Dec": "+60.71674966",
    "Distance": "187.96992481203",
    "Mag": 2.15,
    "ColorIndex": -0.046
  },
  {
    "BayerFlamsteed": " 94Bet Leo",
    "ProperName": "Denebola",
    "RA": "11.81774398",
    "Dec": "+14.57233687",
    "Distance": "11.0913930789707",
    "Mag": 2.14,
    "ColorIndex": 0.09
  },
  {
    "BayerFlamsteed": " 57Gam1And",
    "ProperName": " ",
    "RA": "2.06497752",
    "Dec": "+42.32984832",
    "Distance": "108.813928182807",
    "Mag": 2.1,
    "ColorIndex": 1.37
  },
  {
    "BayerFlamsteed": " 26Bet Per",
    "ProperName": "Algol",
    "RA": "3.13614714",
    "Dec": "+40.95565120",
    "Distance": "28.4575981787137",
    "Mag": 2.09,
    "ColorIndex": -0.003
  },
  {
    "BayerFlamsteed": " 55Alp Oph",
    "ProperName": "Rasalhague",
    "RA": "17.58222354",
    "Dec": "+12.56057584",
    "Distance": "14.3184421534937",
    "Mag": 2.08,
    "ColorIndex": 0.155
  },
  {
    "BayerFlamsteed": " 53Kap Ori",
    "ProperName": "Saiph",
    "RA": "5.79594109",
    "Dec": "-09.66960186",
    "Distance": "221.238938053097",
    "Mag": 2.07,
    "ColorIndex": -0.168
  },
  {
    "BayerFlamsteed": " 43Bet And",
    "ProperName": "Mirach",
    "RA": "1.16216599",
    "Dec": "+35.62083048",
    "Distance": "61.1246943765281",
    "Mag": 2.07,
    "ColorIndex": 1.576
  },
  {
    "BayerFlamsteed": " 21Alp And",
    "ProperName": "Alpheratz",
    "RA": "0.13976888",
    "Dec": "+29.09082805",
    "Distance": "29.7619047619048",
    "Mag": 2.07,
    "ColorIndex": -0.038
  },
  {
    "BayerFlamsteed": "  7Bet UMi",
    "ProperName": "Kochab",
    "RA": "14.84510983",
    "Dec": "+74.15547596",
    "Distance": "38.7747188832881",
    "Mag": 2.07,
    "ColorIndex": 1.465
  },
  {
    "BayerFlamsteed": "   Bet Gru",
    "ProperName": " ",
    "RA": "22.71109302",
    "Dec": "-46.88456594",
    "Distance": "52.1648408972353",
    "Mag": 2.07,
    "ColorIndex": 1.61
  },
  {
    "BayerFlamsteed": "  5The Cen",
    "ProperName": " ",
    "RA": "14.11147907",
    "Dec": "-36.36869575",
    "Distance": "18.6846038863976",
    "Mag": 2.06,
    "ColorIndex": 1.011
  },
  {
    "BayerFlamsteed": " 34Sig Sgr",
    "ProperName": "Nunki",
    "RA": "18.92108797",
    "Dec": "-26.29659428",
    "Distance": "68.7757909215956",
    "Mag": 2.05,
    "ColorIndex": -0.134
  },
  {
    "BayerFlamsteed": " 16Bet Cet",
    "ProperName": "Diphda",
    "RA": "0.7264523",
    "Dec": "-17.98668410",
    "Distance": "29.3772032902468",
    "Mag": 2.04,
    "ColorIndex": 1.019
  },
  {
    "BayerFlamsteed": " 41Gam1Leo",
    "ProperName": "Algieba",
    "RA": "10.3328227",
    "Dec": "+19.84186032",
    "Distance": "38.5208012326656",
    "Mag": 2.01,
    "ColorIndex": 1.128
  },
  {
    "BayerFlamsteed": " 13Alp Ari",
    "ProperName": "Hamal",
    "RA": "2.11952383",
    "Dec": "+23.46277743",
    "Distance": "20.2101859337106",
    "Mag": 2.01,
    "ColorIndex": 1.151
  },
  {
    "BayerFlamsteed": " 30Alp Hya",
    "ProperName": "Alphard",
    "RA": "9.45979217",
    "Dec": "-08.65868335",
    "Distance": "54.3478260869565",
    "Mag": 1.99,
    "ColorIndex": 1.44
  },
  {
    "BayerFlamsteed": "  2Bet CMa",
    "ProperName": " ",
    "RA": "6.37832983",
    "Dec": "-17.95591658",
    "Distance": "153.139356814701",
    "Mag": 1.98,
    "ColorIndex": -0.24
  },
  {
    "BayerFlamsteed": "  1Alp UMi",
    "ProperName": "Polaris",
    "RA": "2.52974312",
    "Dec": "+89.26413805",
    "Distance": "132.275132275132",
    "Mag": 1.97,
    "ColorIndex": 0.636
  },
  {
    "BayerFlamsteed": "   Alp Pav",
    "ProperName": " ",
    "RA": "20.42745823",
    "Dec": "-56.73488071",
    "Distance": "56.1797752808989",
    "Mag": 1.94,
    "ColorIndex": -0.118
  },
  {
    "BayerFlamsteed": " 24Gam Gem",
    "ProperName": "Alhena",
    "RA": "6.62852842",
    "Dec": "+16.39941482",
    "Distance": "32.133676092545",
    "Mag": 1.93,
    "ColorIndex": 0.001
  },
  {
    "BayerFlamsteed": "   Del Vel",
    "ProperName": " ",
    "RA": "8.7450548",
    "Dec": "-54.70856797",
    "Distance": "24.4498777506112",
    "Mag": 1.93,
    "ColorIndex": 0.043
  },
  {
    "BayerFlamsteed": "   Alp TrA",
    "ProperName": " ",
    "RA": "16.81107382",
    "Dec": "-69.02763503",
    "Distance": "127.388535031847",
    "Mag": 1.91,
    "ColorIndex": 1.447
  },
  {
    "BayerFlamsteed": " 34Bet Aur",
    "ProperName": " ",
    "RA": "5.99215817",
    "Dec": "+44.94743492",
    "Distance": "25.1762336354481",
    "Mag": 1.9,
    "ColorIndex": 0.077
  },
  {
    "BayerFlamsteed": "   The Sco",
    "ProperName": " ",
    "RA": "17.62197938",
    "Dec": "-42.99782155",
    "Distance": "83.4028356964137",
    "Mag": 1.86,
    "ColorIndex": 0.406
  },
  {
    "BayerFlamsteed": "   Eps Car",
    "ProperName": " ",
    "RA": "8.37524019",
    "Dec": "-59.50953829",
    "Distance": "193.798449612403",
    "Mag": 1.86,
    "ColorIndex": 1.196
  },
  {
    "BayerFlamsteed": " 85Eta UMa",
    "ProperName": "Alkaid",
    "RA": "13.79237392",
    "Dec": "+49.31330288",
    "Distance": "30.8737264587836",
    "Mag": 1.85,
    "ColorIndex": -0.099
  },
  {
    "BayerFlamsteed": " 25Del CMa",
    "ProperName": " ",
    "RA": "7.13985723",
    "Dec": "-26.39320776",
    "Distance": "549.450549450549",
    "Mag": 1.83,
    "ColorIndex": 0.671
  },
  {
    "BayerFlamsteed": " 50Alp UMa",
    "ProperName": "Dubhe",
    "RA": "11.06217691",
    "Dec": "+61.75111888",
    "Distance": "37.9075056861259",
    "Mag": 1.81,
    "ColorIndex": 1.061
  },
  {
    "BayerFlamsteed": " 33Alp Per",
    "ProperName": "Mirphak",
    "RA": "3.40537459",
    "Dec": "+49.86124281",
    "Distance": "181.488203266788",
    "Mag": 1.79,
    "ColorIndex": 0.481
  },
  {
    "BayerFlamsteed": " 20Eps Sgr",
    "ProperName": "Kaus Australis",
    "RA": "18.40287397",
    "Dec": "-34.38431460",
    "Distance": "44.3458980044346",
    "Mag": 1.79,
    "ColorIndex": -0.031
  },
  {
    "BayerFlamsteed": " 77Eps UMa",
    "ProperName": "Alioth",
    "RA": "12.9004536",
    "Dec": "+55.95984301",
    "Distance": "24.8138957816377",
    "Mag": 1.76,
    "ColorIndex": -0.022
  },
  {
    "BayerFlamsteed": "   Gam2Vel",
    "ProperName": " ",
    "RA": "8.15887648",
    "Dec": "-47.33661177",
    "Distance": "257.731958762887",
    "Mag": 1.75,
    "ColorIndex": -0.145
  },
  {
    "BayerFlamsteed": " 50Zet Ori",
    "ProperName": "Alnitak",
    "RA": "5.67931244",
    "Dec": "-01.94257841",
    "Distance": "250.62656641604",
    "Mag": 1.74,
    "ColorIndex": -0.199
  },
  {
    "BayerFlamsteed": "   Alp Gru",
    "ProperName": "Alnair",
    "RA": "22.13718789",
    "Dec": "-46.96061593",
    "Distance": "31.0945273631841",
    "Mag": 1.73,
    "ColorIndex": -0.07
  },
  {
    "BayerFlamsteed": " 46Eps Ori",
    "ProperName": "Alnilam",
    "RA": "5.60355904",
    "Dec": "-01.20191725",
    "Distance": "411.522633744856",
    "Mag": 1.69,
    "ColorIndex": -0.184
  },
  {
    "BayerFlamsteed": "   Bet Car",
    "ProperName": " ",
    "RA": "9.22006688",
    "Dec": "-69.71747245",
    "Distance": "34.0831629175187",
    "Mag": 1.67,
    "ColorIndex": 0.07
  },
  {
    "BayerFlamsteed": "112Bet Tau",
    "ProperName": "Alnath",
    "RA": "5.43819386",
    "Dec": "+28.60787346",
    "Distance": "40.1767778224186",
    "Mag": 1.65,
    "ColorIndex": -0.13
  },
  {
    "BayerFlamsteed": " 24Gam Ori",
    "ProperName": "Bellatrix",
    "RA": "5.41885227",
    "Dec": "+06.34973451",
    "Distance": "74.5156482861401",
    "Mag": 1.64,
    "ColorIndex": -0.224
  },
  {
    "BayerFlamsteed": " 35Lam Sco",
    "ProperName": "Shaula",
    "RA": "17.56014624",
    "Dec": "-37.10374835",
    "Distance": "215.51724137931",
    "Mag": 1.62,
    "ColorIndex": -0.231
  },
  {
    "BayerFlamsteed": "   Gam Cru",
    "ProperName": " ",
    "RA": "12.5194248",
    "Dec": "-57.11256922",
    "Distance": "26.9614451334592",
    "Mag": 1.59,
    "ColorIndex": 1.6
  },
  {
    "BayerFlamsteed": " 66Alp Gem",
    "ProperName": "Castor",
    "RA": "7.57666793",
    "Dec": "+31.88863645",
    "Distance": "15.8052789631737",
    "Mag": 1.58,
    "ColorIndex": 0.034
  },
  {
    "BayerFlamsteed": " 21Eps CMa",
    "ProperName": "Adhara",
    "RA": "6.9770963",
    "Dec": "-28.97208931",
    "Distance": "132.100396301189",
    "Mag": 1.5,
    "ColorIndex": -0.211
  },
  {
    "BayerFlamsteed": " 32Alp Leo",
    "ProperName": "Regulus",
    "RA": "10.13957205",
    "Dec": "+11.96719513",
    "Distance": "23.7586124970302",
    "Mag": 1.36,
    "ColorIndex": -0.087
  },
  {
    "BayerFlamsteed": "   Alp2Cen",
    "ProperName": "Rigel Kentaurus B",
    "RA": "14.66094188",
    "Dec": "-60.83947139",
    "Distance": "1.34749097181049",
    "Mag": 1.35,
    "ColorIndex": 0.9
  },
  {
    "BayerFlamsteed": " 50Alp Cyg",
    "ProperName": "Deneb",
    "RA": "20.69053151",
    "Dec": "+45.28033423",
    "Distance": "990.09900990099",
    "Mag": 1.25,
    "ColorIndex": 0.092
  },
  {
    "BayerFlamsteed": "   Bet Cru",
    "ProperName": " ",
    "RA": "12.79536635",
    "Dec": "-59.68873246",
    "Distance": "108.108108108108",
    "Mag": 1.25,
    "ColorIndex": -0.238
  },
  {
    "BayerFlamsteed": " 24Alp PsA",
    "ProperName": "Fomalhaut",
    "RA": "22.96078488",
    "Dec": "-29.62183701",
    "Distance": "7.68757687576876",
    "Mag": 1.17,
    "ColorIndex": 0.145
  },
  {
    "BayerFlamsteed": " 78Bet Gem",
    "ProperName": "Pollux",
    "RA": "7.75537884",
    "Dec": "+28.02631031",
    "Distance": "10.3369857349597",
    "Mag": 1.16,
    "ColorIndex": 0.991
  },
  {
    "BayerFlamsteed": " 21Alp Sco",
    "ProperName": "Antares",
    "RA": "16.49012986",
    "Dec": "-26.43194608",
    "Distance": "185.185185185185",
    "Mag": 1.06,
    "ColorIndex": 1.865
  },
  {
    "BayerFlamsteed": " 67Alp Vir",
    "ProperName": "Spica",
    "RA": "13.41989015",
    "Dec": "-11.16124491",
    "Distance": "80.3858520900322",
    "Mag": 0.98,
    "ColorIndex": -0.235
  },
  {
    "BayerFlamsteed": " 87Alp Tau",
    "ProperName": "Aldebaran",
    "RA": "4.59866679",
    "Dec": "+16.50976164",
    "Distance": "19.9640646835696",
    "Mag": 0.87,
    "ColorIndex": 1.538
  },
  {
    "BayerFlamsteed": "   Alp1Cru",
    "ProperName": "Acrux",
    "RA": "12.44331705",
    "Dec": "-63.09905586",
    "Distance": "98.3284169124877",
    "Mag": 0.77,
    "ColorIndex": -0.243
  },
  {
    "BayerFlamsteed": " 53Alp Aql",
    "ProperName": "Altair",
    "RA": "19.84630057",
    "Dec": "+08.86738491",
    "Distance": "5.14297469656449",
    "Mag": 0.76,
    "ColorIndex": 0.221
  },
  {
    "BayerFlamsteed": "   Bet Cen",
    "ProperName": "Hadar",
    "RA": "14.06373459",
    "Dec": "-60.37297840",
    "Distance": "161.030595813205",
    "Mag": 0.61,
    "ColorIndex": -0.231
  },
  {
    "BayerFlamsteed": " 58Alp Ori",
    "ProperName": "Betelgeuse",
    "RA": "5.91952477",
    "Dec": "+07.40703634",
    "Distance": "131.061598951507",
    "Mag": 0.45,
    "ColorIndex": 1.5
  },
  {
    "BayerFlamsteed": "   Alp Eri",
    "ProperName": "Achernar",
    "RA": "1.62854213",
    "Dec": "-57.23666007",
    "Distance": "44.0917107583774",
    "Mag": 0.45,
    "ColorIndex": -0.158
  },
  {
    "BayerFlamsteed": " 10Alp CMi",
    "ProperName": "Procyon",
    "RA": "7.65514946",
    "Dec": "+05.22750767",
    "Distance": "3.49735949358235",
    "Mag": 0.4,
    "ColorIndex": 0.432
  },
  {
    "BayerFlamsteed": " 19Bet Ori",
    "ProperName": "Rigel",
    "RA": "5.24229756",
    "Dec": "-08.20163919",
    "Distance": "236.96682464455",
    "Mag": 0.18,
    "ColorIndex": -0.03
  },
  {
    "BayerFlamsteed": " 13Alp Aur",
    "ProperName": "Capella",
    "RA": "5.27813767",
    "Dec": "+45.99902927",
    "Distance": "12.9382843834907",
    "Mag": 0.08,
    "ColorIndex": 0.795
  },
  {
    "BayerFlamsteed": "  3Alp Lyr",
    "ProperName": "Vega",
    "RA": "18.61560722",
    "Dec": "+38.78299311",
    "Distance": "7.75614674629644",
    "Mag": 0.03,
    "ColorIndex": -0.001
  },
  {
    "BayerFlamsteed": " 17Sig CrB",
    "ProperName": " ",
    "RA": "16.2448477254128",
    "Dec": "33.8578202931886",
    "Distance": "21.6872695727608",
    "Mag": 6.72,
    "ColorIndex": null
  },
  {
    "BayerFlamsteed": " 53    Aqr",
    "ProperName": " ",
    "RA": "22.4427686234027",
    "Dec": "-16.7424243590695",
    "Distance": "20.0803212851406",
    "Mag": 6.21,
    "ColorIndex": 0.62
  },
  {
    "BayerFlamsteed": " 78Mu 2Cyg",
    "ProperName": " ",
    "RA": "21.7352989913218",
    "Dec": "28.7460139667878",
    "Distance": "22.4014336917563",
    "Mag": 6.09,
    "ColorIndex": null
  },
  {
    "BayerFlamsteed": "  2Eta CrB",
    "ProperName": " ",
    "RA": "15.3865051497143",
    "Dec": "30.2887873723128",
    "Distance": "18.6219739292365",
    "Mag": 5.96,
    "ColorIndex": null
  },
  {
    "BayerFlamsteed": " 21Mu  Dra",
    "ProperName": " ",
    "RA": "17.0887840325494",
    "Dec": "54.4679889670376",
    "Distance": "23.2558139534884",
    "Mag": 5.63,
    "ColorIndex": 0.47
  },
  {
    "BayerFlamsteed": " 69Tau Oph",
    "ProperName": " ",
    "RA": "18.051182117854",
    "Dec": "-8.18124153788091",
    "Distance": "52.0291363163372",
    "Mag": 5.24,
    "ColorIndex": 0.38
  },
  {
    "BayerFlamsteed": " 42Alp Com",
    "ProperName": " ",
    "RA": "13.1667836929554",
    "Dec": "17.526962699562",
    "Distance": "14.3245953301819",
    "Mag": 5.17,
    "ColorIndex": null
  },
  {
    "BayerFlamsteed": " 36    Oph",
    "ProperName": " ",
    "RA": "17.2561897481974",
    "Dec": "-26.5875605536023",
    "Distance": "5.98515681110845",
    "Mag": 5.07,
    "ColorIndex": 0.85
  },
  {
    "BayerFlamsteed": "   Gam CrA",
    "ProperName": " ",
    "RA": "19.1067621235043",
    "Dec": "-37.0610654595946",
    "Distance": "17.8922884236894",
    "Mag": 5,
    "ColorIndex": null
  },
  {
    "BayerFlamsteed": "   Xi  Sco",
    "ProperName": " ",
    "RA": "16.0728309493662",
    "Dec": "-11.3741501261325",
    "Distance": "24.330900243309",
    "Mag": 5,
    "ColorIndex": null
  },
  {
    "BayerFlamsteed": "   Xi  Sco",
    "ProperName": " ",
    "RA": "16.0728309493662",
    "Dec": "-11.3741501261325",
    "Distance": "24.330900243309",
    "Mag": 4.84,
    "ColorIndex": 0.45
  },
  {
    "BayerFlamsteed": " 53Xi  UMa",
    "ProperName": " ",
    "RA": "11.3035119198113",
    "Dec": "31.5368521599899",
    "Distance": "10.4166666666667",
    "Mag": 4.8,
    "ColorIndex": null
  },
  {
    "BayerFlamsteed": " 53Xi  UMa",
    "ProperName": " ",
    "RA": "11.3035119198113",
    "Dec": "31.5368521599899",
    "Distance": "10.4166666666667",
    "Mag": 4.33,
    "ColorIndex": 0.59
  },
  {
    "BayerFlamsteed": " 79Zet UMa",
    "ProperName": " ",
    "RA": "13.398898541116",
    "Dec": "54.9208921914242",
    "Distance": "24.1545893719807",
    "Mag": 3.95,
    "ColorIndex": 0.13
  },
  {
    "BayerFlamsteed": " 29Gam Vir",
    "ProperName": " ",
    "RA": "12.6946896478077",
    "Dec": "-1.44933013444673",
    "Distance": "11.8301194842068",
    "Mag": 3.52,
    "ColorIndex": null
  },
  {
    "BayerFlamsteed": " 66Alp Gem",
    "ProperName": " ",
    "RA": "7.57697782948592",
    "Dec": "31.8918827889317",
    "Distance": "15.8052789631737",
    "Mag": 2.85,
    "ColorIndex": null
  }
];